import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime, Info } from 'luxon';

function CustomAdapterLuxon(weekStartsOn: number) {
  class Adapter extends AdapterLuxon {
    private weekStartsOn = weekStartsOn;

    public getWeekdays = (): string[] => {
      const weekdays = Info.weekdaysFormat('narrow', { locale: this.locale });
      const start = weekdays.slice(0, this.weekStartsOn - 1);
      const end = weekdays.slice(this.weekStartsOn - 1);
      return [...end, ...start];
    };

    public getWeekArray = (date: DateTime): DateTime[][] => {
      const startOfMonth = date.startOf('month');
      const endOfMonth = date.endOf('month');
      const firstWeekDayOfMonth = startOfMonth.weekday;
      const lastMonthDays = (firstWeekDayOfMonth - this.weekStartsOn + 7) % 7;
      const lastWeekDayOfMonth = endOfMonth.weekday;
      const nextMonthDays = (6 - lastWeekDayOfMonth + this.weekStartsOn) % 7;
      const endOfMonthDay = endOfMonth.day;
      const totalLines = Math.ceil((lastMonthDays + endOfMonthDay + nextMonthDays) / 7);

      const startOfCalendar = startOfMonth.minus({ days: lastMonthDays });

      const calendar: DateTime[][] = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < totalLines; i++) {
        calendar.push([]);
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < 7; j++) {
          calendar[i].push(startOfCalendar.plus({ days: i * 7 + j }));
        }
      }

      return calendar;
    };
  }

  return Adapter;
}

export default CustomAdapterLuxon;
