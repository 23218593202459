import { useCallback, useState } from 'react';
import { ApiError } from '../../services/http';
import documentWarehousingApi, {
  DocumentWarehousing,
  DocumentWarehousingStoreForm,
} from '../../services/http/documentWarehousing.api';
import { ReserveManagementItem } from '../../services/http/reserveManagement.api';
import { useHttp } from './api.hook';

/**
 * 文書倉庫保管庫APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentWarehousingStoreApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: DocumentWarehousingStoreForm): Promise<void> {
    setLoading(true);
    try {
      return await documentWarehousingApi.store(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    request, error, loading,
  };
}

/**
 * 文書検索用予約ナンバーAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentSearchForReserveNoApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DocumentWarehousing[] | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (nos: ReserveManagementItem[]): Promise<DocumentWarehousing[]> => {
    setLoading(true);
    try {
      const doc = await documentWarehousingApi.searchForReserveNo(nos);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading, data, error, request, setData,
  };
}

/**
 * 配送方法取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`: リクエストを行う関数。
 *   - `data`: リクエストが成功した場合のレスポンスデータ
 *   - `error`: リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDeliveryMethodApi() {
  return useHttp(documentWarehousingApi.getDeliveryMethod);
}

/**
 * 配送可能日取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`: リクエストを行う関数。
 *   - `data`: リクエストが成功した場合のレスポンスデータ
 *   - `error`: リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDeliveryCalendarApi() {
  return useHttp(documentWarehousingApi.getDeliveryCalendar);
}
