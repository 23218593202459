import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef, ICellRendererParams, ValueFormatterParams,
} from 'ag-grid-community';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './tagMaintenancePage.module.css';
import mainStyles from '../main.module.css';
import Breadcrumb from '../../components/Breadcrumb';
import routes from '../../utils/routes';
import usePageTitle from '../../hooks/title.hook';
import AlertModal, { AlertModalProps, alertModalInitialState } from '../../components/AlertModal';
import BaseTable from '../../components/BaseTable';
import AGUtils from '../../utils/ag-grid.utils';
import { DocumentTag, TagType, TagWithDocumentTypeList } from '../../services/http/tag.api';
import SelectCellEditor from '../../components/BaseTable/partials/SelectCell/SelectCellEditor';
import TagFormat from '../../utils/tagFormat';
import CheckboxCellRender from '../../components/BaseTable/partials/CheckBoxCell/CheckBoxCellRender';
import InputCellRender from '../../components/BaseTable/partials/InputCellRender';
import Button from '../../components/Button/button';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import {
  useDeleteTagApi, useGetAllExistingTagsApi, useRegisterTagApi, useUpdateTagApi,
} from '../../hooks/api/tag.hook';
import ButtonGroupCellRender from '../../components/BaseTable/partials/ButtonGroupCellRender';
import SaveTagModalBody from './partials/saveTagModalBody';
import IconCellRender from '../../components/BaseTable/partials/IconCellRender/IconCellRender';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import NotificationSettingModal from './notificationSettingModal';

function NotificationSettingCell(params: ICellRendererParams<DocumentTag> & { onNotificationSettingModalOpen: () => void }) {
  const { data, onNotificationSettingModalOpen } = params;
  return (
    <div hidden={data?.format !== TagFormat.DATE}>
      <div
        className={styles['notification-container']}
      >
        <Button
          onClick={onNotificationSettingModalOpen}
          className={`${mainStyles['btn-primary']} ${styles['button-primary']}`}
        >
          期限通知設定
        </Button>
        {data?.reminderUseFlg === 1 ? (
          <button
            type="button"
            disabled
            className={`${styles['button-disabled']} ${styles['button-disabled-set']}`}
          >
            設定中
          </button>
        ) : (
          <button
            type="button"
            disabled
            className={`${styles['button-disabled']} ${styles['button-disabled-unset']}`}
          >
            未設定
          </button>
        )}
        {
          (data?.reminderUseFlg === 1 && (
            <span
              className={styles['notification-span']}
            >
              {`${data?.reminderNoticePeriod ?? 0}日前`}
            </span>
          ))
        }
      </div>
    </div>
  );
}

function TagMaintenancePage() {
  usePageTitle('タグメンテナンス');

  const openMessageModal = useMessageModal();
  const TagFormatTextMap = TagFormat.getTextMap();
  const gridRef = useRef<AgGridReact<TagWithDocumentTypeList>>(null);

  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);
  const [tableTags, setTableTags] = useState<TagWithDocumentTypeList[]>([]);
  const [form, setForm] = useState<TagWithDocumentTypeList | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTagSettingId, setSelectedTagSettingId] = useState<number>();

  const [notificationSettingModalOpen, setNotificationSettingModalOpen] = useState(false);

  const { request: getAllTagsRequest } = useGetAllExistingTagsApi();
  const { request: registerTagRequest } = useRegisterTagApi();
  const { request: deleteTagRequest } = useDeleteTagApi();
  const { request: updateTagRequest } = useUpdateTagApi();
  const { request: createLog } = useCreateLogApi();

  const isAlreadyAddingTag = tableTags.some((item) => item.tagSettingId === 0);
  const selectedTag = tableTags.find((tag) => tag.tagSettingId === selectedTagSettingId);

  const canEditAgGridColumn = useCallback((value: TagWithDocumentTypeList) => {
    if (value.tagType === TagType.SYSTEM) return false;
    if (!form) return false;
    return value.tagSettingId === form.tagSettingId;
  }, [form]);

  const sortTags = useCallback((a:TagWithDocumentTypeList, b: TagWithDocumentTypeList) => {
    if (a.tagType === TagType.CUSTOMER && b.tagType === TagType.CUSTOMER) return 0;
    if (a.tagType === TagType.CUSTOMER) return 1;
    if (b.tagType === TagType.CUSTOMER) return -1;
    return 0;
  }, []);

  const onTagItemChanged = useCallback((item: TagWithDocumentTypeList, key: keyof TagWithDocumentTypeList, value: TagWithDocumentTypeList[keyof TagWithDocumentTypeList]) => {
    setForm((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        [key]: value,
      };
    });
  }, []);

  const handleSetTableTags = useCallback((tags: TagWithDocumentTypeList[]) => {
    const sortedTags = tags.sort(sortTags);
    setTableTags(sortedTags);
  }, [sortTags]);

  const fetchAllTags = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getAllTagsRequest();
      const allOrganizationTags = res.tagList;
      handleSetTableTags(allOrganizationTags);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    } finally {
      setIsLoading(false);
    }
  }, [getAllTagsRequest, handleSetTableTags, openMessageModal]);

  const registerTag = useCallback(async (tag: TagWithDocumentTypeList) => {
    try {
      await registerTagRequest(tag);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    } finally {
      fetchAllTags();
    }
  }, [createLog, fetchAllTags, openMessageModal, registerTagRequest]);

  const updateTag = useCallback(async (tag: TagWithDocumentTypeList) => {
    try {
      await updateTagRequest({
        tagSettingId: tag.tagSettingId,
        tagLabel: tag.tagLabel,
        format: Number(tag.format),
        duplicate: tag.duplicate,
      });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    } finally {
      fetchAllTags();
    }
  }, [createLog, fetchAllTags, openMessageModal, updateTagRequest]);

  const handleTagEditCancel = useCallback(() => {
    if (form && form.tagSettingId === 0) {
      setTableTags((prev) => prev.filter((item) => item.tagSettingId !== form.tagSettingId));
    }
    setForm(null);
  }, [form]);

  const handleEditOrSaveClick = useCallback((tag: TagWithDocumentTypeList) => {
    if (!form) {
      setForm(tag);
      return;
    }
    if (form.tagSettingId !== tag.tagSettingId) {
      handleTagEditCancel();
      setForm(tag);
      return;
    }
    const isTagLabelDuplicated = tableTags.some((item) => item.tagLabel === form.tagLabel && item.tagSettingId !== form.tagSettingId);
    if (isTagLabelDuplicated) {
      openMessageModal('更新対象のタグが既に登録されています。');
      return;
    }
    if (form.tagLabel === '') {
      openMessageModal('タグ名を入力してください。');
      return;
    }
    const modalFooterText = tag.tagSettingId === 0 ? '新しいタグを登録します。よろしいですか？' : '変更してよろしいですか？';
    setModal({
      open: true,
      text: form.documentTypeList.length > 0
        ? (
          <SaveTagModalBody
            tagLabel={form.tagLabel}
            items={form.documentTypeList}
            footer={modalFooterText}
          />
        )
        : modalFooterText,
      onConfirm() {
        setModal({ ...modal, open: false });
        if (form.tagSettingId > 0) {
          updateTag(form);
        } else {
          registerTag(form);
        }
        setForm(null);
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  }, [form, handleTagEditCancel, modal, openMessageModal, registerTag, tableTags, updateTag]);

  const deleteTag = useCallback(async (tag: TagWithDocumentTypeList) => {
    try {
      await deleteTagRequest(tag.tagSettingId);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    } finally {
      fetchAllTags();
    }
  }, [createLog, deleteTagRequest, fetchAllTags, openMessageModal]);

  const handleDeleteTag = useCallback((tag: TagWithDocumentTypeList) => {
    const deleteFooterText = '削除してよろしいですか？';
    setModal({
      open: true,
      text: tag.documentTypeList.length > 0 ? (
        <SaveTagModalBody
          header="登録されているデータも全て削除されます。"
          tagLabel={tag.tagLabel}
          items={tag.documentTypeList}
          footer={deleteFooterText}
        />
      ) : `登録されているデータも全て削除されます。\n${deleteFooterText}`,
      onConfirm() {
        setModal({ ...modal, open: false });
        setForm(null);
        deleteTag(tag);
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  }, [deleteTag, modal]);

  const checkBoxValue = useCallback((tag: TagWithDocumentTypeList) => {
    if (!form) return tag.duplicate;
    if (form.tagSettingId !== tag.tagSettingId) return tag.duplicate;
    return form.duplicate;
  }, [form]);

  const handleDeleteClick = useCallback((tag: TagWithDocumentTypeList) => {
    if (tag.tagSettingId > 0) {
      handleDeleteTag(tag);
      return;
    }
    setTableTags((prev) => prev.filter((item) => item.tagSettingId !== tag.tagSettingId));
  }, [handleDeleteTag]);

  const handleAddTagRow = useCallback(() => {
    const newTag: TagWithDocumentTypeList = {
      duplicate: false,
      format: TagFormat.STRING,
      tagSettingId: 0,
      require: false,
      tagLabel: '',
      tagType: TagType.CUSTOMER,
      documentTypeList: [],
      reminderNoticePeriod: 0,
      reminderUseFlg: 0,
    };
    setTableTags((prev) => ([...prev, newTag]));
    setForm(newTag);
  }, []);

  const onSaveReminderSettings = useCallback(() => {
    setNotificationSettingModalOpen(false);
    fetchAllTags();
  }, [fetchAllTags]);

  const columns = useMemo((): ColDef[] => [
    AGUtils.colAutoIncrement('id'),
    {
      headerName: 'タグ名',
      resizable: true,
      field: 'tagLabel',
      width: 311,
      suppressKeyboardEvent: () => form != null,
      cellRendererSelector: (params: ICellRendererParams<TagWithDocumentTypeList>) => {
        if ((params.data && form) && canEditAgGridColumn(params.data)) {
          return {
            component: InputCellRender,
            params: {
              value: form.tagLabel,
              onTextInput: (row: TagWithDocumentTypeList, value: string) => {
                onTagItemChanged(row, 'tagLabel', value);
              },
            },
          };
        }
        return undefined;
      },
    },
    {
      headerName: '書式',
      resizable: true,
      field: 'format',
      cellStyle: { overflow: 'initial' },
      width: 120,
      cellRendererSelector: (params: ICellRendererParams<TagWithDocumentTypeList>) => {
        if ((params.data && form) && canEditAgGridColumn(params.data) && params.data.tagSettingId === 0) {
          return {
            component: SelectCellEditor,
            params: {
              value: form.format,
              options: [{ text: '文字列', value: TagFormat.STRING }, { text: '数値', value: TagFormat.NUMBER }, { text: '日付', value: TagFormat.DATE }],
              onChange: (e: TagFormat) => {
                if (!params.data) return;
                onTagItemChanged(params.data, 'format', e);
              },
            },
          };
        }
        return undefined;
      },
      valueFormatter: ({ data }: ValueFormatterParams<TagWithDocumentTypeList>) => {
        if (!data) return '';
        return TagFormatTextMap[data.format];
      },
    },
    {
      headerName: '重複を許可しない',
      resizable: true,
      field: 'duplicate',
      width: 160,
      cellRendererSelector: ({ data }: ICellRendererParams<TagWithDocumentTypeList>) => {
        if (data && data.tagType === TagType.CUSTOMER) {
          return {
            component: CheckboxCellRender,
            params: {
              value: ((form && data)) ? checkBoxValue(data) : data.duplicate,
              readOnly: !canEditAgGridColumn(data),
              align: 'center',
              setValue: (value: boolean) => {
                if (!data) return;
                onTagItemChanged(data, 'duplicate', value);
              },
            },
          };
        }
        if (data && data.duplicate && data.tagType === TagType.SYSTEM) {
          return {
            component: IconCellRender,
            params: {
              src: '/images/check-solid.svg',
            },
          };
        }
        return undefined;
      },
    },
    {
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      minWidth: 300,
      suppressMovable: true,
      field: 'buttons',
      cellRenderer: (params: ICellRendererParams) => ButtonGroupCellRender({
        items: [
          {
            label: (params.data && form) && (params.data.tagSettingId === form.tagSettingId) ? '保存' : '変更',
            data: params.data,
            onClick: (tag: TagWithDocumentTypeList) => handleEditOrSaveClick(tag),
            hidden: params.data.tagType === TagType.SYSTEM,
            style: {
              fontSize: '12px', height: '26px', width: '80px', borderRadius: '5px',
            },
            className: (params.data && form) && (params.data.tagSettingId === form.tagSettingId) ? mainStyles['btn-primary'] : '',
          },
          {
            label: 'キャンセル',
            data: params.data,
            onClick: () => handleTagEditCancel(),
            disabled: params.data.tagType === TagType.SYSTEM,
            hidden: !canEditAgGridColumn(params.data),
            style: {
              fontSize: '12px', height: '26px', width: '80px', borderRadius: '5px',
            },
          },
          {
            label: '削除',
            data: params.data,
            onClick: (tag: TagWithDocumentTypeList) => handleDeleteClick(tag),
            disabled: params.data.tagType === TagType.SYSTEM,
            hidden: !canEditAgGridColumn(params.data),
            style: {
              fontSize: '12px', height: '26px', width: '80px', borderRadius: '5px',
            },
            className: (params.data && form) && (params.data.tagSettingId === form.tagSettingId) ? mainStyles['btn-danger'] : '',
          },
        ],
      }),
    },
    {
      headerName: '',
      resizable: true,
      field: 'notification',
      minWidth: 370,
      flex: 1,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
      cellRendererSelector: (params: ICellRendererParams<TagWithDocumentTypeList>) => ({
        component: NotificationSettingCell,
        params: {
          data: params.data,
          onNotificationSettingModalOpen: () => {
            if (params.data) {
              setSelectedTagSettingId(params.data.tagSettingId);
              setNotificationSettingModalOpen(true);
            }
          },
        },
      }),
    },
  ], [form, canEditAgGridColumn, onTagItemChanged, TagFormatTextMap, checkBoxValue, handleEditOrSaveClick, handleTagEditCancel, handleDeleteClick]);

  useEffect(() => {
    fetchAllTags();
  }, [fetchAllTags]);

  useEffectOnce(() => {
    createLog(LogFormName.TagMaintenanceScreen, LogControlName.Show);
  });

  return (
    <MainFrame borderBox>
      <div className={`${styles.mainFrame} ${mainStyles['pt-74px']}`}>
        <Breadcrumb crumbs={[
          { label: 'マスタ管理', route: routes.masterDataSettingGeneral },
          { label: document.title }]}
        />
        <div className={styles.mainFrameBody}>
          <div className={styles.rightSection}>
            <div className={styles.rightSectionTop}>
              <Button className={`${styles.topButton} ${styles.mr10}`} disabled={isAlreadyAddingTag} color="lighterGray" size="smaller" onClick={handleAddTagRow}>
                タグを登録
              </Button>
            </div>

            <div className={styles.tableContainer}>
              <BaseTable<TagWithDocumentTypeList>
                tableRef={gridRef}
                formName="tagMaintenanceTable"
                waitToLoad={isLoading}
                columnDefs={columns}
                rowData={tableTags}
                suppressRowTransform
                stopEditingWhenCellsLoseFocus
                createLogOnDownloadTableData={LogFormName.TagMaintenanceScreen}
                suppressContextMenu={form != null}
                sideBar
              />
            </div>

          </div>
        </div>
        {(notificationSettingModalOpen && selectedTagSettingId) && (
        <NotificationSettingModal
          tagSettingId={selectedTagSettingId}
          onCancel={() => setNotificationSettingModalOpen(false)}
          onSave={onSaveReminderSettings}
          tagName={selectedTag?.tagLabel || ''}
          reminderUseFlgProp={selectedTag?.reminderUseFlg || 0}
          reminderNoticePeriodProp={selectedTag?.reminderNoticePeriod || 0}
        />
        )}
        <AlertModal open={modal.open} text={modal.text} onConfirm={modal.onConfirm} onCancel={modal.onCancel} textCenter />
      </div>
    </MainFrame>
  );
}

export default TagMaintenancePage;
