import { useEffect, useRef } from 'react';

/**
 * 一度だけ実行される useEffect フック
 */
export default function useEffectOnce(effect: () => void | (() => void | undefined)) {
  const destroyFunc = useRef<any>(null);
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);

  if (effectCalled.current) renderAfterCalled.current = true;

  useEffect(() => {
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    return () => {
      if (!renderAfterCalled.current) return;
      if (destroyFunc.current) destroyFunc.current();
    };
  }, []);
}
