import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import styles from './mainFrameLeftSection.module.css';
import Button from '../Button/button';
import { useFullFolderSearch } from '../../hooks/api/folder.hook';
import {
  Folder, FolderDeleteForm, FolderId, FolderUpdateForm,
} from '../../services/http/folder.api';
import { ManagementType, useAuthContext } from '../../store/auth.store';
import { FolderForm } from './partials/folderForm';
import { FolderItem } from './partials/folderItem';
import mainStyles from '../../pages/main.module.css';
import SortFoldersModal from '../SortFoldersModal/sortFoldersModal';
import DropdownMenu from '../DropdownMenu';

/**
 * メインフレームの左部メニューのプロップスインターフェース
 */
export interface MainFrameLeftSectionProps {
  /** 選択されているフォルダ */
  folder: Folder | null,
  /** フォルダ選択時に実行されるハンドラー */
  onSelectFolder: (folder: Folder) => void,
  /** フォルダ権限変更中かどうか */
  isChangingFolderPermissions: boolean,
  /** 上部ボタンを非表示にするかどうか */
  hideTopButton?: boolean,
  /** 文書フォルダ変更時に実行されるハンドラー */
  onDocumentFolderChanged?: () => void,
  /** フォルダ一覧を更新するための値 */
  updateFolderList?: number,
  onFolderListSearchLoading?: (isLoading: boolean) => void,
  onFolderListSearchEmpty?: (isEmpty: boolean) => void,
  onDropCompleted?: () => void
  mainFrameLeftSectionWidth?: number,
  onUpdateFolder?: (folder: Folder) => void,
}

/**
 * メインフレームの左部メニューコンポーネント
 */
function MainFrameLeftSection({
  folder, onSelectFolder, isChangingFolderPermissions, hideTopButton, onDocumentFolderChanged, updateFolderList, onFolderListSearchLoading = () => {}, onFolderListSearchEmpty = () => {}, mainFrameLeftSectionWidth, onDropCompleted, onUpdateFolder,
}: MainFrameLeftSectionProps) {
  const [originalFolderListState, setOriginalFolderListState] = useState<Folder[]>([]);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [filteredFolders, setFilteredFolders] = useState<Folder[]>([]);
  const [folderPinListState, setFolderPinListState] = useState<FolderId[]>([]);
  const [newFolderFlag, setNewFolderFlag] = useState(false);
  const [sortFoldersModal, setSortFoldersModal] = useState(false);
  const [isFolderActionDropdownOpen, setIsFolderActionDropdownOpen] = useState(false);
  const { user } = useAuthContext();

  const folderSearch = useFullFolderSearch();

  const threeDotsRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const isFolderPinned = useCallback((targetFolder: Folder): boolean => folderPinListState.some((pinItem) => pinItem.id === targetFolder.id), [folderPinListState]);

  const orderPinnedFolders = useCallback((pinnedFolders: Folder[], pinnedFolderIds: number[]): Folder[] => pinnedFolderIds
    .map((id) => pinnedFolders.find((folderItem) => folderItem.id === id))
    .filter((folderItem): folderItem is Folder => folderItem !== undefined), []);

  const getSortedFolders = useCallback((folderList: Folder[], folderPinList: FolderId[]): Folder[] => {
    const pinnedFolderIds = folderPinList.map((pinItem) => pinItem.id);

    const pinnedFoldersList = folderList.filter((folderItem) => pinnedFolderIds.includes(folderItem.id));
    const unpinnedFolderList = folderList.filter((folderItem) => !pinnedFolderIds.includes(folderItem.id));

    const orderedPinnedFolders = orderPinnedFolders(pinnedFoldersList, pinnedFolderIds);

    return [...orderedPinnedFolders, ...unpinnedFolderList];
  }, [orderPinnedFolders]);

  const fetchFolders = useCallback(async () => {
    onFolderListSearchLoading(true);

    const result = await folderSearch.exec({});
    const { folderList, folderPinList } = result;
    setOriginalFolderListState(folderList);
    setFolderPinListState(folderPinList);

    const sortedFolders = getSortedFolders(folderList, folderPinList);

    setFolders(sortedFolders);
    setFilteredFolders(sortedFolders);

    onFolderListSearchLoading(false);
    onFolderListSearchEmpty(sortedFolders.length === 0);
  }, []);

  const onCreateFolder = useCallback(() => {
    setNewFolderFlag(false);
    fetchFolders();
  }, [fetchFolders]);

  const onCancelCreateFolder = useCallback(() => {
    setNewFolderFlag(false);
  }, []);

  const updateFolderNameList = useCallback(
    (folderList: Folder[], form: FolderUpdateForm): Folder[] => folderList.map((item) => (item.id === form.id ? { ...item, name: form.name } : item)),
    [],
  );

  const findUpdatedFolder = useCallback(
    (folderList: Folder[], folderId: number): Folder | undefined => folderList.find((item) => item.id === folderId),
    [],
  );

  const onUpdateForm = useCallback(async (form: FolderUpdateForm): Promise<void> => {
    const updatedFolders = updateFolderNameList(folders, form);
    setFolders(updatedFolders);

    fetchFolders();

    const updatedFolder = findUpdatedFolder(updatedFolders, form.id);
    if (!updatedFolder || !onUpdateFolder) return;

    onUpdateFolder(updatedFolder);
  }, [fetchFolders, findUpdatedFolder, folders, onUpdateFolder, updateFolderNameList]);

  const onDeleteForm = useCallback(async (form: FolderDeleteForm): Promise<void> => {
    setFolders(folders.filter((item) => item.id !== form.id));
    fetchFolders();
  }, [fetchFolders, folders]);

  useEffect(() => {
    fetchFolders();
  }, [fetchFolders, updateFolderList]);

  const handleFolderSearch = useCallback((search: string) => {
    const lowerCasedSearch = search.toLowerCase();
    const filtered = folders.filter((f) => f.name.toLowerCase().includes(lowerCasedSearch));
    setFilteredFolders(filtered);
  }, [folders]);

  const onClearSearch = useCallback(() => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      handleFolderSearch('');
    }
  }, [handleFolderSearch]);

  const onSendSortListSuccess = useCallback(() => {
    setSortFoldersModal(false);
    fetchFolders();
  }, [setSortFoldersModal, fetchFolders]);

  const handleFolderActionDropdownOutsideClick = () => {
    // eslint-disable-next-line no-restricted-globals
    if (threeDotsRef.current && threeDotsRef.current.contains(event?.target as Node)) {
      return;
    }
    setIsFolderActionDropdownOpen(false);
  };

  const handleFolderActionDropdownSelect = () => {
    setSortFoldersModal(true);
    setIsFolderActionDropdownOpen(false);
  };

  return (
    <div className={styles.mainFrameLeftSection} style={{ width: `${mainFrameLeftSectionWidth}px` }}>
      <div className={styles.mainFrameLeftSectionTopLine} />
      <div className={styles.mainFrameLeftSectionTop} style={{ height: '103px' }}>
        <div style={{ marginTop: '10px', marginBottom: '16px', minHeight: '30px' }}>
          {(!isChangingFolderPermissions && !hideTopButton && user?.managementType === ManagementType.Admin) && (
          <div className={styles.addFolderButtonContainer}>
            <Button className={styles.addFolderButton} size="smaller" onClick={() => setNewFolderFlag(true)}>
              <>
                <img className={styles.addFolderIcon} src="/images/Add-Plus.svg" alt="" />
                <span>フォルダを追加する</span>
              </>
            </Button>
            <DropdownMenu
              options={[{
                text: '並び順を変更する',
                value: '',
              }]}
              left
              onSelect={handleFolderActionDropdownSelect}
              onOutsideClick={handleFolderActionDropdownOutsideClick}
              open={isFolderActionDropdownOpen}
              allowToggleOnClick
            >
              <div
                ref={threeDotsRef}
                className={styles.threeDotsContainer}
                onClick={() => setIsFolderActionDropdownOpen((prev) => !prev)}
                role="button"
                tabIndex={0}
              >
                <img src="/images/Three-Dots.svg" alt="" />
              </div>
            </DropdownMenu>
          </div>
          )}
        </div>
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={`${mainStyles.input} ${mainStyles['input-search']} ${styles['input-search']}`}
            placeholder="&#xF002; 検索"
            onChange={(e) => handleFolderSearch(e.target.value)}
            ref={searchInputRef}
          />
          <img
            className={styles.clearSearchButton}
            src="/images/CloseMidGray.svg"
            alt="clear-search-button"
            onClick={() => onClearSearch()}
          />
        </div>
      </div>
      <div className={styles.mainFrameLeftSectionList}>
        {newFolderFlag && (<FolderForm folders={folders} onCreate={onCreateFolder} onCancel={onCancelCreateFolder} />)}
        {filteredFolders.map((item) => (
          <FolderItem
            onDocumentFolderChanged={onDocumentFolderChanged}
            key={item.id + 1}
            folder={item}
            folders={folders}
            selected={folder?.id === item.id}
            onSelect={onSelectFolder}
            onEdit={onUpdateForm}
            onDelete={onDeleteForm}
            onDropCompleted={onDropCompleted}
            mainFrameLeftSectionWidth={mainFrameLeftSectionWidth}
            onPinnedStatusChanged={fetchFolders}
            isPinned={isFolderPinned(item)}
          />
        ))}
      </div>
      {sortFoldersModal && <SortFoldersModal handleCancel={() => { setSortFoldersModal(false); }} handleSuccess={() => onSendSortListSuccess()} folders={originalFolderListState} />}
    </div>
  );
}

export default MainFrameLeftSection;
