import { DateTime } from 'luxon';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import mainStyles from '../main.module.css';
import styles from './recieveModal.module.css';
import ScreenModal from '../../components/ScreenModal/screenModal';
import Button from '../../components/Button/button';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import Input from '../../components/Input/input';
import DatePickerJp from '../../components/DatePickerJp';
import BaseText from '../../components/BaseText/BaseText';
import RadioGroupInput from '../../components/RadioGroupInput';
import Formatter from '../../utils/formatters';
import {
  DeliveryAddressSpecify,
  DeliveryConfirmationRequest,
  DeliveryType, DocumentId,
  DocumentWarehousing,
  DocumentWarehousingStoreForm, GetDeliveryCalendarRequestForm,
} from '../../services/http/documentWarehousing.api';
import Validator from '../../utils/validators';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import {
  useDeliveryCalendarApi,
  useDeliveryMethodApi,
  useDocumentWarehousingStoreApi,
} from '../../hooks/api/documentWarehousing.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import AlertModal, {
  alertModalInitialState,
  AlertModalProps,
  CloseAlertModal,
  closeModalInitialState,
} from '../../components/AlertModal';
import ExportLabelComponent from '../confirmedRequestListScreen/exportLabelComponent';
import ControlCode from '../../utils/controlCode';
import { CollectAndDeliver } from '../../services/http/collectAndDeliver.api';
import { useCollectAndDeliverSearchApi } from '../../hooks/api/collectAndDeliver.hook';
import { CartDocument, RequestType } from '../../services/http/reservationCart.api';
import LoadingOverlay from '../../components/LoadingOverlay';
import { DeliveryTime, WarehouseStatusCode } from '../../utils/warehouse.utils';
import AutoCompleteMUI from '../../components/AutoCompleteMUI/AutoCompleteMUI';

export interface Props {
  onCancel: () => void,
  onSuccess: () => void,
  onError: () => void,
  tabDocumentList: CartDocument[],
  requestType: RequestType,
  onDocumentRequestDone: () => void,
}

enum DeliveryMethod {
  SendToWarehouse = '宅配便',
  RequestPickup = '寺田便',
}

function Component({
  onCancel, onSuccess, onError, tabDocumentList, requestType, onDocumentRequestDone,
}: Props) {
  // hooks
  const openMessageModal = useMessageModal();

  // Apis
  const storeDocumentWarehousing = useDocumentWarehousingStoreApi();
  const { request: createLog } = useCreateLogApi();
  const { request: collectAndDeliverSearchApiRequest } = useCollectAndDeliverSearchApi();
  const { request: getDeliveryMethod, loading: deliveryMethodLoading } = useDeliveryMethodApi();
  const { request: getDeliveryCalendar, loading: deliveryCalendarLoading } = useDeliveryCalendarApi();

  // states
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>(DeliveryMethod.SendToWarehouse);
  const [fromDate, setFromDate] = useState<DateTime | null>(null);
  const [form, setForm] = useState<DocumentWarehousingStoreForm>({});
  const [alertModal, setAlertModal] = useState<AlertModalProps>(alertModalInitialState);
  const [closeAlertModal, setCloseAlertModal] = useState<AlertModalProps>(closeModalInitialState);
  const [selectedCollectAndDeliver, setSelectedCollectAndDeliver] = useState<CollectAndDeliver | null>(null);
  const [collectAndDelivers, setCollectAndDelivers] = useState<CollectAndDeliver[]>([]);
  const [isTeradaAvailable, setIsTeradaAvailable] = useState(true);
  const [shortestDesiredDate, setShortestDesiredDate] = useState<string | null>(null);
  const [lastDesiredDate, setLastDesiredDate] = useState<string | null>(null);
  const [disabledDates, setDisabledDates] = useState<string[]>([]);
  const [documentIdList, setDocumentIdList] = useState<DocumentId[]>([]);
  const [holidayList, setHolidayList] = useState<string[]>([]);
  const [documentsForExportLabel, setDocumentsForExportLabel] = useState<DocumentWarehousing[] | null>(null);

  // methods
  const createRequestForm = useCallback((): DocumentWarehousingStoreForm => ({
    ...form,
    deliveryType: deliveryMethod === DeliveryMethod.SendToWarehouse ? DeliveryType.Courier : DeliveryType.TeradaDelivery,
    documentList: documentIdList,
    deliveryTimeCd: deliveryMethod === DeliveryMethod.SendToWarehouse ? undefined : DeliveryTime.NO_SPECIFIC_TIME,
  }), [form, deliveryMethod, documentIdList]);

  const validateForm = useCallback((): boolean => {
    if (form.trdContact && form.trdContact.length > Validator.contactMessageMax) {
      openMessageModal(`連絡事項を${Validator.contactMessageMax}文字以内で入力してください`);
      return false;
    }

    if (
      (deliveryMethod === DeliveryMethod.SendToWarehouse && (!form.emergencyContacts || !form.scheduledDate))
        || (deliveryMethod === DeliveryMethod.RequestPickup && (!isTeradaAvailable || !form.emergencyContacts
            || !selectedCollectAndDeliver || !form.customerStaffName || !form.deliveryName || !form.scheduledDate))
    ) {
      openMessageModal('全ての必須項目を入力してください');
      return false;
    }

    return true;
  }, [form, deliveryMethod, isTeradaAvailable, selectedCollectAndDeliver, openMessageModal]);

  const handleStoreDocumentRequest = useCallback(async (requestForm: DocumentWarehousingStoreForm) => {
    try {
      await storeDocumentWarehousing.request(requestForm);
      return true;
    } catch (error) {
      setCloseAlertModal({
        ...closeAlertModal,
        text: (error as ApiError).message,
        open: true,
        onCancel() {
          setCloseAlertModal({ ...closeAlertModal, open: false });
          onError();
        },
      });
      return false;
    }
  }, [storeDocumentWarehousing, openMessageModal]);

  const showSuccessModal = useCallback((notReceivedDocuments: DocumentWarehousing[]) => {
    if (notReceivedDocuments.length > 0 && requestType === RequestType.RECEIVE) {
      setAlertModal({
        open: true,
        text: (
          <div>
            <div>
              依頼が完了しました。保管品ラベルを発行しますか？
            </div>
            <div style={{ color: '#e60012', marginTop: 10 }}>
              ※新規預け入れの文書には保管品ラベルを貼付してください。
            </div>
            <div style={{ color: '#e60012' }}>
              保管品ラベルは後から発行することも可能です。
            </div>
          </div>
        ),
        onCancel: () => {
          setAlertModal({ ...alertModal, open: false });
          onSuccess();
        },
        onConfirm: () => {
          setAlertModal({ ...alertModal, open: false });
          setDocumentsForExportLabel(notReceivedDocuments);
        },
      });
    } else {
      setCloseAlertModal({
        ...alertModal,
        text: '依頼が完了しました。',
        open: true,
        onCancel() {
          setCloseAlertModal({ ...alertModal, open: false });
          onSuccess();
        },
      });
    }
  }, [alertModal, onSuccess, requestType, setAlertModal, setCloseAlertModal, setDocumentsForExportLabel]);

  const handleSendForm = useCallback(async () => {
    setAlertModal({ ...alertModal, open: false });
    const requestForm = createRequestForm();
    const filteredNotReceivedDocuments = tabDocumentList.map((item) => ({
      ccControlNumber: item.ccControlNumber,
      documentId: item.documentId ?? null,
      documentName: item.documentName,
      documentFileName: item.documentFileName,
      documentFileSize: item.documentFileSize,
      documentTypeId: item.documentTypeId,
      documentTypeName: item.documentTypeName,
      tagList: item.tagList?.map((tag) => ({
        tagLabel: tag.tagLabel,
        tagValue: tag.tagValue,
      })) ?? [],
      relationalDocumentCount: item.relationalDocumentCount,
      warehouseStatus: item.warehouseStatus,
      warehouseStatusCode: item.warehouseStatusCode,
      paperFlg: item.originalFlg,
      electronicFlg: item.electronicFlg,
      registUser: item.registUser,
      registDate: item.registDate,
      updateUser: item.updateUser,
      updateDate: item.updateDate,
      itemCode: item.itemCode ?? '',
      barcodePrinting: item.barcodePrinting,
    })).filter((documentWarehousing) => documentWarehousing.warehouseStatusCode === WarehouseStatusCode.NOT_RECEIVED);

    const isRequestSuccessful = await handleStoreDocumentRequest(requestForm);
    if (!isRequestSuccessful) return;
    onDocumentRequestDone();

    showSuccessModal(filteredNotReceivedDocuments as unknown as DocumentWarehousing[]);
  }, [alertModal, createRequestForm, tabDocumentList, handleStoreDocumentRequest, onDocumentRequestDone, showSuccessModal]);

  const handleSubmit = () => {
    if (!validateForm()) return;

    setAlertModal({
      open: true,
      text: '依頼を送信します。よろしいですか？',
      onCancel: () => {
        setAlertModal({ ...alertModal, open: false });
      },
      onConfirm: handleSendForm,
    });
  };

  const shouldDisableDate = useCallback((dateParam: DateTime) => {
    if (!shortestDesiredDate || !lastDesiredDate) {
      return true;
    }

    const date = Formatter.reformatDateTime(dateParam, Formatter.defaultDateTimeFormat);

    const isBeforeShortestDate = date < Formatter.fromFormatToDateTime(shortestDesiredDate, Formatter.defaultDateTimeFormat);
    const isAfterLastDate = date > Formatter.fromFormatToDateTime(lastDesiredDate, Formatter.defaultDateTimeFormat);
    const isHoliday = holidayList.some((holiday) => date.hasSame(Formatter.fromFormatToDateTime(holiday, Formatter.defaultDateTimeFormat), 'day'));
    const isDisabled = disabledDates.some((disabledDate) => date.hasSame(Formatter.fromFormatToDateTime(disabledDate, Formatter.defaultDateTimeFormat), 'day'));

    return isBeforeShortestDate || isAfterLastDate || isDisabled || isHoliday;
  }, [shortestDesiredDate, lastDesiredDate, holidayList, disabledDates]);

  const updateFormWithCollectAndDeliver = useCallback((collectAndDeliver: CollectAndDeliver) => {
    setForm({
      ...form,
      deliveryCd: collectAndDeliver.deliveryCd,
      deliveryName: collectAndDeliver.deliveryName,
      deliveryPostNo: collectAndDeliver.deliveryPostNo,
      deliveryAddress1: collectAndDeliver.deliveryAddress1,
      deliveryAddress2: collectAndDeliver.deliveryAddress2,
      deliveryAddress3: collectAndDeliver.deliveryAddress3,
      customerStaffName: collectAndDeliver.deliveryName3,
      emergencyContacts: collectAndDeliver.deliveryPhone,
      deliveryFax: collectAndDeliver.deliveryFax,
    });
  }, [form]);

  const createDeliveryMethodForm = useCallback((collectAndDeliver: CollectAndDeliver): DeliveryConfirmationRequest => ({
    reservationType: RequestType.RECEIVE,
    deliveryAddressSpecify: DeliveryAddressSpecify.Specified,
    delivery: [{
      deliveryCd: collectAndDeliver.deliveryCd,
      deliveryPostNo: collectAndDeliver.deliveryPostNo,
      deliveryAddress1: collectAndDeliver.deliveryAddress1,
      deliveryAddress2: collectAndDeliver.deliveryAddress2,
      deliveryAddress3: collectAndDeliver.deliveryAddress3,
    }],
  }), []);

  const createDeliveryCalendarRequestForm = useCallback((collectAndDeliver: CollectAndDeliver): GetDeliveryCalendarRequestForm => ({
    reservationType: RequestType.RECEIVE,
    deliveryServiceId: DeliveryAddressSpecify.Specified,
    delivery: [{
      deliveryCd: collectAndDeliver.deliveryCd,
      deliveryPostNo: collectAndDeliver.deliveryPostNo,
      deliveryAddress1: collectAndDeliver.deliveryAddress1,
      deliveryAddress2: collectAndDeliver.deliveryAddress2,
      deliveryAddress3: collectAndDeliver.deliveryAddress3,
    }],
  }), []);

  const fetchDeliveryMethod = useCallback(async (deliveryMethodForm: DeliveryConfirmationRequest) => {
    try {
      const res = await getDeliveryMethod(deliveryMethodForm);
      const isTerada = res.deliveryServiceList.some((service) => service.deliveryType === DeliveryType.TeradaDelivery);
      setIsTeradaAvailable(isTerada);
      return isTerada;
    } catch (error) {
      openMessageModal((error as ApiError)?.message);
      return false;
    }
  }, [getDeliveryMethod, openMessageModal]);

  const fetchAndSetDeliveryCalendar = useCallback(async (deliveryMethodForm: GetDeliveryCalendarRequestForm) => {
    try {
      const response = await getDeliveryCalendar(deliveryMethodForm);
      setShortestDesiredDate(response.shortestDesiredDate);
      setLastDesiredDate(response.lastDesiredDate);
      setHolidayList(response.holidayList.map((holiday) => holiday.holiday));
      const deliveryTime = response.deliveryTimeList.find((time) => time.deliveryTimeCd === DeliveryTime.NO_SPECIFIC_TIME);
      if (deliveryTime == null) setIsTeradaAvailable(false);
      else setDisabledDates(deliveryTime.unavailableDateList.map((unavailable) => unavailable.unavailableDate));
    } catch (error) {
      openMessageModal((error as ApiError)?.message);
    }
  }, [getDeliveryCalendar, openMessageModal]);

  const handleSelectedCollectAndDelivers = useCallback(async (collectAndDeliver: CollectAndDeliver) => {
    setSelectedCollectAndDeliver(collectAndDeliver);
    updateFormWithCollectAndDeliver(collectAndDeliver);

    const deliveryMethodForm = createDeliveryMethodForm(collectAndDeliver);
    const isTerada = await fetchDeliveryMethod(deliveryMethodForm);

    const deliveryCalendarRequestForm = createDeliveryCalendarRequestForm(collectAndDeliver);

    if (isTerada) {
      await fetchAndSetDeliveryCalendar(deliveryCalendarRequestForm);
    }
  }, [
    updateFormWithCollectAndDeliver,
    createDeliveryMethodForm,
    fetchDeliveryMethod,
    fetchAndSetDeliveryCalendar,
    setSelectedCollectAndDeliver,
  ]);

  const clearCollectionAndDeliver = useCallback(() => {
    setIsTeradaAvailable(true);
    setSelectedCollectAndDeliver(null);
    setFromDate(null);
    setForm({
      ...form,
      deliveryCd: undefined,
      deliveryName: undefined,
      deliveryPostNo: undefined,
      deliveryAddress1: undefined,
      deliveryAddress2: undefined,
      deliveryAddress3: undefined,
      customerStaffName: undefined,
      deliveryFax: undefined,
      scheduledDate: undefined,
      trdContact: '',
      userMemo: '',
      emergencyContacts: undefined,
    });
  }, [form]);

  const onChangeDeliveryMethod = useCallback((value: DeliveryMethod) => {
    setFromDate(null);
    clearCollectionAndDeliver();
    setDeliveryMethod(value as DeliveryMethod);
  }, [clearCollectionAndDeliver]);

  const fetchCollectAndDelivers = useCallback(async () => {
    try {
      const res = await collectAndDeliverSearchApiRequest();
      setCollectAndDelivers(res);
    } catch (error) {
      openMessageModal((error as ApiError)?.message);
    }
  }, [collectAndDeliverSearchApiRequest, openMessageModal]);

  // Memos
  const collectAndDeliverOptions = useMemo(
    () => {
      const res = collectAndDelivers.map((item) => ({ label: `${item.deliveryCd}：${item.deliveryName}`, value: item }));
      return [{ label: '', value: undefined as unknown as CollectAndDeliver }, ...res];
    },
    [collectAndDelivers],
  );

  const getDeliveryCalendarForCourierForm = useMemo(() => ({
    reservationType: requestType,
    delivery: [],
    deliveryServiceId: '2',
  }), [requestType]);

  // effects
  useEffectOnce(() => {
    createLog(LogFormName.WareHousingRequestScreen, LogControlName.Show);
  });

  useEffect(() => {
    const selectedDocumentsIds = tabDocumentList.map((document: CartDocument) => ({ documentId: document.documentId }));
    setDocumentIdList(selectedDocumentsIds);
  }, [tabDocumentList]);

  useEffect(() => {
    fetchCollectAndDelivers();
  }, [fetchCollectAndDelivers]);

  useEffect(() => {
    fetchAndSetDeliveryCalendar(getDeliveryCalendarForCourierForm);
  }, [fetchAndSetDeliveryCalendar, getDeliveryCalendarForCourierForm]);

  return (
    <ScreenModal
      customStyles={{
        minWidth: '800px',
        maxHeight: '90%',
        border: 'var(--defaultBorder)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.title}>
          { requestType === RequestType.RECEIVE ? '新規入庫依頼' : '再入庫依頼' }
        </div>
      </div>
      <div className={styles['modal-body']}>
        <div className={styles['form-row']}>
          <div className={`${styles.label} ${styles['form-left']}`}>対象件数</div>
          <div className={styles['form-right']}>
            <BaseText size="smMd">
              {tabDocumentList.length}
              件
            </BaseText>
          </div>
        </div>
        <div className={`${styles['form-row']} ${styles['no-padding-bottom']}`}>
          <div className={`${styles.label} ${styles['form-left']}`}>集配方法</div>
          <div>
            <div>
              <RadioGroupInput<DeliveryMethod>
                value={deliveryMethod}
                onChange={onChangeDeliveryMethod}
              >
                <RadioGroupInput.RadioInput value={DeliveryMethod.SendToWarehouse}>
                  <span style={{ fontSize: '14px', marginLeft: 0 }}>{DeliveryMethod.SendToWarehouse}</span>
                </RadioGroupInput.RadioInput>
                <RadioGroupInput.RadioInput value={DeliveryMethod.RequestPickup}>
                  <span style={{ fontSize: '14px', marginLeft: 0 }}>{DeliveryMethod.RequestPickup}</span>
                </RadioGroupInput.RadioInput>
              </RadioGroupInput>
            </div>
          </div>
        </div>
        {deliveryMethod === DeliveryMethod.SendToWarehouse && (
        <div className={styles['form-row']} style={{ marginLeft: '180px' }}>
          <BaseText color="var(--danger)" size="sm">※ 宅配便はお客様手配となります</BaseText>
        </div>
        )}

        {deliveryMethod === DeliveryMethod.SendToWarehouse ? (
          <>
            <div className={styles['form-row']}>
              <div className={`${styles.label} ${styles['form-left']}`}>
                倉庫到着予定日
                <span className={styles['required-asterisk']}>*</span>
              </div>
              <div className={`${styles['form-right']}`}>
                <DatePickerJp
                  inputClassName={mainStyles['w-100']}
                  shouldDisableDate={shouldDisableDate}
                  value={fromDate}
                  onChange={(newValue) => {
                    setForm({ ...form, scheduledDate: newValue ? Formatter.fromDateTimeToString(newValue, Formatter.defaultFullDateFormat) : undefined });
                    setFromDate(newValue);
                  }}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disableTyping
                />
              </div>
            </div>

            <div className={`${styles['form-row']}`}>
              <div className={`${styles.label} ${styles['form-left']}`}>
                緊急連絡先
                <span className={styles['required-asterisk']}>*</span>
              </div>
              <div className={`${styles['form-right']} ${mainStyles['mr-20px']}`}>
                <Input
                  type="text"
                  className={mainStyles.input}
                  value={form.emergencyContacts || undefined}
                  onChange={(e) => setForm({ ...form, emergencyContacts: e })}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={`${styles['form-row']} ${styles['no-padding-bottom']}`} style={{ marginTop: '15px' }}>
              <div className={`${styles.label} ${styles['form-left']}`}>
                集配先
                <span className={styles['required-asterisk']}>*</span>
              </div>
              <div className={styles['form-right']}>
                <AutoCompleteMUI
                  value={selectedCollectAndDeliver ? `${selectedCollectAndDeliver.deliveryCd}：${selectedCollectAndDeliver.deliveryName}` : ''}
                  options={collectAndDeliverOptions}
                  onSelect={handleSelectedCollectAndDelivers}
                  menuStyle={{ width: '100%' }}
                  onDeselect={() => {
                    clearCollectionAndDeliver();
                  }}
                />
                <div className={styles['form-right-detail']} style={{ height: '42px' }}>
                  {selectedCollectAndDeliver && (
                  <>
                    <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                      <span style={{ marginRight: '5px' }}>〒</span>
                      {' '}
                      {selectedCollectAndDeliver?.deliveryPostNo}
                    </div>
                    {selectedCollectAndDeliver?.deliveryAddress1}
                    {', '}
                    {selectedCollectAndDeliver?.deliveryAddress2}
                    {', '}
                    {selectedCollectAndDeliver?.deliveryAddress3}
                  </>
                  )}
                </div>
              </div>
            </div>
            {!isTeradaAvailable && (
            <div style={{ marginLeft: '180px' }}>
              <div>
                <BaseText color="var(--danger)" size="sm">※ 選択された集配先は寺田便のエリア対象外です。</BaseText>
                <div>
                  <BaseText color="var(--danger)" size="sm" style={{ marginLeft: 14 }}>他の集配先を選択いただくか、集配方法の「宅配便」を選択してください。</BaseText>
                </div>
              </div>
            </div>
            )}

            <div className={styles['form-row']} style={{ marginTop: '15px' }}>
              <div className={`${styles.label} ${styles['form-left']}`}>
                担当者名
                <span className={styles['required-asterisk']}>*</span>
              </div>
              <div className={styles['form-right']}>
                <Input
                  value={form.customerStaffName || ''}
                  onChange={(e) => setForm({ ...form, customerStaffName: e })}
                  disabled={!isTeradaAvailable || !selectedCollectAndDeliver}
                />
              </div>
            </div>

            <div className={`${styles['form-row']}`}>
              <div className={`${styles.label} ${styles['form-left']}`}>
                TEL
                <span className={styles['required-asterisk']}>*</span>
              </div>
              <div className={`${styles['form-right']} ${mainStyles['mr-20px']}`}>
                <Input
                  value={form?.emergencyContacts || ''}
                  onChange={(e) => setForm({ ...form, emergencyContacts: e })}
                  disabled={!isTeradaAvailable || !selectedCollectAndDeliver}
                />
              </div>
            </div>
            <div className={styles['form-row']}>
              <div className={`${styles.label} ${styles['form-left']}`}>FAX</div>
              <div className={styles['form-right']}>
                <Input
                  value={form?.deliveryFax || ''}
                  onChange={(e) => setForm({ ...form, deliveryFax: e })}
                  disabled={!isTeradaAvailable || !selectedCollectAndDeliver}
                />
              </div>
            </div>
            <div className={styles['form-row']}>
              <div className={`${styles.label} ${styles['form-left']}`}>
                希望日
                <span className={styles['required-asterisk']}>*</span>
              </div>
              <div className={styles['form-right']}>
                <DatePickerJp
                  inputClassName={mainStyles['w-100']}
                  shouldDisableDate={shouldDisableDate}
                  value={fromDate}
                  onChange={(newValue) => {
                    setForm({ ...form, scheduledDate: newValue ? Formatter.fromDateTimeToString(newValue, Formatter.defaultFullDateFormat) : undefined });
                    setFromDate(newValue);
                  }}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disabled={!isTeradaAvailable || !selectedCollectAndDeliver}
                  disableTyping
                />
              </div>
            </div>
          </>
        )}

        <div className={styles['form-row']}>
          <div className={`${styles.label} ${styles['form-left']}`}>
            寺田倉庫への連絡事項
          </div>
          <textarea
            style={{ width: '100%' }}
            className={mainStyles['text-area']}
            defaultValue=""
            value={form.trdContact}
            placeholder="寺田倉庫へ連絡事項がある場合は入力してください。（100文字以内）"
            onChange={(e) => setForm({ ...form, trdContact: e.target.value })}
          />
        </div>

        <div className={styles['form-row']}>
          <div className={`${styles.label} ${styles['form-left']}`}>
            お客様メモ
          </div>
          <textarea
            style={{ width: '100%' }}
            className={mainStyles['text-area']}
            defaultValue=""
            value={form.userMemo}
            placeholder="その他、依頼に対して備考がある場合は入力してください。"
            onChange={(e) => setForm({ ...form, userMemo: e.target.value })}
          />
        </div>

      </div>

      <div className={styles['modal-footer']}>
        <Button
          size="smaller"
          color="lighterGray"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          size="smaller"
          onClick={handleSubmit}
          loading={storeDocumentWarehousing.loading}
        >
          依頼
        </Button>
        <AlertModal modalStyle={{ minWidth: '500px' }} open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} textCenter />
        <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} closeButtonText="OK" />
        {documentsForExportLabel && (
        <ExportLabelComponent
          returnToMain
          exportFrom={ControlCode.Receiving}
          data={documentsForExportLabel}
          display
          setModalDisplay={(modalDisplay) => {
            if (!modalDisplay) {
              setDocumentsForExportLabel(null);
            }
          }}
          tableName="warehousingRequestTable"
        />
        )}
        {(deliveryMethodLoading || deliveryCalendarLoading) && <LoadingOverlay />}
      </div>
    </ScreenModal>
  );
}

export default Component;
