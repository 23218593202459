import React, {
  CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { Autocomplete } from '@mui/material';
import styles from './AutoCompleteMUI.module.css';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
export interface AutoCompleteMUIProps<T=any> {
  options: {
    label: string,
    value: T
  }[]
  onSelect: (selected: T) => void
  menuStyle?: CSSProperties
  onBlur?: () => void
  onBlurSetTextAsSelected?: boolean
  value?: T
  onDeselect?: () => void
}

export default forwardRef((props: AutoCompleteMUIProps, ref) => {
  const [text, setText] = useState<any>(props.value);
  const [inputValue, setInputValue] = useState<string>('');
  const refInput = useRef<HTMLInputElement>(null);

  const onTextInput = (value: string) => {
    setText(value);
  };

  const onSelect = useCallback((value: any) => {
    if (!value.value && props.onDeselect) {
      props.onDeselect();
      return;
    }
    props.onSelect(value.value);
    refInput.current?.blur();
  }, [props]);

  const handleBlur = useCallback(() => {
    if (props.onBlur) {
      props.onBlur();
    }
  }, [props, text]);

  useImperativeHandle(ref, () => ({
    getValue() {
      return text;
    },
  }));

  useEffect(() => {
    refInput.current?.focus();
    refInput.current?.click();
  }, []);

  return (
    <div>
      <Autocomplete
        blurOnSelect
        disablePortal
        freeSolo
        isOptionEqualToValue={(option, value) => option === value}
        value={text}
        onChange={(event: any, newValue: any) => {
          if (newValue != null) {
            setText(newValue.label);
            onSelect(newValue);
          }
        }}
        onBlurCapture={handleBlur}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          onTextInput(newInputValue);
        }}
        id="controllable-states-demo"
        options={props.options}
        sx={{
          width: { sm: '100%' },
          '& + .MuiAutocomplete-popper': {
            '& .MuiAutocomplete-listbox': {
              margin: 0,
              padding: 0,
            },
            '& .MuiAutocomplete-option': {
              height: '40px',
              margin: 0,
              '&:hover': {
                backgroundColor: '#D2D2D2',
              },
              '&:not(:hover)': {
                backgroundColor: 'transparent',
              },
            },
          },
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              type="text"
              {...params.inputProps}
              style={{ width: '100%', paddingRight: '50px' }}
              className={styles.AutoCompleteInputStyle}
            />
          </div>
        )}
      />
    </div>
  );
});
