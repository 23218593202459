/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
import { useNavigate } from 'react-router-dom';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  ColDef,
  FirstDataRenderedEvent, GetRowIdParams,
  ICellRendererParams,
  RowClickedEvent,
  RowGroupOpenedEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import Mark from 'mark.js';
import { toast } from 'react-toastify';
import mainStyles from '../../pages/main.module.css';
import styles from './mainFrameRightSection.module.css';
import routes from '../../utils/routes';
import {
  useDocumentDisplayMode,
  useDocumentDownload,
  useDocumentGetDetail,
} from '../../hooks/api/document.hook';
import {
  ContractFlag,
  Document, DocumentDetail, SortList,
} from '../../services/http/documents.api';
import { Folder } from '../../services/http/folder.api';
import BaseTable from '../BaseTable';
import DropdownMenu from '../DropdownMenu';
import Button from '../Button/button';
// eslint-disable-next-line import/no-cycle
import DocDetailModalFrame from '../DocDetailModal/docDetailModalFrame';
import Breadcrumb from '../Breadcrumb';
import ExportLabelComponent from '../../pages/confirmedRequestListScreen/exportLabelComponent';
import ControlCode from '../../utils/controlCode';
import AGUtils from '../../utils/ag-grid.utils';
import PaginationJp from '../BaseTable/partials/PaginationJp/mannual';
import { useGetAllTagsApi } from '../../hooks/api/tag.hook';
import RelationalDocumentsDetailCellRenderer from '../BaseTable/partials/relationalDocumentsDetailCellRenderer/relationalDocumentsDetailCellRenderer';
import AlertModal, {
  alertModalInitialState, AlertModalProps,
} from '../AlertModal';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import LoadingOverlay from '../LoadingOverlay';
import { ManagementType, useAuthContext } from '../../store/auth.store';
import { WarehouseStatusCode } from '../../utils/warehouse.utils';
import { useCreateDownloadFileLogApi, useCreateLogApi } from '../../hooks/api/log.hook';
import { LogFormName } from '../../utils/log.utils';
import CheckModalBody from '../CheckModalBody/checkModalBody';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { ContractType, WarehouseStatusType } from '../SearchInputTop';
import Checkbox from '../Checkbox';
import PdfIcon from '../../assets/images/pdf-icon.png';
import { STATUS_MESSAGES } from '../../utils/messages';
import { PdfPreviewModal } from '../PdfPreviewModal';
import { useReservationCartRegister } from '../../hooks/api/reservationCart.hook';
import { RegisterCartResponseMessageType, RequestType } from '../../services/http/reservationCart.api';
import renderCartIcon from './renderCartIcon';

export enum SearchType {
  FOLDER = 'folder',
  ADVANCED = 'advanced',
  SIMPLE = 'simple',
}

export interface DocumentsAndSearchType {
  documents: Document[];
  searchType: SearchType | null;
}

export const defaultDocumentsAndSearchType: DocumentsAndSearchType = {
  documents: [],
  searchType: null,
};

export interface Pagination {
  page: number,
  totalItems: number,
  pageLimit: number,
  totalPages: number,
}

export const defaultPagination: Pagination = {
  page: 1,
  totalItems: 0,
  pageLimit: 0,
  totalPages: 0,
};

interface SelectedFilesToDocumentWarehousing {
  ccControlNumber: number,
  documentId: number,
  documentName: string,
  documentFileName?: string,
  documentFileSize?: number,
  documentTypeId?: number,
  documentTypeName?: string,
  tagList: { tagLabel: string, tagValue: string }[],
  relationalDocumentCount?: number,
  warehouseStatus?: string,
  warehouseStatusCode?: string,
  paperFlg?: ContractFlag,
  electronicFlg?: ContractFlag,
  registUser: string,
  registDate: Date,
  updateUser: string,
  updateDate: Date,
  itemCode: string,
  barcodePrinting: string,
  cartAddedFlg?: number,
}

export const mainPageTableName = 'mainPageTable_v1';

/**
 * メインフレームの右セクションのプロップスインターフェース
 */
export interface Props {
  /** 選択されているフォルダ */
  folder: Folder | null,
  /** フォルダの権限変更時のコールバック */
  onChangingFolderPermissions: () => void,
  // --------------------------------------------------
  documentsAndSearchType: DocumentsAndSearchType,
  pagination: Pagination,
  isSearching: boolean,
  highlightTexts: string[],
  warehouseStatus: WarehouseStatusType,
  contractType: ContractType,
  isFolderListEmpty: boolean,
  isFolderListLoading: boolean,
  onPageChanged: (page: number) => void,
  onCloseSearchSection: () => void,
  onSortChanged: (sortList: SortList[]) => void,
  onRemoveDocument: (document: DocumentDetail) => void,
  onRefresh: (page: number) => void,
  dropCompletedTrigger: number,
}

/**
 * メインフレームの右セクションのコンポーネント
 */
function MainFrameRightSection({
  folder,
  onChangingFolderPermissions,
  // ----------------------
  documentsAndSearchType,
  pagination,
  isSearching,
  highlightTexts,
  warehouseStatus,
  contractType,
  isFolderListEmpty,
  isFolderListLoading,
  onPageChanged,
  onCloseSearchSection,
  onSortChanged,
  onRemoveDocument,
  onRefresh,
  dropCompletedTrigger,
}: Props) {
  // refs
  const inputFile = useRef<HTMLInputElement | null>(null);
  const tableRef = useRef<AgGridReact<Document>>(null);

  // States
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [selectDocument, setSelectedDocument] = useState<Document | null>(null);
  const [labelExportDisplay, setLabelExportDisplay] = useState(false);
  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);
  const [exportLabelDocuments, setExportLabelDocuments] = useState<SelectedFilesToDocumentWarehousing[]>([]);
  const [sortedIdList, setSortedIdList] = useState<number[]>([]);
  const [preventModalClose, setPreventModalClose] = useState(false);
  const [pdfForModal, setPdfForModal] = useState<File | null>(null);

  const [suppressModal, setSuppressModal] = useState(false);
  const [detailedDocument, setDetailedDocument] = useState<DocumentDetail | null>(null);

  const {
    user, hasPermission, hasFolderPermission,
  } = useAuthContext();

  const selectedDocumentsRef = useRef<Document[]>(selectedDocuments);

  // Hooks
  const { displayMode } = useDocumentDisplayMode();
  const { allTags, reload: getAllTags, loadingAllTags } = useGetAllTagsApi();
  const navigate = useNavigate();

  const openMessageModal = useMessageModal();
  const documentGetDetail = useDocumentGetDetail();
  const { request: documentDownload, loading: documentDownloadLoading } = useDocumentDownload();
  const { request: createDownloadLog } = useCreateDownloadFileLogApi();
  const { request: reservationCartRegister } = useReservationCartRegister();

  // constants
  const cantGoNext = pagination.page === pagination.totalPages;
  const cantGoPrevious = pagination.page === 1;
  const isDocumentDraggable = true;

  const { request: createLog } = useCreateLogApi();

  // Methods
  const onPdfPreviewRequest = useCallback((doc: Document) => {
    setSuppressModal(true);
    if (doc) {
      try {
        documentGetDetail.request({ id: doc.id }).then(setDetailedDocument);
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    }
  }, [createLog, documentGetDetail, openMessageModal]);

  useEffect(() => {
    if (detailedDocument) {
      if (detailedDocument.documents.length === 0) {
        openMessageModal('PDFファイルが見つかりませんでした。');
        return;
      }
      const current = detailedDocument.documents.sort((a, b) => b.versionNo - a.versionNo)[0];
      documentDownload({ documentDataId: current.dataId, fileName: current.fileName })
        .then((file) => {
          createDownloadLog(current.dataId, 1);
          setPdfForModal(file);
        })
        .catch(() => openMessageModal('PDFファイルが見つかりませんでした。'));
    }
  }, [detailedDocument, documentDownload, openMessageModal]);

  const toggleSelectDocument = useCallback((document: Document) => {
    const selectedDocument = selectedDocuments.find((d) => d.id === document.id);
    if (selectedDocument) {
      setSelectedDocuments(selectedDocuments.filter((d) => d.id !== selectedDocument.id));
    } else {
      setSelectedDocuments([...selectedDocuments, document]);
    }
  }, [selectedDocuments]);

  const onFirstDataRendered = useCallback((e: FirstDataRenderedEvent<Document>) => e.api?.forEachNodeAfterFilter((node) => {
    node.setSelected(selectedDocuments.some((document) => node.data?.id === document.id), true, true);
  }), [selectedDocuments]);

  const selectedFilesToDocumentWarehousing = useCallback((): SelectedFilesToDocumentWarehousing[] => selectedDocuments.map((item) => ({
    ccControlNumber: item.ccControlNumber,
    documentId: item.id,
    documentName: item.name,
    documentFileName: item.fileName,
    documentFileSize: item.fileSize,
    documentTypeId: item.typeId,
    documentTypeName: item.typeName,
    tagList: item.tagList?.map((tag) => ({
      tagLabel: tag.label,
      tagValue: tag.value,
    })) ?? [],
    relationalDocumentCount: item.relationalDocumentCount,
    warehouseStatus: item.warehouseStatus,
    warehouseStatusCode: item.warehouseStatusCode,
    paperFlg: item.paperFlg !== undefined ? item.paperFlg : undefined,
    electronicFlg: item.electronicFlg !== undefined ? item.electronicFlg : undefined,
    registUser: item.registUser,
    registDate: item.registDate,
    updateUser: item.updateUser,
    updateDate: item.updateDate,
    itemCode: item.itemCode ?? '',
    barcodePrinting: item.barcodePrinting,
    cartAddedFlg: item.cartAddedFlg,
  })), [selectedDocuments]);

  const getRequestType = useCallback((controlCd: string): RequestType => {
    const requestTypes = {
      [ControlCode.Receiving]: RequestType.RECEIVE,
      [ControlCode.Warehousing]: RequestType.DISPATCH,
      [ControlCode.Destruction]: RequestType.DISPOSAL,
      [ControlCode.Scan]: RequestType.REMOTE_SCAN,
    };

    return requestTypes[controlCd as keyof typeof requestTypes];
  }, []);

  const updateGridRowsWithCartFlag = useCallback((documentListIds: { documentId: number }[]) => {
    if (!tableRef.current) return;

    const gridApi = tableRef.current.api;

    gridApi.forEachNode((node) => {
      const document = node.data as Document | undefined;
      const isInCart = documentListIds.some(({ documentId }) => documentId === document?.id);

      if (document && isInCart) {
        document.cartAddedFlg = 1;
        node.setData({ ...document });
      }
    });
  }, [tableRef]);

  const addToCart = useCallback(async (controlCd: string, documentList: SelectedFilesToDocumentWarehousing[]) => {
    const documentListIds = documentList.map(({ documentId }) => ({ documentId }));
    const requestType = getRequestType(controlCd);

    if (!requestType) return;

    try {
      const response = await reservationCartRegister({
        documentList: documentListIds,
        requestType,
      });

      updateGridRowsWithCartFlag(response.documentList);
      if (response.messageType === RegisterCartResponseMessageType.TOAST) {
        toast(response.message);
      }
      if (response.messageType === RegisterCartResponseMessageType.ALERT) {
        openMessageModal(response.message);
      }
    } catch (error) {
      openMessageModal((error as ApiError)?.message);
    }
  }, [getRequestType, reservationCartRegister, updateGridRowsWithCartFlag, openMessageModal]);

  const checkControlCodeAndDisplayMessage = async (controlCd: string) => {
    const documentWarehousingList = selectedFilesToDocumentWarehousing();
    const documentsToAdd: SelectedFilesToDocumentWarehousing[] = [];
    const documentsCanNotAdd: SelectedFilesToDocumentWarehousing[] = [];
    let allowedStatusMessage = '';

    if ((controlCd === ControlCode.Scan || controlCd === ControlCode.Warehousing || controlCd === ControlCode.Destruction) && documentWarehousingList.length > 300) {
      openMessageModal(`${ControlCode.describe(controlCd)}依頼の受付上限は300件です。\n現在${documentWarehousingList.length}件選択されています。`);
      return;
    }

    if (controlCd === ControlCode.Receiving || controlCd === ControlCode.Scan || controlCd === ControlCode.Warehousing || controlCd === ControlCode.Destruction) {
      documentWarehousingList.forEach((document) => {
        documentsToAdd.push(document);
      });
    }
    if (controlCd === '保管品ラベル発行') {
      allowedStatusMessage = '以下の文書は未入庫のため、保管品ラベルの発行対象外となりますがよろしいですか？';
      documentWarehousingList.forEach(async (document) => {
        if (String(document.warehouseStatusCode) !== String(WarehouseStatusCode.NOT_RECEIVED)) {
          documentsToAdd.push(document);
        } else {
          documentsCanNotAdd.push(document);
        }
      });
      setExportLabelDocuments(documentsToAdd);
    }

    if (documentsCanNotAdd.length > 0) {
      const modalTitle = (controlCd === '保管品ラベル発行' ? allowedStatusMessage : '以下の文書は対象外のため依頼に含まれませんがよろしいですか？');
      const modalItems = documentsCanNotAdd.map((document) => document.documentName);
      const modalFooter = (controlCd !== '保管品ラベル発行' ? allowedStatusMessage : '');

      setModal({
        modalStyle: { width: '650px' },
        open: true,
        text: <CheckModalBody title={modalTitle} items={modalItems} footer={modalFooter} />,
        onConfirm: async () => {
          if (documentsToAdd.length === 0) {
            setModal({ ...modal, open: false });
            return;
          }
          setLabelExportDisplay(true);
          setModal({ ...modal, open: false });
        },
        onCancel: async () => setModal({ ...modal, open: false }),
      });
    } else {
      if (controlCd === '保管品ラベル発行') {
        setLabelExportDisplay(true);
        return;
      }

      await addToCart(controlCd, documentsToAdd);
    }
  };

  const MarkJsInstance = useMemo(() => new Mark('.highlightTarget'), []);

  const unMarkText = useCallback(() => MarkJsInstance?.unmark(), [MarkJsInstance]);

  const markText = useCallback((texts: string[]) => {
    const markJsOptions = { acrossElements: true };
    MarkJsInstance?.mark(texts, markJsOptions);
  }, [MarkJsInstance]);

  const highLightSearch = useCallback(() => {
    setTimeout(() => {
      unMarkText();
      markText(highlightTexts);
    }, 300);
  }, [markText, unMarkText, highlightTexts]);

  useEffect(() => {
    if (highlightTexts.length > 0) {
      markText(highlightTexts);
      return;
    }
    unMarkText();
  }, [highlightTexts, markText, unMarkText]);

  const onDragStart = useMemo(() => (dragEvent: React.DragEvent<HTMLDivElement>, document: Document) => {
    const documentFolderId = folder ? folder.id : document.folderId;
    let selectedDocumentsToTransfer: { document: Document, folderId: number }[] = [];
    if (selectedDocumentsRef.current.length > 0) {
      const filteredDocuments = selectedDocumentsRef.current.filter((doc) => doc.requiredTagInputFlg === 1);
      selectedDocumentsToTransfer = filteredDocuments.map((doc) => {
        const docFolderId = folder ? folder.id : doc.folderId;
        return { document: doc, folderId: docFolderId };
      });
    } else {
      selectedDocumentsToTransfer = [{ document, folderId: documentFolderId }];
    }
    const { userAgent } = window.navigator;
    const isIE = userAgent.indexOf('Trident/') >= 0;
    dragEvent?.dataTransfer?.setData(
      isIE ? 'text' : 'text/plain',
      JSON.stringify({
        documentsTransfer: selectedDocumentsToTransfer,
        folderId: folder?.id,
      }),
    );
  }, [folder]);

  const relationalDocumentsDetailCellRenderer = (e: { data: Document }) => (
    <RelationalDocumentsDetailCellRenderer
      relationalDocumentList={e.data.relationalDocumentList}
    />
  );

  const onExpandRelationalDocumentDetailRow = (params: ICellRendererParams) => {
    const documentExists = params.node.data.relationalDocumentList.length;
    const detailRowIsExpanded = tableRef.current?.api.getDisplayedRowAtIndex(params.node.rowIndex as number)?.expanded;

    if (!documentExists) return;
    if (!detailRowIsExpanded) {
      tableRef.current?.api.getDisplayedRowAtIndex(params.node.rowIndex as number)?.setExpanded(true);
    } else {
      tableRef.current?.api.getDisplayedRowAtIndex(params.node.rowIndex as number)?.setExpanded(false);
    }
  };

  const onFileSelected = useCallback((value: FileList | null, folderParam: Folder | null) => {
    if (value && folderParam) {
      navigate(routes.csvImportScreen, { state: { file: value[0], folderId: folderParam.id } });
    }
  }, [navigate]);

  const onRowGroupOpened = (params: RowGroupOpenedEvent<Document>) => {
    if (params.node.expanded) {
      params.api.forEachNode((node) => {
        if (
          node.expanded
                    && node.id !== params.node.id
        ) {
          node.setExpanded(false);
        }
      });
    }
  };

  const handleLabelGenerate = async () => {
    await checkControlCodeAndDisplayMessage('保管品ラベル発行');
  };

  const onDropdownOptionSelected = (e: string) => {
    if (e === '一括登録') {
      inputFile.current?.click();
    }
    if (e === '文書を追加する') {
      if (folder) {
        navigate(routes.docRegisterScreen, { state: { folderId: folder.id } });
      }
    }
  };

  const closeModal = () => {
    if (preventModalClose) {
      setPreventModalClose(false);
      return;
    }
    setTimeout(() => {
      setSelectedDocument(null);
    }, 100);
  };

  const handleRemoveDocument = (document: DocumentDetail) => {
    onRemoveDocument(document);
    setSelectedDocuments([]);
  };

  // Memos
  const addDocOption = useMemo(() => [
    { text: '文書を追加する', value: '文書を追加する' },
    { text: '一括登録', value: '一括登録' },
  ], []);

  const filteredDocuments = useMemo(() => {
    let result = documentsAndSearchType.documents;
    // if (warehouseStatus) result = result.filter((item) => item.warehouseStatus === WarehouseStatus.NOT_RECEIVED);
    if (warehouseStatus !== WarehouseStatusType.ALL) {
      result = result.filter((item) => {
        if (warehouseStatus === WarehouseStatusType.NOT_STORED) return item.warehouseStatusCode === WarehouseStatusCode.NOT_RECEIVED;
        if (warehouseStatus === WarehouseStatusType.STORED) return item.warehouseStatusCode === WarehouseStatusCode.IN_STORAGE;
        return true;
      });
    }
    if (contractType !== ContractType.ALL) {
      result = result.filter((item) => {
        if (contractType === ContractType.ELECTRONIC) return item.electronicFlg;
        if (contractType === ContractType.PAPER) return item.paperFlg !== null && !item.electronicFlg;
        return true;
      });
    }

    return result;
  }, [contractType, documentsAndSearchType.documents, warehouseStatus]);

  const colsDefs = useMemo((): ColDef<Document>[] => {
    const cols: ColDef[] = [
      {
        headerName: '',
        field: 'cart',
        maxWidth: 30,
        lockPosition: true,
        suppressColumnsToolPanel: true,
        cellRenderer: renderCartIcon,
        pinned: 'left',
      },
      {
        headerName: 'Checkbox',
        field: 'checkbox',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        maxWidth: 50,
        minWidth: 50,
        lockPosition: true,
        suppressColumnsToolPanel: true,
        cellClass: 'add-border-right',
        pinned: 'left',
      },
      {
        headerName: '文書名',
        resizable: true,
        sortable: true,
        field: 'name',
        width: 250,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams) => (
          <div
            className={mainStyles['ml-3']}
            style={{ alignContent: 'center', display: 'flex' }}
            draggable={params.node.data.requiredTagInputFlg === 1 ? isDocumentDraggable : false}
            onDragStart={(e) => onDragStart(e, params.node.data)}
          >
            {params.node.data.fileName
              ? (
                <img
                  src="/images/Pdf-icon.svg"
                  alt="pdf"
                  onClick={() => onPdfPreviewRequest(params.node.data)}
                />
              )
              : (
                <img
                  src="/images/Pdf-icon-semitransparent.svg"
                  alt="pdf"
                  onClick={() => setSuppressModal(true)}
                />
              )}
            {isSearching ? (
              <div className={[mainStyles['ml-2'], mainStyles['text-overflow'], styles.docName].join(' ')}>
                <div className={styles.folderName}>
                  {params.node.data.folderName}
                </div>
                <div
                  className={[mainStyles['ml-2'], mainStyles['text-overflow'], styles.docName, 'highlightTarget'].join(' ')}
                >
                  {params.value}
                </div>
              </div>
            ) : (
              <span className={[mainStyles['ml-2'], mainStyles['text-overflow'], 'highlightTarget'].join(' ')}>
                {params.value}
              </span>
            )}
            {params.node.data.requiredTagInputFlg !== 1 && (
            <div style={{ marginLeft: 'auto' }}>
              <img src="/images/Icon-awesome-exclamation.svg" alt="exclamation" style={{ height: '12px', marginRight: '5px' }} />
            </div>
            )}
          </div>
        ),
      }, {
        headerName: '',
        resizable: true,
        sortable: true,
        field: 'relationalDocumentCount',
        width: 90,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams<Document>) => (
          <button
            type="button"
            className={params.value ? styles.tableButton : styles.tableButtonDisabled}
            onClick={() => {
              onExpandRelationalDocumentDetailRow(params);
            }}
          >
            <img src="/images/Icon-awesome-link.svg" alt="" />
            {` ${params.value}`}
          </button>
        ),
        headerComponentParams: {
          template: '<div style={{ visibility: "hidden" }}></div>',
        },
      },
      AGUtils.colDefault('typeName', '文書種類'),
      AGUtils.colDefault('warehouseStatus', 'ステータス'),
      AGUtils.colDefault('fileName', 'ファイル名', 170, true, true),
      AGUtils.colFileSize('fileSize', 'サイズ'),
      {
        headerName: '契約種別',
        field: 'electronicFlg',
        resizable: true,
        sortable: true,
        valueGetter: (params: ValueGetterParams<Document>) => {
          if (!params.data) return null;
          if (params.data.electronicFlg === 1) return '電子契約';
          if (params.data.electronicFlg === 0) return '書面契約';
          return null;
        },
        width: 100,
      },
      {
        headerName: '書面有無',
        resizable: true,
        sortable: true,
        field: 'paperFlg',
        width: 105,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams<Document>) => (
          <button
            type="button"
            className={params.value ? styles.tableButton : styles.tableButtonDisabled}
          >
            書面
          </button>
        ),
      },
      AGUtils.colDefault('ccControlNumber', '文書ID'),
      AGUtils.colDefault('barcodePrinting', 'バーコード印字欄', 150, true, true),
      AGUtils.colDefault('itemCode', '保管品バーコード', 150, true, true),
      AGUtils.colUsername('registUser', '登録者'),
      AGUtils.colDate('registDate', '登録日時'),
      AGUtils.colUsername('updateUser', '更新者'),
      AGUtils.colDate('updateDate', '更新日時'),
    ];

    const tags = new Set<string>(allTags.map((tag) => tag.tagLabel));
    const unique = Array.from(tags);

    unique.forEach((tag) => {
      const apiTag = allTags.find(({ tagLabel }) => (tagLabel === tag)) ?? null;
      cols.push({
        field: `${tag}`,
        headerName: tag,
        resizable: true,
        sortable: true,
        width: 160,
        cellClass: 'highlightTarget',
        valueGetter: AGUtils.getTagValueGetter(tag, apiTag),
      });
    });

    return cols;
  }, [allTags, folder, onDragStart, isDocumentDraggable, isSearching]);

  const applyPinnedColumns = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.columnApi.applyColumnState({
        state: [
          { colId: 'cart', pinned: 'left' },
          { colId: 'checkbox', pinned: 'left' },
        ],
        applyOrder: true,
      });
    }
  }, [tableRef]);

  const onSelectDocument = (document: Document) => {
    if (suppressModal) {
      setSelectedDocument(null);
      setTimeout(() => {
        setSuppressModal(false);
      }, 200);
    } else if (document) setSelectedDocument(document);
  };

  const onTableRowClicked = (e: RowClickedEvent) => {
    const isRelationalDocumentCell = tableRef.current?.api?.getFocusedCell()?.column?.getColId() === 'relationalDocumentCount';
    if (!isRelationalDocumentCell) {
      onSelectDocument(e.data);
    }
  };

  const handleSortedData = (data: Document[]) => {
    const ids = data.map((d) => d.id);
    setSortedIdList(ids);
  };

  const noRowsText = useMemo(() => {
    if (isFolderListLoading || filteredDocuments.length !== 0) return '　';
    if (isFolderListEmpty) return 'フォルダを作成してください';
    if (!folder && !isSearching) return 'フォルダを選択してください';
    return STATUS_MESSAGES.NO_DATA;
  }, [folder, isFolderListEmpty, isFolderListLoading, filteredDocuments, isSearching]);

  // Effects

  useEffectOnce(() => {
    getAllTags();
  });

  useEffect(() => {
    setSelectedDocuments([]);
  }, [folder]);

  useEffect(() => {
    closeModal();
  }, [folder, selectedDocuments, isSearching, documentsAndSearchType]);

  useEffect(() => {
    if (documentsAndSearchType.searchType === SearchType.SIMPLE) {
      highLightSearch();
    }
  }, [documentsAndSearchType.searchType, highLightSearch, highlightTexts]);

  useEffect(() => {
    setSelectedDocuments([]);
  }, [dropCompletedTrigger]);

  useEffect(() => {
    selectedDocumentsRef.current = selectedDocuments;
  }, [selectedDocuments]);

  return (
    <div className={styles.mainFrameRightSection}>
      {!isSearching && (
        <Breadcrumb crumbs={[{ label: `${folder?.name ? folder.name : ''}` }]} />
      )}

      {isSearching && (
        <div className={styles.box}>
          <div className={styles.boxLeft}>
            <div className={styles.boxLeftTitle}>
              <span>検索結果</span>
            </div>
          </div>
          <div className={styles.boxRight}>
            <div
              role="button"
              tabIndex={0}
              className={styles.boxRightButton}
              onClick={onCloseSearchSection}
            >
              結果を閉じる
            </div>
          </div>
        </div>

      )}

      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={(e) => onFileSelected(e.target.files, folder)}
        accept=".csv"
      />
      <div
        className={[styles.mainFrameRightSectionTop, selectedDocuments.length > 0 ? styles.topMinWidth : ''].join(' ')}
      >
        {(selectedDocuments.length === 0) && (
        <div className={styles.mainFrameRightSectionTopButtons}>
          {!isSearching && (
          <>
            {folder && (
            <DropdownMenu
              className={styles.docDropdownMenu}
              options={addDocOption}
              left
              onSelect={onDropdownOptionSelected}
              disabled={!hasFolderPermission(folder, ControlCode.Write)}
            >
              <Button
                onClick={closeModal}
                className={styles.addDocButton}
                disabled={!hasFolderPermission(folder, ControlCode.Write)}
                size="smaller"
              >
                <>
                  <img className={styles.addDocIcon} src={hasFolderPermission(folder, ControlCode.Write) ? '/images/Add-Plus.svg' : '/images/Add-Plus-Disabled.svg'} alt="" />
                  <span>文書を追加する</span>
                </>
              </Button>
            </DropdownMenu>
            )}
            {(user?.managementType === ManagementType.Admin && folder) && (
            <Button
              className={`${mainStyles['ml-10']} ${mainStyles['justify-content-center']}`}
              size="smaller"
              onClick={() => onChangingFolderPermissions()}
            >
              権限を変更する
            </Button>
            )}
          </>
          )}
          {/* Keep this button hidden for now. This was a client request */}
          {/* {folder && ( */}
          {/* <Button */}
          {/*  outline */}
          {/*  className={styles.listButton} */}
          {/*  onClick={toggleDisplayMode} */}
          {/* /> */}
          {/* )} */}
        </div>
        )}
        {(selectedDocuments.length > 0) && (
        <div className={styles.onRowSelected}>
          <div>
            原本を倉庫に
            <Button
              className={styles.mr10}
              disabled={!hasPermission(ControlCode.Receiving)}
              size="smaller"
              onClick={() => checkControlCodeAndDisplayMessage(ControlCode.Receiving)}
            >
              入庫
            </Button>
          </div>
          <div>
            倉庫に預けた文書を
            <Button
              className={styles.mr10}
              disabled={!hasPermission(ControlCode.Scan)}
              size="smaller"
              onClick={() => checkControlCodeAndDisplayMessage(ControlCode.Scan)}
            >
              リモートスキャン
            </Button>
            <Button
              className={styles.mr10}
              disabled={!hasPermission(ControlCode.Warehousing)}
              size="smaller"
              onClick={() => checkControlCodeAndDisplayMessage(ControlCode.Warehousing)}
            >
              出庫
            </Button>
            <Button
              className={styles.mr10}
              disabled={!hasPermission(ControlCode.Destruction)}
              size="smaller"
              onClick={() => checkControlCodeAndDisplayMessage(ControlCode.Destruction)}
            >
              廃棄
            </Button>
          </div>
          <div>
            <Button
              className={styles.mr10}
              size="smaller"
              onClick={() => handleLabelGenerate()}
            >
              保管品ラベルを発行
            </Button>
          </div>
        </div>
        )}
      </div>
      { displayMode === 'list' && (
      <BaseTable<Document>
        tableRef={tableRef}
        formName={mainPageTableName}
        waitToLoad={loadingAllTags}
        rowData={filteredDocuments}
        columnDefs={colsDefs}
        rowSelection="multiple"
        suppressRowClickSelection
        onSelectionChanged={(e) => setSelectedDocuments(e.api.getSelectedRows())}
        onRowClicked={(e) => onTableRowClicked(e)}
        onFirstDataRendered={onFirstDataRendered}
        minWidthProp={selectedDocuments.length > 0 ? '1141px' : '0'}
        sideBar
        pagination
        paginationPageSize={500}
        suppressPaginationPanel
        masterDetail
        detailCellRenderer={relationalDocumentsDetailCellRenderer}
        detailRowAutoHeight
        onRowGroupOpened={onRowGroupOpened}
        embedFullWidthRows
        suppressMenuHide={false}
        rowDragManaged
        rowDragMultiRow
        sortChangedHandler={onSortChanged}
        handleSortedData={handleSortedData}
        onRowDataUpdated={() => { highLightSearch(); }}
        onBodyScroll={() => { highLightSearch(); }}
        noRowsText={noRowsText}
        createLogOnDownloadTableData={LogFormName.Main}
        useColPin
        onColumnSettingsApplied={applyPinnedColumns}
        getRowId={(params: GetRowIdParams<Document>) => String(params.data.id)}
      />
      )}
      {displayMode === 'grid' && (
      <div className={[styles.fileGridContainer].join(' ')}>
        {
              filteredDocuments.map((document) => (
                <div
                  className={[mainStyles['p-2'], styles.fileCard].join(' ')}
                  key={document.id}
                  draggable={isDocumentDraggable}
                  onDragStart={(e) => onDragStart(e, document)}
                >
                  <Checkbox
                    className={styles.fileCardCheckbox}
                    value={selectedDocuments.some((item) => item.id === document.id)}
                    onChange={() => toggleSelectDocument(document)}
                  />
                  <div
                    className={styles.fileCardImageContainer}
                    aria-hidden
                    onClick={() => onSelectDocument(document)}
                  >
                    {document.fileName
                      ? <img src={PdfIcon} alt="pdf" height={50} />
                      : <div style={{ height: '50px' }} />}
                  </div>
                  <span className={styles.fileCardTitle}>{document.name}</span>
                </div>
              ))
          }
      </div>
      )}
      {displayMode === 'largeGrid' && (
      <div className={[styles.fileGridContainer].join(' ')}>
        {
              filteredDocuments.map((document) => (
                <div
                  className={[mainStyles['p-2'], styles.fileCardLarge].join(' ')}
                  key={document.id}
                  draggable={isDocumentDraggable}
                  onDragStart={(e) => onDragStart(e, document)}
                >
                  <Checkbox
                    className={styles.fileCardCheckbox}
                    value={selectedDocuments.some((item) => item.id === document.id)}
                    onChange={() => toggleSelectDocument(document)}
                  />
                  <div
                    className={styles.fileCardImageContainer}
                    aria-hidden
                    onClick={() => onSelectDocument(document)}
                  >
                    {document.fileName
                      ? <img src={PdfIcon} alt="pdf" height={100} />
                      : <div style={{ height: '100px' }} />}
                  </div>
                  <span className={styles.fileCardTitle}>{document.name}</span>
                </div>
              ))
          }
      </div>
      )}
      { pagination.totalItems > 0 && (
      <PaginationJp
        totalPage={pagination.totalPages}
        pageInfo={{ pageLimit: pagination.pageLimit, page: pagination.page, total: pagination.totalItems }}
        onBtFirst={() => {
          if (cantGoPrevious) return;
          onPageChanged(1);
        }}
        onBtLast={() => {
          if (cantGoNext) return;
          onPageChanged(pagination.totalPages);
        }}
        onBtNext={() => {
          if (cantGoNext) return;
          onPageChanged(pagination.page + 1);
        }}
        onBtPrevious={() => {
          if (cantGoPrevious) return;
          onPageChanged(pagination.page - 1);
        }}
      />
      )}
      {selectDocument && (
      <DocDetailModalFrame
        document={selectDocument}
        onClose={closeModal}
        onRemoveDocument={handleRemoveDocument}
        onDocumentUpdated={() => onRefresh(pagination.page)}
        onCancelAddingRelatedDocuments={() => {
          setPreventModalClose(true);
          onRefresh(pagination.page);
        }}
      />
      )}
      {labelExportDisplay && (
      <ExportLabelComponent
        sortedIdList={sortedIdList}
        data={exportLabelDocuments}
        display={labelExportDisplay}
        setModalDisplay={setLabelExportDisplay}
        tableName="mainPageExportLabelComponent"
      />
      )}
      <AlertModal
        open={modal.open}
        text={modal.text}
        onCancel={modal.onCancel}
        onConfirm={modal.onConfirm}
        modalStyle={modal.modalStyle}
      />
      { (detailedDocument && pdfForModal) && (
        <PdfPreviewModal
          open
          onClose={() => setPdfForModal(null)}
          pdfFile={pdfForModal}
          fileName={detailedDocument?.documents[0].fileName}
          fileId={detailedDocument?.documents[0].dataId}
        />
      )}

      {documentDownloadLoading ? <LoadingOverlay /> : null}
    </div>
  );
}

export default MainFrameRightSection;
