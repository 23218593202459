import { useState, useCallback } from 'react';
import { ApiError } from '../../services/http';
import TagApi, {
  BaseTag, DocumentTag,
  GetAllTagsResponse,
  GetDefaultTagsForm,
  GetSystemTagForm,
  GetTagForm,
  RegisterTagForm,
  UpdateTagForm,
} from '../../services/http/tag.api';

/**
 * システムのタグを取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetSystemTagsApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<BaseTag[] | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: GetSystemTagForm): Promise<BaseTag[]> => {
    setLoading(true);
    try {
      const doc = await TagApi.getSystemTags(form);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, data, error, request,
  };
}

/**
 * タグを取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetTagsApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function request(form: GetTagForm): Promise<BaseTag[]> {
    setLoading(true);
    try {
      return await TagApi.getTags(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }
  return {
    loading, error, request,
  };
}

/**
 * デフォルトタグを取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetDefaultTagsApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: GetDefaultTagsForm): Promise<BaseTag[]> => {
    setLoading(true);
    try {
      return await TagApi.getDefaultTags(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

/**
 * 関連タグを取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetRelatedTagsApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (documentClassificationId: number, documentTypeId: number): Promise<BaseTag[]> => {
    setLoading(true);
    try {
      const dcTags = await TagApi.getSystemTags({ documentClassificationId });
      const dtTags = await TagApi.getDefaultTags({ documentTypeId });
      const result: BaseTag[] = [];

      dcTags.forEach((tag) => {
        result.push({
          tagSettingId: tag.tagSettingId as number,
          tagLabel: tag.tagLabel,
          format: tag.format,
          require: tag.require,
          duplicate: tag.duplicate,
          reminderNoticePeriod: 10,
          reminderUseFlg: 1,
        });
      });
      dtTags.forEach((tag) => {
        result.push(tag);
      });

      return result;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

/**
 * 供給者権限を取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `allTags`:全てのタグ。
 *   - `reload`:リロード関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 *   - `loadingAllTags`: 全てのタグリクエスト中かどうか
 */
export function useGetAllTagsApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [allTags, setAllTags] = useState<BaseTag[]>([]);
  const [loadingAllTags, setLoadingAllTags] = useState(true);

  const request = useCallback(async (): Promise<DocumentTag[]> => {
    setLoading(true);
    setLoadingAllTags(true);
    try {
      const result = await TagApi.getOrganizationTags();
      return result.sort((a, b) => a.tagSettingId - b.tagSettingId);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
      setLoadingAllTags(false);
    }
  }, []);

  const reload = useCallback(async () => {
    const t = await request();
    setAllTags(t);
  }, [request]);

  return {
    loading, error, allTags, reload, loadingAllTags,
  };
}

/**
 * 組織タグを取得APIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request`:リクエストを行う関数。
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetOrganizationTagsApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (): Promise<DocumentTag[]> => {
    setLoading(true);
    try {
      return await TagApi.getOrganizationTags();
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

export function useGetAllExistingTagsApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (): Promise<GetAllTagsResponse> => {
    setLoading(true);
    try {
      return await TagApi.getAllTags();
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

export function useRegisterTagApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: RegisterTagForm): Promise<void> => {
    setLoading(true);
    try {
      return await TagApi.registerTag(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

export function useDeleteTagApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (tagSettingId: number): Promise<void> => {
    setLoading(true);
    try {
      return await TagApi.deleteTag(tagSettingId);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}

export function useUpdateTagApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const request = useCallback(async (form: UpdateTagForm): Promise<void> => {
    setLoading(true);
    try {
      return await TagApi.updateTag(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    loading, error, request,
  };
}
