import {
  createContext, useState, PropsWithChildren, useContext, useMemo,
} from 'react';

export interface CartStore {
  total: null | number
  setTotal: React.Dispatch<React.SetStateAction<CartStore['total']>>
}

export type CartProviderProps = PropsWithChildren;

const CartContext = createContext<CartStore>({
  total: null,
  setTotal: () => {},
});

function CartProvider({ children }: CartProviderProps) {
  const [total, setTotal] = useState<CartStore['total']>(null);
  const value = useMemo(() => ({
    total,
    setTotal,
  }), [total, setTotal]);

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
}

export const useCartContext = () => useContext(CartContext);

export default CartProvider;
