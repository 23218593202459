import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';

const renderCartIcon = (params: ICellRendererParams) => {
  if (params.node.data.cartAddedFlg) {
    return (
      <div
        style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginLeft: '6px',
        }}
      >
        <img
          src="/images/paper-plane-regular.svg"
          alt="Cart added"
          style={{ height: '14px', margin: '0 auto' }}
        />
      </div>
    );
  }
  return null;
};
// a  a
export default renderCartIcon;
