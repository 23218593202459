import React, {
  CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Autocomplete } from '@mui/material';
import { DropDownMenuItem } from '../../../DropdownMenu';
import styles from './autoCompleteCellRender.module.css';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
export interface AutoCompleteCellRenderProps<T=any> extends ICellEditorParams {
  options: DropDownMenuItem<T>[]
  onSelect: (row: T, selected: T) => void,
  menuStyle?: CSSProperties
  dropDownItemStyle?: CSSProperties
  onTextInput?: (row: T, value: string) => void
  onBlur?: () => void
  onBlurSetTextAsSelected?: boolean
}

export default forwardRef((props: AutoCompleteCellRenderProps, ref) => {
  const [text, setText] = useState<any>(props.value);
  const [inputValue, setInputValue] = useState<string>('');
  const refInput = useRef<HTMLInputElement>(null);

  const onTextInput = (value: string) => {
    setText(value);
    if (props.onTextInput) {
      props.onTextInput(props.data, value);
    }
  };

  const onSelect = useCallback((value: any) => {
    props.onSelect(props.data, value);
    if (props.onBlurSetTextAsSelected) return;
    props.stopEditing();
  }, [props]);

  const handleBlur = useCallback(() => {
    if (props.onBlur) {
      props.onBlur();
    }
    if (props.onBlurSetTextAsSelected && text !== props.value) {
      setText(props.value);
      setInputValue(props.value);
    }
  }, [props, text]);

  useImperativeHandle(ref, () => ({
    getValue() {
      return text;
    },
  }));

  useEffect(() => {
    refInput.current?.focus();
    refInput.current?.click();
  }, []);

  return (
    <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
      <Autocomplete
        freeSolo
        isOptionEqualToValue={(option, value) => option === value}
        value={text}
        onChange={(event: any, newValue: any) => {
          if (newValue != null) {
            setText(newValue.label);
            onSelect(newValue);
          }
        }}
        onBlurCapture={handleBlur}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          onTextInput(newInputValue);
        }}
        id="controllable-states-demo"
        options={props.options}
        sx={{ width: '100%' }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              type="text"
              {...params.inputProps}
              style={{ width: '100%' }}
              className={styles.AutoCompleteInputStyle}
            />
          </div>
        )}
      />
    </div>
  );
});
