import React, {
  useCallback, useEffect, useRef, useState,
  DragEvent,
} from 'react';
import { ApiError } from '../../../services/http';
import DropdownMenu from '../../DropdownMenu';
import { useAlertModal, useMessageModal } from '../../../hooks/modal.hook';
import { Folder, FolderDeleteForm, FolderUpdateForm } from '../../../services/http/folder.api';
import {
  useFolderDelete, useFolderUpdate, useFolderPutUpPin, useFolderRemovePin,
} from '../../../hooks/api/folder.hook';
import styles from './folderItem.module.css';
import Input from '../../Input/input';
import ControlCode from '../../../utils/controlCode';
import { useMoveDocumentFolder } from '../../../hooks/api/document.hook';
import { ManagementType, useAuthContext } from '../../../store/auth.store';
// import { useSearchBarContext } from '../../store/searchBar.store';
import AlertModal, { AlertModalProps, alertModalInitialState } from '../../AlertModal';
import { Document } from '../../../services/http/documents.api';
import CheckModalBody from '../../CheckModalBody/checkModalBody';
import LoadingOverlay from '../../LoadingOverlay';
import FolderOptionValue from '../../../utils/folder.utils';

/**
 * フォルダオプション
 */
interface DocumentWithFolderId {
  document: Document,
  folderId: number
}

/**
 * フォルダアイテムのプロップスインターフェース
 */
export interface FolderItemProps {
  /** 使用されるフォルダ */
  folder: Folder,
  /** フォルダ一覧 */
  folders: Folder[],
  /** 選択されているかどうか */
  selected?: boolean,
  /** 選択時に実行されるハンドラー */
  onSelect: (folder: Folder) => void,
  /** 編集時に実行されるハンドラー */
  onEdit?: (form: FolderUpdateForm) => void,
  /** 削除時に実行されるハンドラー */
  onDelete?: (form: FolderDeleteForm) => void,
  /** ドキュメントフォルダ変更時に実行されるハンドラー */
  onDocumentFolderChanged?: () => void,
  /** ドロップ完了時に実行されるハンドラー */
  onDropCompleted?: () => void;
  /** メインフレームの左部セクションの幅 */
  mainFrameLeftSectionWidth?: number,
  onPinnedStatusChanged?: () => void;
  isPinned?: boolean;
}

/**
 * フォルダアイテムのコンポーネント
 */
export function FolderItem({
  folder, folders, selected = false, onSelect, onEdit, onDelete, onDocumentFolderChanged, mainFrameLeftSectionWidth, onPinnedStatusChanged, onDropCompleted, isPinned,
}: FolderItemProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const threeDotsRef = useRef<HTMLDivElement>(null);

  const [form, setForm] = useState<FolderUpdateForm | null>(null);
  const [modal, setModal] = useState<AlertModalProps>(alertModalInitialState);
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const searchBar = useSearchBarContext();
  const openMessageModal = useMessageModal();
  const openAlertModal = useAlertModal();
  const folderUpdate = useFolderUpdate();
  const folderDelete = useFolderDelete();
  const moveDocumentFolder = useMoveDocumentFolder();
  const { user, hasFolderPermission, hasDocumentPermission } = useAuthContext();
  const folderPutUpPin = useFolderPutUpPin();
  const folderRemovePin = useFolderRemovePin();

  const editable = onEdit != null && onDelete != null && user?.managementType === ManagementType.Admin;

  const baseWidth = mainFrameLeftSectionWidth || 0;
  const adjustment = isPinned ? 145 : 120;
  const formWidth = form ? '100px' : '105px';
  const maxWidth = baseWidth - adjustment > 1
    ? `${baseWidth - adjustment}px`
    : formWidth;

  const folderOptions = [
    { text: isPinned ? 'ピン留め解除' : 'ピン留め', value: FolderOptionValue.TogglePin },
    ...(editable ? [
      { text: 'フォルダ名を変更', value: FolderOptionValue.Edit },
      { text: '削除', value: FolderOptionValue.Remove },
    ] : []),
  ];

  const onUpdateForm = useCallback(async () => {
    if (!form) return;
    if (onEdit == null) {
      return;
    }
    if (form.name === folder.name) {
      setForm(null);
      return;
    }
    try {
      if (inputRef.current) inputRef.current.disabled = true;

      if (folders.some((item) => item.name === form.name)) throw new Error('このフォルダ名は既に登録済みです。');
      await folderUpdate.exec(form);
      onEdit(form);
      setForm(null);
    } catch (e: unknown) {
      await openMessageModal((e as Error)?.message);
    }
    if (inputRef.current) {
      inputRef.current.disabled = false;
      inputRef.current.focus();
    }
  }, [form, folders, onEdit]);

  const onDeleteForm = useCallback(async (): Promise<void> => {
    if (onDelete == null) {
      return;
    }
    const deleteForm = { id: folder.id };
    await openAlertModal(`${folder.name} を削除します。よろしいですか？`);
    folderDelete.exec(deleteForm)
      .then(() => onDelete(deleteForm))
      .catch((e) => {
        openMessageModal((e as ApiError)?.message);
      });
  }, [folder, onDelete]);

  const onTogglePin = useCallback(async () => {
    if (isPinned) {
      await folderRemovePin.exec(folder.id);
    } else {
      await folderPutUpPin.exec(folder.id);
    }
    onPinnedStatusChanged?.();
  }, [isPinned, folder.id, folderRemovePin, folderPutUpPin, onPinnedStatusChanged]);

  const generateFolderActions = useCallback(() => ({
    [FolderOptionValue.Edit]: () => setForm({ id: folder.id, name: folder.name }),
    [FolderOptionValue.Remove]: onDeleteForm,
    [FolderOptionValue.TogglePin]: onTogglePin,
  }), [onDeleteForm, onTogglePin, folder.id, folder.name]);

  const onMenuSelect = useCallback(async (value: FolderOptionValue) => {
    const action = generateFolderActions()[value];
    if (action) {
      await action();
    }
  }, [generateFolderActions]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const onDragOver = useCallback((event: DragEvent) => {
    const { types } = event.dataTransfer;
    const dragSupported = types.length;
    if (dragSupported) {
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.dropEffect = 'move';
    }
    event.preventDefault();
  }, []);

  const isDocumentDroppable = useCallback((document: Document) => {
    const documentHasPermission = hasDocumentPermission(document, ControlCode.Write) && hasDocumentPermission(document, ControlCode.Delete);
    return documentHasPermission;
  }, [hasDocumentPermission]);

  const moveDocumentsToFolder = useCallback(async (documentsToMove: DocumentWithFolderId[]) => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-restricted-syntax
      for await (const i of documentsToMove) {
        await moveDocumentFolder.exec({ documentId: i.document.id, folderId: folder.id });
      }
    } catch (e) {
      await openMessageModal((e as Error)?.message);
    } finally {
      if (documentsToMove.length > 0 && onDocumentFolderChanged) onDocumentFolderChanged();
      setLoading(false);
    }
  }, [folder.id, moveDocumentFolder, onDocumentFolderChanged, openMessageModal]);

  const checkDocumentsPermissionAndDisplayMessage = useCallback(async (documentsWithFolderId: DocumentWithFolderId[]) => {
    const failedDocuments: DocumentWithFolderId[] = [];
    const documentsAllowedToMove: DocumentWithFolderId[] = [];
    documentsWithFolderId.forEach((documentWithFolder) => {
      if (!isDocumentDroppable(documentWithFolder.document)) {
        failedDocuments.push(documentWithFolder);
      } else {
        documentsAllowedToMove.push(documentWithFolder);
      }
    });

    if (failedDocuments.length === 0) {
      await moveDocumentsToFolder(documentsWithFolderId);
      if (onDropCompleted) {
        onDropCompleted();
      }
      return;
    }

    const modalTitle = '移動可能な文書のみが対象となりますがよろしいですか？\n\n以下の文書は移動元のフォルダに削除権限が無いため移動できません。';
    const modalItems = failedDocuments.map((failed) => failed.document.name);

    setModal({
      modalStyle: { width: '650px' },
      open: true,
      text: <CheckModalBody title={modalTitle} items={modalItems} />,
      onConfirm: async () => {
        if (documentsWithFolderId.length === 0) {
          setModal({ ...modal, open: false });
          return;
        }
        setModal({ ...modal, open: false });
        await moveDocumentsToFolder(documentsAllowedToMove);
      },
      onCancel: async () => setModal({ ...modal, open: false }),
    });
  }, [isDocumentDroppable, modal, moveDocumentsToFolder]);

  const onDrop = useCallback(async (event: DragEvent) => {
    event.preventDefault();
    const { userAgent } = window.navigator;
    const isIE = userAgent.indexOf('Trident/') >= 0;
    const dataJson = event.dataTransfer.getData(isIE ? 'text' : 'text/plain');
    const data = JSON.parse(dataJson);
    if (data.folderId === folder.id) return;
    if (!hasFolderPermission(folder, ControlCode.Write)) {
      openMessageModal('移動先のフォルダに更新権限が付与されていません。');
      return;
    }
    const documentsMovedToDifferentFolder: DocumentWithFolderId[] = data.documentsTransfer.filter((doc: DocumentWithFolderId) => doc.folderId !== folder.id);
    const documentCount = documentsMovedToDifferentFolder.length;
    const destinationFolderName = folder.name;

    setModal({
      open: true,
      text: `選択された${documentCount}件の文書を移動先フォルダ：${destinationFolderName}へ移動します。\nよろしいですか？`,
      onConfirm: async () => {
        setModal({ ...modal, open: false });
        await checkDocumentsPermissionAndDisplayMessage(documentsMovedToDifferentFolder);
      },
      onCancel: () => {
        setModal({ ...modal, open: false });
      },
    });
  }, [checkDocumentsPermissionAndDisplayMessage, folder, hasFolderPermission, modal, openMessageModal]);

  const onClickOutside = useCallback(() => {
    if (!form) return;
    if (form.name === '') {
      setForm(null);
    } else {
      onUpdateForm();
    }
  }, [form]);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleOutsideClick = () => {
    // eslint-disable-next-line no-restricted-globals
    if (threeDotsRef.current && threeDotsRef.current.contains(event?.target as Node)) {
      return;
    }
    setIsDropdownOpen(false);
  };

  return (
    <>
      <div
        title={folder.name}
        className={selected ? styles.selectedFolderFrame : styles.folderFrame}
        aria-hidden="true"
        onClick={(event) => {
          if (threeDotsRef.current?.contains(event.target as Node)) {
            return;
          }
          onSelect(folder);
        }}
        onDragOver={(event) => {
          onDragOver(event);
        }}
        onDrop={(event) => {
          onDrop(event);
        }}
      >
        <div className={styles.folderOptionsLeftFrame}>
          <div className={styles.folderOptionsLeftFrameIcon}>
            <img src="/images/Row-Folder.svg" alt="" />
          </div>
          <div className={selected ? styles.selectedFolderOptionsLeftFrameText : styles.folderOptionsLeftFrameText}>
            <div className={folder.authorityList.some((item) => item.controlCode === ControlCode.Read) ? styles.folderOptionsLeftFrameTextEnabled : styles.folderOptionsLeftFrameTextGrayedOut}>{ControlCode.describe(ControlCode.Read)}</div>
            <div className={folder.authorityList.some((item) => item.controlCode === ControlCode.Write) ? styles.folderOptionsLeftFrameTextEnabled : styles.folderOptionsLeftFrameTextGrayedOut}>{ControlCode.describe(ControlCode.Write)}</div>
            <div className={folder.authorityList.some((item) => item.controlCode === ControlCode.Delete) ? styles.folderOptionsLeftFrameTextEnabled : styles.folderOptionsLeftFrameTextGrayedOut}>{ControlCode.describe(ControlCode.Delete)}</div>
          </div>
        </div>
        <div className={styles.folderOptionsRightFrame}>
          {
            !form
              ? (
                <>
                  <div
                    className={selected ? styles.selectedFolderOptionsRightFrameText : styles.folderOptionsRightFrameText}
                    style={{ maxWidth }}
                  >
                    {folder.name}
                  </div>
                  {isPinned && (
                  <img src="/images/thumbtack-solid-diagonal.svg" alt="Pinned" className={styles.pinnedIcon} />
                  )}
                </>
              )
              : (
                <Input
                  style={{ maxWidth }}
                  className={styles.folderOptionsRightFrameText}
                  value={form.name}
                  inputRef={inputRef}
                  maxLength={100}
                  onChange={(e) => setForm({ ...form, name: e })}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') onUpdateForm();
                    return true;
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Escape') setForm(null);
                    return true;
                  }}
                  onBlur={onClickOutside}
                />
              )
          }
          <DropdownMenu
            options={folderOptions}
            left
            onSelect={onMenuSelect}
            onOutsideClick={handleOutsideClick}
            open={isDropdownOpen}
            allowToggleOnClick
          >
            <div
              ref={threeDotsRef}
              className={styles.extraIcon}
              style={{ padding: '10px 10px 10px 2px' }}
              onClick={handleDropdownToggle}
              role="button"
              tabIndex={0}
            >
              <img src="/images/Three-Dots.svg" alt="" />
            </div>
          </DropdownMenu>
        </div>

        {loading && <LoadingOverlay />}
      </div>
      <AlertModal
        open={modal.open}
        text={modal.text}
        onCancel={modal.onCancel}
        onConfirm={modal.onConfirm}
        modalStyle={modal.modalStyle}
      />
    </>
  );
}

export default FolderItem;
