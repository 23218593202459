import { useState } from 'react';
import mainStyles from '../main.module.css';
import styles from './disposalModal.module.css';
import ScreenModal from '../../components/ScreenModal/screenModal';
import Button from '../../components/Button/button';
import SelectInput from '../../components/SelectInput';
import Input from '../../components/Input/input';
import { useMessageModal } from '../../hooks/modal.hook';
import Validator from '../../utils/validators';
import { useDisposeReserveApi } from '../../hooks/api/reserve.hook';
import { ApiError } from '../../services/http';
import AlertModal, { alertModalInitialState, CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { ReservationCartRegisterForm } from '../../services/http/reservationCart.api';

export interface ReceiveModalProps {
  documentList: ReservationCartRegisterForm['documentList'],
  onCancel: () => void,
  onSuccess: () => void,
  onError: () => void,
  onDocumentRequestDone: () => void,
}

function Component(props: ReceiveModalProps) {
  const {
    documentList, onCancel, onSuccess, onError, onDocumentRequestDone,
  } = props;
  const openMessageModal = useMessageModal();
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);
  const [disposalReportIssueDiv, setDisposalReportIssueDiv] = useState<number | null>(null);
  const [trdContact, setTrdContact] = useState('');
  const [emergencyContacts, setEmergencyContacts] = useState<string | null>(null);
  const [userMemo, setUserMemo] = useState('');

  // Apis
  const disposeReserveApi = useDisposeReserveApi();
  const { request: createLog } = useCreateLogApi();

  const handleSendForm = async () => {
    setAlertModal({ ...alertModal, open: false });
    try {
      await disposeReserveApi.request({
        documentList,
        disposalReportIssueDiv: disposalReportIssueDiv as number,
        trdContact,
        emergencyContacts: emergencyContacts as string,
        userMemo,
      });
      onDocumentRequestDone();
      setCloseAlertModal({
        ...closeAlertModal,
        open: true,
        text: '依頼が完了しました。',
        onCancel: onSuccess,
      });
    } catch (e) {
      setCloseAlertModal({
        ...closeAlertModal,
        text: (e as ApiError).message,
        open: true,
        onCancel() {
          setCloseAlertModal({ ...closeAlertModal, open: false });
          onError();
        },
      });
    }
  };

  const handleSubmit = () => {
    if (disposalReportIssueDiv == null || emergencyContacts == null) {
      openMessageModal('全ての必須項目を入力してください');
      return;
    }
    if (trdContact.length > Validator.contactMessageMax) {
      openMessageModal(`連絡事項を${Validator.contactMessageMax}文字以内で入力してください`);
      return;
    }

    setAlertModal({
      open: true,
      text: '依頼を送信します。よろしいですか？',
      onCancel: () => {
        setAlertModal({ ...alertModal, open: false });
      },
      onConfirm: handleSendForm,
    });
  };

  useEffectOnce(() => {
    createLog(LogFormName.DisposalRequestScreen, LogControlName.Show);
  });

  return (
    <ScreenModal
      customStyles={{
        minWidth: '800px',
        maxHeight: '85%',
        border: 'var(--defaultBorder)',
        display: 'flex',
        flexDirection: 'column',
        color: 'initial',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.title}>
          廃棄依頼
        </div>
      </div>
      <div className={styles['modal-body']}>
        <div className={styles['form-row']}>
          <div className={styles['form-left']}>
            <label className={styles.label}>対象件数</label>
          </div>
          <span className={styles.label}>
            {documentList.length.toLocaleString()}
            件
          </span>
        </div>
        <div className={styles['form-row']}>
          <div className={styles['form-left']}>
            <label className={styles.label}>廃棄処理報告書発行</label>
            <span>*</span>
          </div>
          <SelectInput
            value={disposalReportIssueDiv}
            options={[{ value: 0, text: '要' }, { value: 1, text: '不要' }]}
            onChange={setDisposalReportIssueDiv}
            style={{ width: '280px' }}
            menuStyle={{ width: '100%' }}
          />
          <div className={[mainStyles['text-danger'], mainStyles['ml-20px']].join(' ')}>
            <span className={mainStyles['mr-1']}>※</span>
            廃棄処理報告書は廃棄依頼者のメールアドレスへ送付いたします。
          </div>
        </div>
        <div className={styles['form-row']}>
          <div className={styles['form-left']}>
            <label className={styles.label}>寺田倉庫への連絡事項</label>
          </div>
          <textarea
            className={mainStyles['text-area']}
            value={trdContact}
            placeholder="寺田倉庫へ連絡事項がある場合は入力してください。（100文字以内）"
            onChange={(e) => setTrdContact(e.target.value)}
          />
        </div>
        <div className={styles['form-row']}>
          <div className={styles['form-left']}>
            <label className={styles.label}>緊急連絡先</label>
            <span>*</span>
          </div>
          <Input
            className={styles.input}
            value={emergencyContacts ?? ''}
            onChange={setEmergencyContacts}
          />
          <div className={[mainStyles['text-danger'], mainStyles['ml-20px']].join(' ')}>
            <span className={mainStyles['mr-1']}>※</span>
            日中に連絡可能な連絡先をご記入ください
          </div>
        </div>
        <div className={styles['form-row']}>
          <div className={styles['form-left']}>
            <label className={styles.label}>お客様メモ</label>
          </div>
          <textarea
            className={mainStyles['text-area']}
            value={userMemo}
            placeholder="その他、依頼に対して備考がある場合は入力してください。"
            onChange={(e) => setUserMemo(e.target.value)}
          />
        </div>
      </div>
      <div className={styles['modal-footer']}>
        <Button size="smaller" color="lighterGray" onClick={onCancel}>キャンセル</Button>
        <Button size="smaller" onClick={handleSubmit} loading={disposeReserveApi.loading}>依頼</Button>
      </div>
      <AlertModal open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} />
      <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} closeButtonText="OK" />
    </ScreenModal>
  );
}

export default Component;
