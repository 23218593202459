import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useMessageModal } from '../../../hooks/modal.hook';
import { Folder, FolderCreateForm } from '../../../services/http/folder.api';
import { useFolderCreate } from '../../../hooks/api/folder.hook';
import styles from './folderItem.module.css';
import Input from '../../Input/input';
import ControlCode from '../../../utils/controlCode';

/**
 * フォルダフォームのプロップスインターフェース
 */
export interface Props {
  /** フォルダ作成キャンセル時に実行されるハンドラー */
  onCancel: () => void;
  /** フォルダ一覧 */
  folders: Folder[];
  /** フォルダ作成時に実行されるハンドラー */
  onCreate: (form: FolderCreateForm) => void;
}

/**
 * フォルダフォームのコンポーネント
 */
export function FolderForm({ folders, onCreate, onCancel }: Props) {
  const [form, setForm] = useState<FolderCreateForm>({ name: '' });
  const inputRef = useRef<HTMLInputElement>(null);

  const openMessageModal = useMessageModal();
  const folderCreate = useFolderCreate();

  const onSendFolderForm = useCallback(async (createForm: FolderCreateForm) => {
    if (createForm.name === '') return;
    try {
      if (inputRef.current && inputRef.current.disabled) return;
      if (inputRef.current) inputRef.current.disabled = true;

      if (folders.some((folder) => folder.name === createForm.name)) throw new Error('このフォルダ名は既に登録済みです。');

      await folderCreate.exec(createForm);
    } catch (e: unknown) {
      await openMessageModal((e as Error)?.message);
    }
    if (inputRef.current) {
      inputRef.current.disabled = false;
      inputRef.current.focus();
    }
    onCreate(form);
  }, [form, folders, inputRef.current]);

  const onClickOutside = useCallback(() => {
    if (form.name === '') {
      onCancel();
    } else {
      onSendFolderForm(form);
    }
  }, [form]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className={styles.folderFrame} aria-hidden="true">
      <div className={styles.folderOptionsLeftFrame}>
        <div className={styles.folderOptionsLeftFrameIcon}>
          <img src="/images/Row-Folder.svg" alt="" />
        </div>
        <div className={styles.folderOptionsLeftFrameText}>
          <div className={styles.folderOptionsLeftFrameTextGrayedOut}>{ControlCode.describe(ControlCode.Read)}</div>
          <div className={styles.folderOptionsLeftFrameTextGrayedOut}>{ControlCode.describe(ControlCode.Write)}</div>
          <div className={styles.folderOptionsLeftFrameTextGrayedOut}>{ControlCode.describe(ControlCode.Delete)}</div>
        </div>
      </div>
      <div className={styles.folderOptionsRightFrame}>
        <Input
          className={styles.folderOptionsRightFrameText}
          value={form.name}
          maxLength={100}
          inputRef={inputRef}
          onChange={(e) => setForm({ name: e })}
          onBlur={onClickOutside}
          onKeyPress={(event) => {
            if (event.key === 'Enter') return onSendFolderForm(form);
            return true;
          }}
          onKeyDown={(event) => {
            if (event.key === 'Escape') return onCancel();
            return true;
          }}
        />
      </div>
    </div>
  );
}

export default FolderForm;
