import { useCallback, useState } from 'react';
import reminderApi, { GetReminderResponse, UpdateReminderRequest } from '../../services/http/reminder.api';
import { ApiError } from '../../services/http';

export function useReminderApi() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetReminderResponse | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const getReminderRequest = useCallback(async (tagSettingId: number): Promise<GetReminderResponse> => {
    setLoading(true);
    try {
      const doc = await reminderApi.getReminder(tagSettingId);
      setData(doc);
      return doc;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  const updateReminderRequest = useCallback(async (form: UpdateReminderRequest): Promise<void> => {
    setLoading(true);
    try {
      await reminderApi.updateReminder(form);
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading, data, error, getReminderRequest, updateReminderRequest,
  };
}

export default useReminderApi;
