import FolderApi, { Folder, FolderSearchForm } from '../../services/http/folder.api';
import { useHttp } from './api.hook';

/**
 * フォルダ検索フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderSearch() {
  const {
    request: originalRequest,
    exec: originalExec,
    data,
    error,
    loading,
  } = useHttp(FolderApi.search);

  const request = async (form: FolderSearchForm): Promise<Folder[]> => {
    const result = await originalRequest(form);
    return result.folderList;
  };

  const exec = async (form: FolderSearchForm): Promise<Folder[]> => {
    const result = await originalExec(form);

    return result.folderList;
  };

  return {
    loading,
    error,
    data: data?.folderList,
    exec,
    request,
  };
}

/**
 * フォルダ検索フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFullFolderSearch() {
  return useHttp(FolderApi.search);
}

/**
 * フォルダ作成フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderCreate() {
  return useHttp(FolderApi.create);
}

/**
 * フォルダ更新フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderUpdate() {
  return useHttp(FolderApi.update);
}

/**
 * フォルダ削除フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderDelete() {
  return useHttp(FolderApi.delete);
}

/**
 * フォルダ権限フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderRoles() {
  return useHttp(FolderApi.roles);
}

/**
 * フォルダピン設定フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderPutUpPin() {
  return useHttp(FolderApi.putUpPin);
}

/**
 * フォルダピン設定フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderRemovePin() {
  return useHttp(FolderApi.removePin);
}

/**
 * フォルダ並び順設定フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *  - `loading`: リクエスト中かどうか
 */
export function useFolderSetSort() {
  return useHttp(FolderApi.setSort);
}
