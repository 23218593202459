import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { DateTime } from 'luxon';
import { Divider } from '@mui/material';
import BaseModal from '../BaseModal';
import Button from '../Button/button';
import styles from './searchInputTopFilterModal.module.css';
import Input from '../Input/input';
import { DocumentSearchDetailForm } from '../../services/http/documents.api';
import SelectInput from '../SelectInput';
import DatePickerJp from '../DatePickerJp';
import AutoCompleteInput from '../AutoCompleteInput';
import { useGetDocumentClassificationAndTypeApi } from '../../hooks/api/documentManagement.hook';
import { DocumentClassificationAndType } from '../../services/http/documentManagement.api';
import { DropDownMenuItem } from '../DropdownMenu';
import { useFolderSearch } from '../../hooks/api/folder.hook';
import { Folder } from '../../services/http/folder.api';
import { useGetAllTagsApi } from '../../hooks/api/tag.hook';
import mainStyles from '../../pages/main.module.css';
import RadioGroupInput from '../RadioGroupInput';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import TagFormat from '../../utils/tagFormat';
import Formatter from '../../utils/formatters';
import { WarehouseStatusCode } from '../../utils/warehouse.utils';
import { BaseTag } from '../../services/http/tag.api';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

function renderTagInputs(
  tagFilter: TagFilter,
  filter: TagFilter['tags'][number],
  onSetTagValue1Change: (tagFilter: TagFilter, filter: TagFilter['tags'][number], value: string) => void,
  onSetTagValue2Change: (tagFilter: TagFilter, filter: TagFilter['tags'][number], value: string) => void,
  onSetTagDate1: (tagFilter: TagFilter, filter: TagFilter['tags'][number], value: DateTime | null) => void,
  onSetTagDate2: (tagFilter: TagFilter, filter: TagFilter['tags'][number], value: DateTime | null) => void,
) {
  if (!tagFilter.defaultTag.tagLabel) return <div />;
  if (filter.condition === 'ブランク') return <div />;

  if (TagFormat.STRING === tagFilter.defaultTag.format) {
    return (
      <Input
        className={[mainStyles['mr-2']].join(' ')}
        value={filter.value1}
        type="text"
        onChange={(value) => (onSetTagValue1Change(tagFilter, filter, value))}
      />
    );
  } if (TagFormat.DATE === tagFilter.defaultTag.format) {
    return (
      <>
        <div className={styles.dateInput}>
          <DatePickerJp
            value={filter.date1 || null}
            onChange={(value) => (onSetTagDate1(tagFilter, filter, value))}
          />
        </div>
        <div className={styles.dateDivisor}>~</div>
        <div className={[styles.dateInput, mainStyles['mr-2']].join(' ')}>
          <DatePickerJp
            value={filter.date2 || null}
            onChange={(value) => (onSetTagDate2(tagFilter, filter, value))}
          />
        </div>
      </>
    );
  } if (TagFormat.NUMBER === tagFilter.defaultTag.format) {
    let inputField;
    if (filter.condition === '次の範囲') {
      inputField = (
        <>
          <Input
            value={filter.value1}
            type="number"
            onChange={(value) => (onSetTagValue1Change(tagFilter, filter, value))}
          />
          <div className={styles.dateDivisor}>~</div>
          <Input
            className={[mainStyles['mr-2']].join(' ')}
            value={filter.value2}
            type="number"
            onChange={(value) => (onSetTagValue2Change(tagFilter, filter, value))}
          />
        </>
      );
    } else if (filter.condition === '次の値と一致') {
      inputField = (
        <Input
          className={[mainStyles['mr-2']].join(' ')}
          value={filter.value1}
          type="number"
          onChange={(value) => (onSetTagValue1Change(tagFilter, filter, value))}
        />
      );
    }
    return inputField;
  }
  return <div />;
}

const wareHouseRecords: Record<WarehouseStatusCode, string> = {
  [WarehouseStatusCode.REQUESTING_TO_RECEIVE]: '入庫依頼中',
  [WarehouseStatusCode.IN_STORAGE]: '保管中',
  [WarehouseStatusCode.REQUESTING_TO_DISPATCH]: '出庫依頼中',
  [WarehouseStatusCode.OUT_OF_WAREHOUSE]: '出庫済み',
  [WarehouseStatusCode.REQUESTING_DISPOSAL]: '廃棄依頼中',
  [WarehouseStatusCode.DISPOSED]: '廃棄済み',
  [WarehouseStatusCode.NOT_RECEIVED]: '未入庫',
  [WarehouseStatusCode.REQUESTING_SCAN]: 'スキャン依頼中',
};

const TagFormatTextMap = TagFormat.getTextMap();

export const TagFormatOptions: Record<TagFormat, string[]> = {
  [TagFormat.NUMBER]: ['次の範囲', '次の値と一致', 'ブランク'],
  [TagFormat.STRING]: ['次の文字を含む', '次の文字と一致', 'ブランク'],
  [TagFormat.DATE]: ['次の期間', 'ブランク'],
};

interface LastSearch {
  form: DocumentSearchDetailForm,
  classification: { classification: DocumentClassificationAndType, type: DocumentClassificationAndType['types'][number] } | undefined,
  tagFilters: TagFilter[],
  enableFolderSearch: boolean,
  selectedClassification: DocumentClassificationAndType | null,

}

export interface TagFilter {
  defaultTag: BaseTag;
  tags: {
    condition: string;
    value1?: string;
    value2?: string;
    date1?: DateTime | null;
    date2?: DateTime | null;
  }[];
}

interface Props {
  open?: boolean;
  onClose?: () => void;
  onAdvancedSearch: (advancedSearch: DocumentSearchDetailForm) => void;
}

export default function SearchInputTopFilterModal({ open = false, onClose = () => ({}), onAdvancedSearch }: Props) {
  const { request: createLog } = useCreateLogApi();

  const [folders, setFolders] = useState<Folder[]>([]);
  const [documentClassificationAndType, setDocumentClassificationAndType] = useState<DocumentClassificationAndType[]>([]);

  const [enableFolderSearch, setEnableFolderSearch] = useState<boolean>(false);
  const [classification, setClassification] = useState<{ classification: DocumentClassificationAndType, type: DocumentClassificationAndType['types'][number] } | undefined>(undefined);
  const [tagFilters, setTagFilters] = useState<TagFilter[]>([]);
  const [form, setForm] = useState<DocumentSearchDetailForm>({
    fileName: '',
    memo: '',
    name: '',
  });
  const [selectedClassification, setSelectedClassification] = useState<DocumentClassificationAndType | null>(null);
  const [lastSearch, setLastSearch] = useState<LastSearch | null>(null);

  const getDocumentClassificationAndTypeApi = useGetDocumentClassificationAndTypeApi();
  const folderSearch = useFolderSearch();
  const openMessageModal = useMessageModal();

  const { allTags, reload: getAllTags } = useGetAllTagsApi();

  useEffect(() => {
    getAllTags();
  }, [getAllTags]);

  const onAddTagFilterClick = useCallback(() => {
    setTagFilters([
      ...tagFilters,
      {
        defaultTag: {
          duplicate: false,
          format: TagFormat.NUMBER,
          tagLabel: '',
          require: false,
          tagSettingId: 0,
          reminderNoticePeriod: 0,
          reminderUseFlg: 0,
        },
        tags: [{ condition: '', value1: '', value2: '' }],
      }]);
  }, [tagFilters]);

  const onAddOrConditionClick = useCallback((tagFilter: TagFilter) => {
    setTagFilters(tagFilters.map((tf) => {
      if (tagFilter === tf) {
        if (tagFilter.defaultTag.format === TagFormat.NUMBER) {
          return { ...tf, tags: [...tf.tags, { condition: '次の範囲', value1: '', value2: '' }] };
        } if (tagFilter.defaultTag.format === TagFormat.STRING) {
          return { ...tf, tags: [...tf.tags, { condition: '次の文字を含む', value1: '', value2: '' }] };
        } if (tagFilter.defaultTag.format === TagFormat.DATE) {
          return { ...tf, tags: [...tf.tags, { condition: '次の期間', value1: '', value2: '' }] };
        }
      }
      return tf;
    }));
  }, [tagFilters]);

  const onSetTagLabelClick = useCallback((tagFilter: TagFilter, defaultTag: BaseTag) => {
    setTagFilters(tagFilters.map((tf) => {
      if (tagFilter === tf) {
        if (defaultTag.format === TagFormat.NUMBER) {
          return { defaultTag, tags: tf.tags.map(() => ({ condition: '次の範囲', value1: '', value2: '' })) };
        } if (defaultTag.format === TagFormat.STRING) {
          return { defaultTag, tags: tf.tags.map(() => ({ condition: '次の文字を含む', value1: '', value2: '' })) };
        } if (defaultTag.format === TagFormat.DATE) {
          return { defaultTag, tags: tf.tags.map(() => ({ condition: '次の期間', value1: '', value2: '' })) };
        }
      }
      return tf;
    }));
  }, [tagFilters]);

  const onSetTagValue1Change = useCallback((tagFilter: TagFilter, tag: TagFilter['tags'][number], value: string) => {
    setTagFilters(tagFilters.map((tf) => {
      if (tagFilter === tf) {
        return { ...tf, tags: tf.tags.map((t) => (t === tag ? { ...t, value1: value } : t)) };
      }
      return tf;
    }));
  }, [tagFilters]);

  const onSetTagValue2Change = useCallback((tagFilter: TagFilter, tag: TagFilter['tags'][number], value: string) => {
    setTagFilters(tagFilters.map((tf) => {
      if (tagFilter === tf) {
        return { ...tf, tags: tf.tags.map((t) => (t === tag ? { ...t, value2: value } : t)) };
      }
      return tf;
    }));
  }, [tagFilters]);

  const onSetTagDate1 = useCallback((tagFilter: TagFilter, tag: TagFilter['tags'][number], date1Value: DateTime | null) => {
    setTagFilters(tagFilters.map((tf) => {
      if (tagFilter === tf) {
        return { ...tf, tags: tf.tags.map((t) => (t === tag ? { ...t, date1: date1Value } : t)) };
      }
      return tf;
    }));
  }, [tagFilters]);

  const onSetTagDate2 = useCallback((tagFilter: TagFilter, tag: TagFilter['tags'][number], date2Value: DateTime | null) => {
    setTagFilters(tagFilters.map((tf) => {
      if (tagFilter === tf) {
        return { ...tf, tags: tf.tags.map((t) => (t === tag ? { ...t, date2: date2Value } : t)) };
      }
      return tf;
    }));
  }, [tagFilters]);

  const onSetTagConditionChange = useCallback((tagFilter: TagFilter, tag: TagFilter['tags'][number], condition: string) => {
    setTagFilters(tagFilters.map((tf) => {
      if (tagFilter === tf) {
        return { ...tf, tags: tf.tags.map((t) => (t === tag ? { ...t, condition } : t)) };
      }
      return tf;
    }));
  }, [tagFilters]);

  const onRemoveSecondORConditionClick = useCallback((tagFilter: TagFilter, tag: TagFilter['tags'][number]) => {
    setTagFilters(
      tagFilters.map(
        (tf) => (tagFilter === tf ? { ...tf, tags: tf.tags.filter((t) => t !== tag) } : tf),
      ).filter((tf) => tf.tags.length),
    );
  }, [tagFilters]);

  const onResetClick = useCallback(() => {
    setForm({
      fileName: '',
      memo: '',
      name: '',
      searchRange: folders[0]?.id,
    });
    setTagFilters([]);
    setSelectedClassification(null);
    setEnableFolderSearch(false);
    setClassification(undefined);
    setLastSearch(null);
  }, [folders]);

  const onSendAdvancedFilter = useCallback(() => {
    // searchBar.setIsSearching(true);
    const sendForm: DocumentSearchDetailForm = { page: 1, ...form };
    if (!enableFolderSearch) delete sendForm.searchRange;
    if (tagFilters.length) {
      sendForm.tagList = [];
      tagFilters.forEach((tagFilter) => {
        tagFilter.tags.forEach((tag) => {
          sendForm.tagList?.push({
            label: tagFilter.defaultTag.tagLabel,
            condition: tag.condition,
            value1: tag.date1 ? Formatter.fromDateTimeToString(tag.date1, Formatter.defaultFullDateFormat) : tag.value1,
            value2: tag.date2 ? Formatter.fromDateTimeToString(tag.date2, Formatter.defaultFullDateFormat) : tag.value2,
          });
        });
      });
    }
    onAdvancedSearch(sendForm);
    // searchBar.setAdvancedSearch(sendForm);
    // searchBar.setText('');

    setLastSearch({
      form,
      tagFilters,
      selectedClassification,
      classification,
      enableFolderSearch,
    });
    onClose();
  }, [createLog, form, enableFolderSearch, tagFilters, selectedClassification, classification, onClose]);

  const fetchFolders = useCallback(async () => {
    if (lastSearch) return;
    try {
      const res = await folderSearch.request({});
      setFolders(res);
      if (res.length) setForm({ ...form, searchRange: res[0].id });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [folderSearch, form, openMessageModal]);

  const onDocumentTypeSelected = useCallback(async (selected: { classification: DocumentClassificationAndType, type: DocumentClassificationAndType['types'][number] }) => {
    setTagFilters([]);
    let f = { ...form, documentTypeId: selected.type.documentTypeId || undefined };
    setClassification(selected);
    if (selected.classification.name.startsWith('契約書')) {
      f = { ...f, electronicContract: 0, existPaper: 0 };
    }
    setForm(f);
    setSelectedClassification(selected.classification);
  }, [form]);

  const documentClassificationAndTypeListOptions = useMemo(() => {
    const result: DropDownMenuItem<{ classification: DocumentClassificationAndType, type: DocumentClassificationAndType['types'][number] }>[] = [];

    documentClassificationAndType.forEach((documentClassification) => {
      documentClassification.types.forEach((documentType) => {
        result.push({
          text: `${documentClassification.name}: ${documentType.documentTypeName}`,
          value: { classification: documentClassification, type: documentType },
        });
      });
    });

    return result;
  }, [documentClassificationAndType]);

  useEffect(() => {
    if (open) {
      try {
        getDocumentClassificationAndTypeApi.request().then(setDocumentClassificationAndType);
        fetchFolders();
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    }
  }, [open]);

  // useEffect(() => {
  //   if (!searchBar.advancedSearch) {
  //     onResetClick();
  //   }
  // }, [onResetClick, searchBar.advancedSearch]);

  useEffectOnce(() => {
    createLog(LogFormName.SearchDetail, LogControlName.Show);
  });

  return (
    <BaseModal open={open} className={styles.modal}>
      <div className={styles.modalBody}>
        <div className={styles.modalBodyRow}>
          <label>文書ID</label>
          <Input type="number" className={[styles.ccControlNumberInput].join('')} value={form.ccControlNumber} placeholder="文書IDに一致する数値を入力" onChange={(ccControlNumber) => setForm({ ...form, ccControlNumber })} />
        </div>
        <Divider className={styles.divider} />
        <div className={styles.modalBodyRow}>
          <label>文書名</label>
          <Input className={styles.bigInput} value={form.name} placeholder="文書名と部分的に一致するキーワードを入力" onChange={(name) => setForm({ ...form, name })} />
        </div>
        <div className={styles.modalBodyRow}>
          <label>PDF</label>
          <Input className={styles.bigInput} value={form.fileName} placeholder="PDFファイル名と部分的に一致するキーワードを入力" onChange={(fileName) => setForm({ ...form, fileName })} />
        </div>
        <div className={styles.modalBodyRow}>
          <label><div style={{ marginLeft: -50 }}>バーコード印字欄</div></label>
          <Input className={styles.bigInput} value={form.barcodePrinting} placeholder="バーコード印字欄と部分的に一致するキーワードを入力" onChange={(barcodePrinting) => setForm({ ...form, barcodePrinting })} />
        </div>
        <div className={styles.modalBodyRow}>
          <label><div style={{ marginLeft: -50 }}>保管品バーコード</div></label>
          <Input className={styles.bigInput} value={form.itemCode} placeholder="保管品バーコードと部分的に一致するキーワードを入力" onChange={(itemCode) => setForm({ ...form, itemCode })} />
        </div>
        <div className={styles.modalBodyRow}>
          <label>文書種類</label>
          <AutoCompleteInput
            className={styles.select}
            value={classification ? `${classification.classification.name}：${classification.type.documentTypeName}` : ''}
            options={documentClassificationAndTypeListOptions}
            onSelect={onDocumentTypeSelected}
            deselectOption
            onDeselect={() => {
              setClassification(undefined);
              setForm({
                ...form, documentTypeId: undefined, electronicContract: undefined, existPaper: undefined,
              });
              setSelectedClassification(null);
            }}
          />
        </div>
        { selectedClassification?.name.startsWith('契約書') ? (
          <>
            <div className={styles.modalBodyRow}>
              <label>契約種類</label>
              <div className={styles.radioRow}>
                <RadioGroupInput
                  value={form.electronicContract ? '1' : '0'}
                  onChange={(value) => {
                    setForm({ ...form, electronicContract: value === '1' ? 1 : 0, existPaper: value === '1' ? 0 : form.existPaper });
                  }}
                >
                  <RadioGroupInput.RadioInput value="1">電子契約</RadioGroupInput.RadioInput>
                  <RadioGroupInput.RadioInput value="0">書面契約</RadioGroupInput.RadioInput>
                </RadioGroupInput>
              </div>
            </div>
            <div className={styles.modalBodyRow}>
              <label>書面有無</label>
              <div className={styles.radioRow}>
                { form.electronicContract ? (
                  <RadioGroupInput value="0" onChange={() => setForm({ ...form, existPaper: 0 })}>
                    <RadioGroupInput.RadioInput disabled value="1">有り</RadioGroupInput.RadioInput>
                    <RadioGroupInput.RadioInput disabled value="0" style={{ marginLeft: '64px' }}>無し</RadioGroupInput.RadioInput>
                  </RadioGroupInput>
                ) : (
                  <RadioGroupInput value={(form.existPaper === 1) ? '1' : '0'} onChange={(value) => setForm({ ...form, existPaper: value === '1' ? 1 : 0 })}>
                    <RadioGroupInput.RadioInput value="1">有り</RadioGroupInput.RadioInput>
                    <RadioGroupInput.RadioInput value="0" style={{ marginLeft: '64px' }}>無し</RadioGroupInput.RadioInput>
                  </RadioGroupInput>
                )}
              </div>
            </div>
          </>
        ) : null}
        <div className={styles.modalBodyRow}>
          <label>登録日時</label>
          <DatePickerJp value={form.registDate_From || null} onChange={(date) => setForm({ ...form, registDate_From: date || undefined })} />
          <div className={styles.dateDivisor}>~</div>
          <DatePickerJp value={form.registDate_To || null} onChange={(date) => setForm({ ...form, registDate_To: date || undefined })} />
        </div>
        <div className={styles.modalBodyRow}>
          <label>更新日時</label>
          <DatePickerJp value={form.updateDate_From || null} onChange={(date) => setForm({ ...form, updateDate_From: date || undefined })} />
          <div className={styles.dateDivisor}>~</div>
          <DatePickerJp value={form.updateDate_To || null} onChange={(date) => setForm({ ...form, updateDate_To: date || undefined })} />
        </div>
        <div className={styles.modalBodyRow}>
          <label>メモ</label>
          <Input
            className={styles.bigInput}
            value={form.memo}
            placeholder="メモに含まれている語句を入力"
            onChange={(memo) => setForm({ ...form, memo })}
          />
        </div>
        <div className={styles.modalBodyRow}>
          <label>ステータス</label>
          <SelectInput<WarehouseStatusCode | undefined>
            className={styles.select}
            value={form.warehouseStatus}
            options={[{ text: '', value: undefined }, ...Object.keys(wareHouseRecords).map((key: string) => ({ text: wareHouseRecords[key as WarehouseStatusCode], value: key as WarehouseStatusCode }))]}
            onChange={(warehouseStatus) => setForm({ ...form, warehouseStatus })}
          />
        </div>
        <div className={styles.modalBodyRow}>
          <label>タグ</label>
          <Button className={styles.button} disabled={!allTags.length} color="lightGray" size="smaller" onClick={onAddTagFilterClick}>タグを追加</Button>
        </div>
        {
          tagFilters.map((tagFilter) => (
            <div className={styles.tagContainer}>
              <label className={styles.tagFirstColumn}>AND</label>
              <div className={[styles.tagSecondColumn, mainStyles['mb-2']].join(' ')}>
                <AutoCompleteInput<BaseTag>
                  className={mainStyles['mb-2']}
                  options={allTags.map((tag) => ({ text: tag.tagLabel, value: tag }))}
                  onSelect={(defaultTag) => onSetTagLabelClick(tagFilter, defaultTag)}
                />
                <Button color="lightGray" size="smaller" fullWidth onClick={() => onAddOrConditionClick(tagFilter)}>条件を追加</Button>
              </div>

              <div className={styles.tagThirdColumn}>
                { tagFilter.defaultTag.tagLabel
                  && tagFilter.tags.map((filter, j) => (
                    <div style={{ width: '100%', display: 'flex', marginBottom: 8 }}>
                      <div className={styles.tagText}>{j === 0 ? `（${TagFormatTextMap[tagFilter.defaultTag.format]}）が` : 'OR'}</div>
                      <SelectInput
                        className={[mainStyles['mr-2'], styles.select].join(' ')}
                        value={filter.condition}
                        options={TagFormatOptions[tagFilter.defaultTag.format].map((item) => ({ text: item, value: item }))}
                        onChange={(value) => onSetTagConditionChange(tagFilter, filter, value)}
                      />
                      <div style={{
                        flex: 1, display: 'flex', justifyContent: 'center', justifyItems: 'center',
                      }}
                      >
                        {renderTagInputs(tagFilter, filter, onSetTagValue1Change, onSetTagValue2Change, onSetTagDate1, onSetTagDate2)}

                      </div>
                      <Button className={styles.buttonTrash} size="smaller" color="lightPrimary" onClick={() => onRemoveSecondORConditionClick(tagFilter, filter)}>
                        <img src="../images/Icon-feather-trash-2.svg" alt="" />
                      </Button>
                    </div>
                  ))}
              </div>
            </div>
          ))
        }
        <div className={styles.modalBodyRow}>
          <label>検索範囲</label>
          <RadioGroupInput className={mainStyles['mr-5']} value={enableFolderSearch ? '1' : ''} onChange={(value) => setEnableFolderSearch(Boolean(value))}>
            <RadioGroupInput.RadioInput value="">全体から検索する</RadioGroupInput.RadioInput>
            <RadioGroupInput.RadioInput value="1" disabled={!folders.length}>フォルダを指定して検索する</RadioGroupInput.RadioInput>
          </RadioGroupInput>

          <SelectInput
            className={enableFolderSearch ? styles.select : styles.selectDisabled}
            value={form.searchRange}
            disabled={!enableFolderSearch}
            options={folders.map((item) => ({ text: item.name, value: item.id }))}
            onChange={(folderId) => setForm({ ...form, searchRange: folderId })}
          />
        </div>
      </div>
      <footer>
        <div>
          <Button className={mainStyles['mr-10']} color="lightGray" size="smaller" onClick={onClose}>閉じる</Button>
          <Button color="lightGray" size="smaller" onClick={onResetClick}>リセット</Button>
        </div>
        <Button color="primary" size="smaller" onClick={onSendAdvancedFilter}>検索</Button>
      </footer>
    </BaseModal>
  );
}
