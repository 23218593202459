import React, {
  useCallback, useMemo, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { RowDragEvent } from 'ag-grid-community';
import { RowNode } from 'ag-grid-enterprise';
import styles from './sortFoldersModal.module.css';
import ScreenModal from '../ScreenModal/screenModal';
import Button from '../Button/button';
import BaseText from '../BaseText/BaseText';
import { Folder } from '../../services/http/folder.api';
import { useFolderSetSort } from '../../hooks/api/folder.hook';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';

export interface Props {
  handleCancel: () => void;
  handleSuccess: () => void;
  folders: Folder[];
}

function Component({ handleCancel, handleSuccess, folders }: Props) {
  const openMessageModal = useMessageModal();
  const { request: setSortRequest } = useFolderSetSort();

  const [rowData, setRowData] = useState(folders);

  const columns = useMemo(() => [
    {
      field: 'name',
      headerName: 'フォルダ名',
      rowDrag: true,
      flex: 1,
      suppressMenu: true,
    },
  ], []);

  const onRowDragEnd = useCallback((event: RowDragEvent) => {
    const reorderedData: Folder[] = [];
    event.api.forEachNode((node, index) => {
      reorderedData.push({ ...node.data, order: index + 1 });
    });
    setRowData(reorderedData);
  }, []);

  const onSave = useCallback(async () => {
    const folderList = rowData.map((folder) => ({ id: folder.id }));

    try {
      await setSortRequest(folderList);
      handleSuccess();
    } catch (e) {
      await openMessageModal((e as ApiError)?.message);
    }
  }, [handleSuccess, openMessageModal, rowData, setSortRequest]);

  return (
    <ScreenModal
      customStyles={{
        height: '90vh',
        width: '100%',
        maxWidth: '800px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: 'var(--defaultBorder)',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.headerTitle}>
          並び順変更
        </div>
      </div>
      <div className={styles['modal-body']}>

        <div className={styles.title}>
          <BaseText>表示したい順にドラッグして並び替えてください</BaseText>
        </div>

        <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columns}
            rowDragManaged
            animateRows
            onRowDragEnd={onRowDragEnd}
          />
        </div>
      </div>
      <div className={styles['modal-footer']}>
        <div className={styles['mainframe-body-footer-buttons']}>
          <Button size="smaller" color="lighterGray" onClick={handleCancel}>キャンセル</Button>
          <Button size="smaller" onClick={onSave}>保存</Button>
        </div>
      </div>
    </ScreenModal>
  );
}

export default Component;
