import { AgGridReact } from 'ag-grid-react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { v1 as uuid } from 'uuid';
import {
  ColDef, GetRowIdParams, ICellRendererParams, NewValueParams,
} from 'ag-grid-community';
import MainFrame from '../../components/MainFrame/mainFrame';
import usePageTitle from '../../hooks/title.hook';
import styles from './categoryListPage.module.css';
import mainStyles from '../main.module.css';
import Button from '../../components/Button/button';
import {
  useDeleteDocumentClassificationApi,
  useGetDocumentClassificationApi,
  useRegisterDocumentClassificationApi,
} from '../../hooks/api/documentManagement.hook';
import { DocumentClassification } from '../../services/http/documentManagement.api';
import AlertModal from '../../components/AlertModal';
import { useGetSystemTagsApi, useGetTagsApi } from '../../hooks/api/tag.hook';
import { BaseTag } from '../../services/http/tag.api';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import LoadingOverlay from '../../components/LoadingOverlay';
import BaseTable from '../../components/BaseTable';
import CheckBoxCellRender from '../../components/BaseTable/partials/CheckBoxCell/CheckBoxCellRender';
import AutoCompleteCellRender, { AutoCompleteCellRenderProps } from '../../components/BaseTable/partials/AutoCompleteCellRender/AutoCompleteCellRender';
import OptionsCellRender from '../../components/BaseTable/partials/OptionsCellRender/OptionsCellRender';
import SelectCellEditor from '../../components/BaseTable/partials/SelectCell/SelectCellEditor';
import Breadcrumb from '../../components/Breadcrumb';
import routes from '../../utils/routes';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import TagFormat from '../../utils/tagFormat';
import AGUtils from '../../utils/ag-grid.utils';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';

/**
 * カテゴリタグのインターフェース
 */
export interface CategoryTag extends BaseTag {
  key: string,
  copiedBy: BaseTag | null;
  modified: boolean
}

/**
 * カテゴリドキュメントのインターフェイス
 */
export interface CategoryDocument extends DocumentClassification {
  systemTags: CategoryTag[]
}

/**
 * モーダルの初期状態
 */
export const modalInitialState = {
  open: false, text: '', onConfirm: () => {}, onCancel: () => {},
};

/**
 * ドキュメントの初期状態
 */
export const documentInitialState: CategoryDocument = {
  id: null, name: '', displayOrder: 0, systemTags: [],
};
/**
 * タグの初期状態
 */
export const tagInitialState: CategoryTag = {
  tagSettingId: 0, tagLabel: '', require: false, duplicate: false, format: TagFormat.NUMBER, copiedBy: null, modified: false, key: '', reminderNoticePeriod: 10, reminderUseFlg: 1,
};

/**
 * 文書分類をカテゴリ文書に変換する機能
 *
 * @param docs -  文書分類情報
 * @returns - 変換後のカテゴリ文書
 */
export function classificationDocToCategory(docs: DocumentClassification[]): CategoryDocument[] {
  return docs
    .map((doc) => ({
      ...doc,
      systemTags: doc.systemTags.map((tag) => ({ ...tagInitialState, ...tag })),
    }));
}

/**
 * タグ形式のテキストマップ
 */
export const TagFormatTextMap = TagFormat.getTextMap();

/**
 * カテゴリ一覧ページ
 */
function Component() {
  usePageTitle('文書分類一覧');
  const openMessageModal = useMessageModal();

  const gridRef = useRef<AgGridReact<CategoryTag>>(null);

  const [documentSearch, setDocumentSearch] = useState('');
  const [documents, setDocuments] = useState<CategoryDocument[]>([]);
  const [categoryDocument, setCategoryDocument] = useState<CategoryDocument>(documentInitialState);
  const [modal, setModal] = useState(modalInitialState);
  const [loading, setLoading] = useState(false);
  const [hintTags, setHintTags] = useState<BaseTag[]>([]);

  // API's
  const { request: getDocumentClassificationRequest } = useGetDocumentClassificationApi();
  const { request: registerDocumentClassificationRequest } = useRegisterDocumentClassificationApi();
  const { request: deleteDocumentClassificationRequest } = useDeleteDocumentClassificationApi();
  const getSystemTagApi = useGetSystemTagsApi();
  const getTagsApi = useGetTagsApi();
  const { request: createLog } = useCreateLogApi();

  const addNewMode = useMemo(() => categoryDocument.id === null || categoryDocument.id === -1, [categoryDocument]);

  const documentSearchOptions = useMemo(
    () => (documents || []).map((item) => ({ text: item.name, value: item })),
    [documents],
  );

  const onDocumentClick = useCallback((doc: CategoryDocument) => {
    const docClone = { ...doc };
    docClone.systemTags = [...doc.systemTags];
    setCategoryDocument(docClone);
    if (docClone.id !== null) {
      try {
        getSystemTagApi
          .request({ documentClassificationId: docClone.id })
          .then((tags) => setCategoryDocument({
            ...docClone,
            systemTags: tags.map((item) => ({
              ...tagInitialState,
              ...item,
              key: uuid(),
            })),
          }));
        getTagsApi.request({ documentClassificationId: docClone.id }).then((tags) => setHintTags(tags));
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    }
  }, [getSystemTagApi, getTagsApi, openMessageModal]);

  const resetScreen = useCallback(async () => {
    try {
      const docs = await getDocumentClassificationRequest();
      const categoryDocs = classificationDocToCategory(docs);
      setDocuments(categoryDocs);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [getDocumentClassificationRequest, openMessageModal]);

  const deleteTag = useCallback((tag: CategoryTag) => {
    setCategoryDocument({ ...categoryDocument, systemTags: categoryDocument.systemTags.filter((item) => item.key !== tag.key) });
  }, [categoryDocument]);

  const onNewDocumentClick = () => {
    setCategoryDocument({ ...documentInitialState, id: -1 });
  };

  const onSaveDocumentClick = async () => {
    if (categoryDocument.name.trim().length < 1) {
      openMessageModal('文書分類名を入力してください。');
      return;
    }
    if (categoryDocument.systemTags.some((tag) => tag.tagLabel.trim().length < 1)) {
      openMessageModal('タグ名を入力してください。');
      return;
    }
    setLoading(true);
    try {
      createLog(LogFormName.CategoryList, LogControlName.Edit);

      await registerDocumentClassificationRequest(categoryDocument);
      await resetScreen();
      if (categoryDocument.id === null) {
        openMessageModal('文書分類を登録しました。');
      } else {
        openMessageModal('文書分類を更新しました。');
      }
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
    setCategoryDocument(documentInitialState);
    setLoading(false);
  };

  const onAddNewTagClick = () => {
    setCategoryDocument({
      ...categoryDocument,
      systemTags: [...categoryDocument.systemTags, { ...tagInitialState, key: uuid() }],
    });
  };
  const onDuplicateDocumentClick = () => {
    setCategoryDocument({
      ...categoryDocument,
      id: -1,
    });
  };

  const onDeleteDocumentClick = () => {
    setModal({
      open: true,
      text: '削除します。よろしいですか？',
      onConfirm: async () => {
        if (categoryDocument.id === null) return;
        setModal({ ...modal, open: false });
        setLoading(true);
        try {
          await deleteDocumentClassificationRequest({ id: categoryDocument.id })
            .then(() => {
              resetScreen();
            })
            .finally(() => setLoading(false));
        } catch (e) {
          openMessageModal((e as ApiError)?.message);
        }
        setCategoryDocument(documentInitialState);
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  };

  const openCancelModal = useCallback(() => {
    setModal({
      open: true,
      text: '入力内容は破棄されます。遷移してよろしいですか？',
      onConfirm: () => {
        setModal({ ...modal, open: false });
        onDocumentClick(documentInitialState);
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  }, [modal, onDocumentClick]);

  const onAutoCompleteTagInput = useCallback((row: CategoryTag, value: string) => {
    setCategoryDocument((c) => ({
      ...c,
      systemTags: c.systemTags.map((item) => (item.key === row.key ? {
        ...item,
        tagLabel: value,
        modified: true,
      } : item)),
    }));
  }, []);

  const onAutoCompleteTagSelect = useCallback((row: CategoryTag, selectedTag: BaseTag) => {
    setCategoryDocument({
      ...categoryDocument,
      systemTags: categoryDocument.systemTags.map((item) => (item.key === row.key ? {
        ...item,
        tagLabel: selectedTag.tagLabel,
        format: selectedTag.format,
        duplicate: selectedTag.duplicate,
        require: selectedTag.require,
        copiedBy: selectedTag,
        modified: true,
      } : item)),
    });
  }, [categoryDocument]);

  const onTagItemChanged = useCallback((row: CategoryTag, key: keyof BaseTag, value: BaseTag[keyof BaseTag]) => {
    setCategoryDocument({
      ...categoryDocument,
      systemTags: categoryDocument.systemTags.map((tag) => (row.key === tag.key ? { ...row, [key]: value, modified: true } : tag)),
    });
  }, [categoryDocument]);

  const columns = useMemo((): ColDef[] => [
    AGUtils.colAutoIncrement('tagSettingId'),
    {
      headerName: 'タグ名',
      resizable: true,
      field: 'tagLabel',
      width: 311,
      editable: true,
      singleClickEdit: true,
      cellEditor: AutoCompleteCellRender,
      cellEditorParams: {
        options: hintTags.map((item) => ({ label: item.tagLabel, value: JSON.stringify(item) })),
        onSelect(row: CategoryTag, value: any) {
          const valueObj = JSON.parse(value.value);
          onAutoCompleteTagSelect(row, valueObj);
        },
        onTextInput: (row: CategoryTag, value: string) => {
          onAutoCompleteTagInput(row, value);
        },
      },
      onCellValueChanged(params) {
        onTagItemChanged(params.data, 'tagLabel', params.data.tagLabel);
      },
    }, {
      headerName: '書式',
      resizable: true,
      editable: true,
      singleClickEdit: true,
      field: 'format',
      cellStyle: { overflow: 'initial' },
      cellEditor: SelectCellEditor,
      cellEditorParams: ({ data }: AutoCompleteCellRenderProps<CategoryTag>) => ({
        disabled: !!data.copiedBy,
        options: [{ text: '文字列', value: TagFormat.STRING }, { text: '数値', value: TagFormat.NUMBER }, { text: '日付', value: TagFormat.DATE }],
        onChange: (e: TagFormat) => onTagItemChanged(data, 'format', e),
      }),
      cellRenderer: (params: ICellRendererParams<CategoryTag>) => TagFormatTextMap[params.data?.format || TagFormat.NUMBER],
      width: 120,
    }, {
      headerName: '入力必須',
      resizable: true,
      field: 'require',
      width: 120,
      cellRenderer: CheckBoxCellRender,
      cellRendererParams: {
        align: 'center',
      },
      onCellValueChanged: (cell: NewValueParams<CategoryTag>) => onTagItemChanged(cell.data, 'require', cell.data.require),
    }, {
      headerName: '重複を許可しない',
      resizable: true,
      field: 'duplicate',
      width: 160,
      /* eslint-disable @typescript-eslint/no-explicit-any */
      cellRenderer: CheckBoxCellRender,
      cellRendererParams: {
        align: 'center',
      },
      onCellValueChanged: (cell: NewValueParams<CategoryTag>) => {
        onTagItemChanged(cell.data, 'duplicate', cell.data.duplicate);
      },
    }, {
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      field: 'options',
      cellStyle: {
        overflow: 'initial',
        display: 'flex',
        justifyContent: 'end',
        justifyItems: 'end',
      },
      cellRenderer: OptionsCellRender,
      cellRendererParams: {
        options: [{ text: '削除', value: 'DELETE' }],
        onSelect(tag: CategoryTag, selectedValue: string) {
          if (selectedValue === 'DELETE') {
            if (tag.modified) {
              setModal({
                open: true,
                text: '対象のタグは文書へ設定されている可能性があります。\n'
                    + '削除してよろしいですか？',
                onConfirm() {
                  setModal({ ...modal, open: false });
                  deleteTag(tag);
                },
                onCancel: () => setModal({ ...modal, open: false }),
              });
            } else {
              deleteTag(tag);
            }
          }
        },
      },
      minWidth: 50,
      flex: 1,
    },
  ], [hintTags, onAutoCompleteTagSelect, onAutoCompleteTagInput, onTagItemChanged, modal, deleteTag]);

  useEffect(() => {
    resetScreen();
  }, [resetScreen]);

  useEffectOnce(() => {
    createLog(LogFormName.CategoryList, LogControlName.Show);
  });

  return (
    <MainFrame borderBox>
      <div className={styles.mainFrame}>
        <Breadcrumb crumbs={[
          { label: 'マスタ管理', route: routes.masterDataSettingAdmin },
          { label: document.title }]}
        />
        <div className={styles.mainFrameBody}>
          <div className={styles.mainFrameLeftSection}>
            <div className={styles.mainFrameLeftSectionTop}>
              <div className={styles.title}>文書分類</div>
              <div className={styles.mt10}>
                <AutoCompleteInput
                  value={documentSearch}
                  placeholder="検索"
                  renderPreInput="&#xF002;"
                  options={documentSearchOptions}
                  onTextInput={setDocumentSearch}
                  onSelect={onDocumentClick}
                />
              </div>
              <div style={{ marginTop: '11px' }}>
                <Button
                  fullWidth
                  size="smaller"
                  onClick={onNewDocumentClick}
                >
                  文書分類を追加
                </Button>
              </div>
            </div>

            <table className={styles.tableHeader}>
              <thead>
                <tr>
                  <th style={{ width: '38px' }}>No</th>
                  <th style={{
                    width: '166px',
                    paddingLeft: '20px',
                    textAlign: 'left',
                  }}
                  >
                    文書分類
                  </th>
                </tr>
              </thead>
            </table>

            <div className={styles.mainFrameLeftSectionList}>
              <table>
                <tbody>
                  {documents.map((item) => (
                    <tr
                      key={item.displayOrder}
                      style={{ cursor: 'pointer', background: categoryDocument.id === item.id ? 'var(--lighterGray)' : '' }}
                      onClick={() => onDocumentClick(item)}
                    >
                      <td>{item.id}</td>
                      <td className={`${styles.borderRight} ${styles.tableName}`} title={item.name}><span>{item.name}</span></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.mainFrameRightSection}>
            { categoryDocument.id !== null ? (
              <>
                <div className={styles.mainFrameRightSectionTop}>
                  <div className={styles.title}>文書分類名</div>
                  <div className={styles.mt10}>
                    <input
                      className={styles.topInput}
                      value={categoryDocument?.name}
                      type="text"
                      placeholder="文書分類名"
                      onChange={(e) => setCategoryDocument({ ...categoryDocument, name: e.target.value.trim() })}
                    />
                  </div>
                  <div className={styles.mainFrameRightSectionTopButtons}>
                    <Button className={`${styles.topButton} ${styles.mr10}`} size="smaller" onClick={onAddNewTagClick}>
                      行を追加
                    </Button>
                    <Button className={`${styles.topButton} ${styles.mr10}`} size="smaller" disabled={addNewMode} onClick={onDuplicateDocumentClick}>
                      複製
                    </Button>
                    <Button className={`${styles.topButton} ${styles.mr10}`} size="smaller" disabled={addNewMode} onClick={onDeleteDocumentClick}>
                      削除
                    </Button>
                  </div>
                </div>
                <div className={styles.mainFrameRightSectionList}>
                  <BaseTable<CategoryTag>
                    tableRef={gridRef}
                    formName="categoryTable"
                    rowData={categoryDocument.systemTags}
                    columnDefs={columns}
                    getRowId={(a: GetRowIdParams<CategoryTag>) => a.data?.key || a.level.toString()}
                    suppressRowTransform
                    stopEditingWhenCellsLoseFocus
                    createLogOnDownloadTableData={LogFormName.CategoryList}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={styles.mainFrameRightSectionTopEmpty} />
                <div className={styles.mainFrameRightSectionListEmpty} />
              </>
            )}
            <div className={styles.mainFrameRightSectionFooterButtons}>
              { categoryDocument.id !== null && (
                <>
                  <Button size="smaller" color="lightGray" className={mainStyles['ml-10']} onClick={openCancelModal}>キャンセル</Button>
                  <Button className={[mainStyles.floatRight, mainStyles['ml-10']].join(' ')} size="smaller" onClick={onSaveDocumentClick}>保存</Button>
                </>
              )}
            </div>
          </div>
          <AlertModal open={modal.open} text={modal.text} onConfirm={modal.onConfirm} onCancel={modal.onCancel} />
          {loading && <LoadingOverlay />}
        </div>
      </div>
    </MainFrame>
  );
}

export default Component;
