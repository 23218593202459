import * as uuid from 'uuid';
import mainStyles from '../../pages/main.module.css';

interface Props {
  title: string;
  items: string[];
  footer?: string;
  cartMessage?: string | null;
}

export default function CheckModalBody({
  title,
  items,
  footer = '',
  cartMessage,
}: Props) {
  return (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {title}
      <br />
      <br />
      {items.slice(0, 5).map((item) => (
        <div key={uuid.v1()} className={mainStyles['text-overflow-ellipsis']}>
          -
          {' '}
          {item}
        </div>
      ))}
      {items.length > 5 && (
      <div>
        他
          {items.length - 5}
        件
      </div>
      )}
      {cartMessage && (
        <>
          <br />
          <div>
            {cartMessage}
          </div>
        </>
      )}
      {footer !== '' ? (
        <>
          <br />
          <div>
            {footer}
          </div>
        </>
      ) : null}
    </div>
  );
}
