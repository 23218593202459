import { useCallback, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styles from './cartDocDetailModalFrame.module.css';
import { Document, DocumentDetail } from '../../services/http/documents.api';
import CartDocDetailInfo from './cartDocDetailInfo';
import { useDocumentGetDetail } from '../../hooks/api/document.hook';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import BaseTab, { BaseTabItem } from '../BaseTab/BaseTab';
import CartDocDetailHistory from './cartDocDetailHistory';
import { CartDocument } from '../../services/http/reservationCart.api';

/**
 * 文書詳細モーダルフレームのプロップスインターフェース
 */
export interface Props {
  /** 使用される文書の参照 */
  document: CartDocument | null;
  /** モーダルが閉じられるときに呼び出される関数 */
  onClose: () => void
}

/**
 * 文書詳細モーダルフレーム
 */
function CartDocDetailModalFrame({
  document: documentRef, onClose,
}: Props) {
  // States
  const [document, setDocument] = useState<DocumentDetail | null>(null);
  const [isClosed, setIsClosed] = useState(false);

  // Hooks
  const documentGetDetail = useDocumentGetDetail();
  const openMessageModal = useMessageModal();

  // Methods
  const spring = useSpring({
    left: (documentRef && !isClosed) ? window.innerWidth - 877 : window.innerWidth + 200,
    display: documentRef ? 'block' : 'none',
    maxWidth: '877px',
    zIndex: 100,
  });

  const onCloseModal = useCallback(() => {
    setIsClosed(true);
    onClose();
  }, [onClose]);

  // Effects
  useEffect(() => {
    if (documentRef) {
      try {
        documentGetDetail.request({ id: documentRef.documentId }).then(setDocument);
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    }
  }, [documentRef]);

  useEffect(() => {
    if (!documentRef) setIsClosed(false);
  }, [documentRef, onClose]);

  return (
    <animated.div id="modal" className={styles.bodyBackground} style={spring}>
      <div className={styles.modalContainer}>
        <div className={styles.modal}>
          <BaseTab modalClosed={isClosed}>
            <BaseTabItem title="詳細情報">
              <CartDocDetailInfo
                document={document}
                onClose={onCloseModal}
              />
            </BaseTabItem>
            <BaseTabItem title="操作履歴">
              <CartDocDetailHistory
                document={document}
                onClose={onCloseModal}
              />
            </BaseTabItem>
          </BaseTab>
        </div>
      </div>
    </animated.div>
  );
}

export default CartDocDetailModalFrame;
