import axios from 'axios';

export enum RequestType {
  RECEIVE = 'R010',
  RECEIVE_AGAIN = 'R020',
  DISPATCH = 'S010',
  DISPOSAL = 'S050',
  REMOTE_SCAN = 'DSK',
}

export enum RegisterCartResponseMessageType {
  ALERT = 'alert',
  TOAST = 'toast',
}

export interface CartRelationalDocument {
  documentId: string,
  ccControlNumber: number,
  documentName: string,
  folderName: string,
  barcodePrinting: string,
  itemCode: string,
  duplicateTagList: {
    tagLabel: string,
    value: string,
  }[],
}
export interface CartDocument {
  ccControlNumber: number,
  documentId: number,
  documentName: string,
  documentFileName: string,
  documentFileSize: number,
  documentTypeId: number,
  documentTypeName: string,
  tagList?: { tagLabel: string, tagValue: string, }[]
  relationalDocumentCount: number,
  relationalDocumentList: CartRelationalDocument[],
  requiredTagInputFlg: number,
  warehouseStatus: string,
  warehouseStatusCode: string,
  itemCode: string,
  originalFlg: number,
  electronicFlg: number,
  thumbnail: string,
  barcodePrinting: string,
  folderId: number,
  folderName: string,
  registUser: string,
  registDate: string,
  updateUser: string,
  updateDate: string,
}

export interface ReservationCartSearchForm {
  requestType: RequestType,
}

export interface ReservationCartCount {
  cartCountList: {
    requestType: RequestType,
    count: number
  }[],
  total: number,
}

export interface ReservationCartRegisterForm {
  requestType: RequestType,
  documentList: { documentId: number }[],
}

export interface ReservationCartDeleteForm {
  // requestType: RequestType,
  documentId: number,
}

export interface ReservationCartDeleteAllForm {
  requestType: RequestType,
}

export interface ReservationCartSearchResponse {
  documentList: CartDocument[],
  total: number,
}

interface Document {
  documentId: number;
}

interface RegisterCartResponse {
  message: string;
  messageType: RegisterCartResponseMessageType;
  documentList: Document[];
}

export default {
  async search(form: ReservationCartSearchForm): Promise<ReservationCartSearchResponse> {
    const res = await axios.get('/ReservationCart/search', { params: form });
    const { data } = res;

    return data;
  },
  async getCount(): Promise<ReservationCartCount> {
    const res = await axios.get('/ReservationCart/getCount');
    const { data } = res;
    return data;
  },
  async register(form: ReservationCartRegisterForm): Promise<RegisterCartResponse> {
    const res = await axios.post('/ReservationCart/register', form);
    const { data } = res;
    return data;
  },
  async delete(form: ReservationCartDeleteForm): Promise<void> {
    const res = await axios.post('/ReservationCart/delete', form);
    const { data } = res;
    return data;
  },
  async deleteAll(form: ReservationCartDeleteAllForm): Promise<void> {
    const res = await axios.post('/ReservationCart/deleteAll', form);
    const { data } = res;
    return data;
  },
};
