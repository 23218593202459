import React from 'react';
import ReactDOM from 'react-dom/client';
import { Settings } from 'luxon';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { LicenseManager } from 'ag-grid-enterprise';
import './utils/theme/theme';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import * as pdfjs from 'pdfjs-dist/build/pdf';
import App from './App';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE || '');

Settings.defaultLocale = 'ja';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
