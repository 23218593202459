import axios from 'axios';

export interface UpdateReminderRequest {
  tagSettingId: number
  reminderNoticePeriod: number | null
  reminderUseFlg: number | null
}

export interface UserGroup {
  id: number;
  userGroupName: string;
}

export interface User {
  mailStopFlg: number;
  id: string;
  userName: string;
  email: string;
  userAuthenticationType: number;
  icon: string;
  emailConfirmed: number;
  passwordUpdate: number;
  deleteFlg: number;
  registerDate: string;
  registerUser: string;
  updateDate: string;
  updateUser: string;
  userGroupList: UserGroup[];
}

export interface GetReminderResponse {
  tagSettingId: number;
  reminderNoticePeriod: number | null;
  reminderUseFlg: number | null;
}

export default {
  async updateReminder(form: UpdateReminderRequest): Promise<void> {
    const res = await axios.post('/Tag/updateReminder', form);
    return res.data;
  },

  async getReminder(tagId: number): Promise<GetReminderResponse> {
    const res = await axios.get<GetReminderResponse>('/Tag/getReminder', { params: { tagSettingId: tagId } });
    const { tagSettingId, reminderNoticePeriod, reminderUseFlg } = res.data;

    return {
      tagSettingId,
      reminderNoticePeriod,
      reminderUseFlg,
    };
  },

};
