import { useCallback } from 'react';
import { useHttp } from './api.hook';
import reservationCartApi, { ReservationCartDeleteAllForm, ReservationCartDeleteForm, ReservationCartRegisterForm } from '../../services/http/reservationCart.api';
import { useCartContext } from '../../store/cart.store';

/**
 * 予約カート情報取得フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useReservationCartSearch() {
  return useHttp(reservationCartApi.search);
}

/**
 * 予約カート件数取得フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useReservationCartGetCount() {
  const { setTotal } = useCartContext();
  const request = useCallback(async () => {
    const cart = await reservationCartApi.getCount();
    setTotal(cart.total);
    return cart;
  }, [setTotal]);
  const res = useHttp(request);

  return res;
}

/**
 * 予約カート情報登録フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useReservationCartRegister() {
  const { request: reservationCartGetCount } = useReservationCartGetCount();
  const request = async (form: ReservationCartRegisterForm) => {
    const registerResponse = await reservationCartApi.register(form);
    await reservationCartGetCount({});
    return registerResponse;
  };
  const res = useHttp(request);

  return res;
}

/**
 * 予約カート情報削除フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useReservationCartDelete() {
  const { request: reservationCartGetCount } = useReservationCartGetCount();
  const request = async (form: ReservationCartDeleteForm) => {
    await reservationCartApi.delete(form);
    await reservationCartGetCount({});
  };
  const res = useHttp(request);

  return res;
}

/**
 * 予約カート情報一括削除フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useReservationCartDeleteAll() {
  const { request: reservationCartGetCount } = useReservationCartGetCount();
  const request = async (form: ReservationCartDeleteAllForm) => {
    await reservationCartApi.deleteAll(form);
    await reservationCartGetCount({});
  };
  const res = useHttp(request);

  return res;
}
