import { AgGridReact } from 'ag-grid-react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  ColDef, EditableCallbackParams, GetRowIdParams, ICellRendererParams, NewValueParams, RowDragEvent,
  SuppressKeyboardEventParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { v1 as uuid } from 'uuid';
import MainFrame from '../../components/MainFrame/mainFrame';
import usePageTitle from '../../hooks/title.hook';
import styles from './docTypeListPage.module.css';
import mainStyles from '../main.module.css';
import {
  useDeleteDocumentTypeApi,
  useGetDocumentClassificationAndTypeApi,
  useGetDocumentTypeApi,
  useSetDocumentTypeApi,
} from '../../hooks/api/documentManagement.hook';
import {
  DocumentClassificationAndType,
  DocumentClassificationAndTypeDefault,
  DocumentType,
  DocumentTypeDefault,
} from '../../services/http/documentManagement.api';
import AlertModal, { alertModalInitialState } from '../../components/AlertModal';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import Button from '../../components/Button/button';
import { BaseTag, DocumentTag, TagType } from '../../services/http/tag.api';
import {
  useGetAllExistingTagsApi, useGetDefaultTagsApi, useGetOrganizationTagsApi, useGetSystemTagsApi,
} from '../../hooks/api/tag.hook';
import LoadingOverlay from '../../components/LoadingOverlay';
import { DropDownMenuItem } from '../../components/DropdownMenu';
import Breadcrumb from '../../components/Breadcrumb';
import routes from '../../utils/routes';
import BaseTable from '../../components/BaseTable';
import AutoCompleteCellRender, { AutoCompleteCellRenderProps } from '../../components/BaseTable/partials/AutoCompleteCellRender/AutoCompleteCellRender';
import CheckBoxCellRender from '../../components/BaseTable/partials/CheckBoxCell/CheckBoxCellRender';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import TagFormat from '../../utils/tagFormat';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import IconCellRender from '../../components/BaseTable/partials/IconCellRender/IconCellRender';
import ButtonCellRender from '../../components/BaseTable/partials/ButtonCellRender';
import { LogFormName } from '../../utils/log.utils';

/**
 * 文書種類タグのインターフェイス
 */
export interface DocTypeTag extends BaseTag {
  tagType: TagType,
  modified: boolean,
  key: string,
}

/**
 * 文書種類フォームのインターフェイス
 */
export interface DocumentTypeForm extends Omit<DocumentType, 'defaultTags'> {
  defaultTags: DocTypeTag[],
}

/**
 * デフォルトの文書種類フォーム
 */
export const DocumentTypeFormDefault: DocumentTypeForm = {
  ...DocumentTypeDefault,
  defaultTags: [],
};

/**
 * タグ形式のテキストマップ
 */
export const TagFormatTextMap = TagFormat.getTextMap();

/**
 * 文書種類一覧ページのコンポーネント
 */
function Component() {
  usePageTitle('文書種類一覧');
  const openMessageModal = useMessageModal();

  const gridRef = useRef<AgGridReact<DocTypeTag>>(null);

  const [documents, setDocuments] = useState<DocumentClassificationAndType[]>([]);
  const [documentClassificationAndType, setDocumentClassificationAndType] = useState<DocumentClassificationAndType>(DocumentClassificationAndTypeDefault);
  const [systemTags, setSystemTags] = useState<BaseTag[]>([]);
  const [types, setTypes] = useState<DocumentType[]>([]);
  const [type, setType] = useState<DocumentType>(DocumentTypeDefault);
  const [form, setForm] = useState<DocumentTypeForm>(DocumentTypeFormDefault);
  const [hintTags, setHintTags] = useState<DropDownMenuItem<DocTypeTag>[]>([]);
  const [modal, setModal] = useState(alertModalInitialState);
  const [loading, setLoading] = useState(false);
  const [allTagsState, setAllTagsState] = useState<DocumentTag[]>([]);

  // API's
  const { request: getDocumentClassificationAndTypeRequest } = useGetDocumentClassificationAndTypeApi();
  const { request: getDocumentTypeRequest } = useGetDocumentTypeApi();
  const registerDocumentTypeApi = useSetDocumentTypeApi();
  const deleteDocumentTypeApi = useDeleteDocumentTypeApi();
  const { request: getDefaultTagsRequest } = useGetDefaultTagsApi();
  const { request: getSystemTagsRequest } = useGetSystemTagsApi();
  const { request: getOrganizationTags } = useGetOrganizationTagsApi();
  const { request: getAllTagsRequest } = useGetAllExistingTagsApi();
  const { request: createLog } = useCreateLogApi();

  const saveButtonDisabled = form.defaultTags.some((item) => item.tagSettingId === 0);

  const onClickNewDefaultTag = async () => {
    setType({ ...DocumentTypeDefault, documentTypeName: ' ' });
    if (documentClassificationAndType.id === null) {
      setForm({ ...DocumentTypeFormDefault });
    } else {
      setForm({
        ...DocumentTypeFormDefault,
        defaultTags: systemTags.map((tag) => ({
          ...tag, tagType: TagType.SYSTEM, modified: false, key: uuid(),
        })),
      });
    }
  };
  const onClickDuplicateType = () => {
    setType({ ...type, documentTypeId: null, documentTypeName: ' ' });
    setForm({ ...form, documentTypeId: null, documentTypeName: ' ' });
  };
  const onClickNewTagRow = () => {
    setForm({
      ...form,
      defaultTags: [
        ...form.defaultTags,
        {
          duplicate: false,
          format: TagFormat.STRING,
          tagSettingId: 0,
          require: false,
          tagLabel: '',
          tagType: TagType.CUSTOMER,
          modified: false,
          key: uuid(),
          reminderNoticePeriod: 1,
          reminderUseFlg: 1,
        }],
    });
  };

  const onDeleteDocumentTypeClick = () => {
    setModal({
      open: true,
      text: '削除します。よろしいですか？',
      onConfirm: async () => {
        if (type.documentTypeId === null) return;
        setModal({ ...modal, open: false });
        setLoading(true);
        try {
          await deleteDocumentTypeApi.request({ documentTypeId: type.documentTypeId });
        } catch (e) {
          openMessageModal((e as ApiError)?.message);
        }
        setLoading(false);
        setDocumentClassificationAndType({ ...DocumentClassificationAndTypeDefault });
        setType({ ...DocumentTypeDefault });
        setTypes([]);
        setForm({ ...DocumentTypeFormDefault });
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  };

  const fetchAllTags = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getAllTagsRequest();
      const allOrganizationTags = res.tagList;
      setHintTags(allOrganizationTags.map((item): DropDownMenuItem<DocTypeTag> => ({ text: item.tagLabel, value: { ...item, modified: false, key: uuid() } })));
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    } finally {
      setLoading(false);
    }
  }, [getAllTagsRequest, openMessageModal]);

  const onSaveDocumentType = async () => {
    const findCreatedTag = form.defaultTags.find((tag) => tag.tagSettingId === 0);
    const cantAddTag = form.defaultTags.find((defaultTag) => allTagsState.find((tag) => (tag.tagLabel === defaultTag.tagLabel && defaultTag.tagSettingId === 0)));
    const duplicateTag = form.defaultTags.find((defaultTag, index) => form.defaultTags.findIndex((tag) => tag.tagLabel === defaultTag.tagLabel) !== index);

    if (findCreatedTag) {
      openMessageModal("Can't create new tag, select one of the options from the list.");
      return;
    }
    if (cantAddTag) {
      openMessageModal(`「${cantAddTag.tagLabel}」はすでに登録済みです。別名で登録してください。`);
      return;
    }
    if (duplicateTag) {
      openMessageModal(`「${duplicateTag.tagLabel}」はすでに登録済みです。別名で登録してください。`);
      return;
    }
    if (form.documentTypeName.trim().length < 1) {
      openMessageModal('文書種類名を入力してください。');
      return;
    }
    form.documentClassificationId = documentClassificationAndType.id;
    if (form.defaultTags.some((item) => item.tagLabel.trim().length < 1)) {
      setLoading(false);
      openMessageModal('タグ名を入力してください。');
      return;
    }
    setLoading(true);
    try {
      form.defaultTags = form.defaultTags.filter((item) => item.tagSettingId !== systemTags.find((tag) => tag.tagLabel === item.tagLabel)?.tagSettingId);
      await registerDocumentTypeApi.request(form);
      if (type.documentTypeId !== null) {
        setType({ ...type });
      } else {
        setDocumentClassificationAndType({ ...documentClassificationAndType });
      }
      if (form.documentTypeId === null) {
        openMessageModal('文書種類を登録しました。');
      } else {
        openMessageModal('文書種類を更新しました。');
      }
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
    setDocumentClassificationAndType(DocumentClassificationAndTypeDefault);
    setType(DocumentTypeDefault);
    setTypes([]);
    setLoading(false);
  };

  const deleteTag = useCallback((tag: DocTypeTag) => {
    setForm((f) => ({ ...f, defaultTags: f.defaultTags.filter((item) => item.key !== tag.key) }));
  }, []);

  const openCancelModal = useCallback(() => {
    setModal({
      open: true,
      text: '入力内容は破棄されます。遷移してよろしいですか？',
      onConfirm: () => {
        setType({ ...DocumentTypeDefault });
        setForm({ ...DocumentTypeFormDefault });
        setDocumentClassificationAndType({ ...DocumentClassificationAndTypeDefault });
        setTypes([]);
        setModal({ ...modal, open: false });
      },
      onCancel: () => setModal({ ...modal, open: false }),
    });
  }, [modal]);

  const onTagItemChanged = useCallback((item: DocTypeTag, key: keyof DocTypeTag, value: DocTypeTag[keyof DocTypeTag]) => {
    setForm({
      ...form,
      defaultTags: form.defaultTags.map((tag) => (item === tag ? { ...tag, [key]: value, modified: true } : tag)),
    });
  }, [form]);

  // MEMO's
  const documentSearchOptions = useMemo(
    () => (documents || []).map((item) => ({ text: item.name, value: item })),
    [documents],
  );
  const typeSearchOptions = useMemo(
    () => types.map((item) => ({ text: item.documentTypeName, value: item })),
    [types],
  );

  const addNewMode = useMemo(() => form.documentTypeId === null, [form]);

  const sortTags = useCallback((a:DocTypeTag, b: DocTypeTag) => {
    if (a.tagType === TagType.CUSTOMER && b.tagType === TagType.CUSTOMER) return 0;
    if (a.tagType === TagType.CUSTOMER) return 1;
    if (b.tagType === TagType.CUSTOMER) return -1;
    return 0;
  }, []);

  const onRowDragMove = useCallback((event: RowDragEvent<DocTypeTag>) => {
    if (!gridRef.current) return;
    const currentTable = form.defaultTags;
    const movingNode = event.node;
    const { overNode } = event;

    function moveInArray(arr: DocTypeTag[], fromIndex: number, toIndex: number) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }

    const rowNeedsToMove = movingNode !== overNode;
    if (!rowNeedsToMove) return;

    const movingData = movingNode.data;
    if (!movingData || !overNode) return;
    const overData = overNode.data;
    if (!overData) return;

    const fromIndex = currentTable.indexOf(movingData);
    const toIndex = currentTable.indexOf(overData);
    const newTable = [...currentTable];

    moveInArray(newTable, fromIndex, toIndex);
    setForm((prev) => ({ ...prev, defaultTags: newTable }));
    gridRef.current.api.clearFocusedCell();
  }, [form.defaultTags]);

  const onRowDragEnd = useCallback(() => {
    if (!gridRef.current) return;
    const currentTable = form.defaultTags;
    const newTable = [...currentTable];

    newTable.sort(sortTags);
    setForm((prev) => ({ ...prev, defaultTags: newTable }));
    gridRef.current.api.clearFocusedCell();
  }, [form.defaultTags, sortTags]);

  const canEditAgGridColumn = useCallback((value: DocTypeTag) => {
    const findTag = allTagsState.find((tag) => tag.tagSettingId === value.tagSettingId);
    if (findTag) {
      return findTag.tagType === TagType.CUSTOMER;
    }
    return true;
  }, [allTagsState]);

  const columns = useMemo((): ColDef[] => [
    {
      rowDrag: (params: EditableCallbackParams<DocTypeTag>) => {
        if (!params.data) return false;
        if (params.data.tagType === TagType.SYSTEM) return false;
        return true;
      },
      headerName: 'タグ名',
      resizable: true,
      field: 'tagLabel',
      width: 311,
      editable: (params) => params.data.tagType === TagType.CUSTOMER || !systemTags.some((t) => t.tagSettingId === params.data.tagSettingId),
      singleClickEdit: true,
      cellEditor: AutoCompleteCellRender,
      cellStyle: { paddingLeft: '10px', paddingRight: '10px' },
      cellEditorParams: {
        options: hintTags.map((item) => ({ label: item.text, value: item.value.tagLabel })),
        onSelect(row: DocTypeTag, value: any) {
          const selectedTag = hintTags.find((t) => t.text === value.value);
          if (selectedTag) {
            const tag = selectedTag.value;
            setForm({
              ...form,
              defaultTags: form.defaultTags.map((t) => (row === t ? { ...tag, modified: true } : t)),
            });
          }
        },
        onBlurSetTextAsSelected: true,
        menuStyle: { width: '100%' },
      },
      onCellValueChanged(params) {
        onTagItemChanged(params.data, 'tagLabel', params.data.tagLabel);
      },
      valueSetter: (params: ValueSetterParams<DocTypeTag>) => {
        const { data } = params;
        const newVal = params.newValue;
        const selectedTag = hintTags.find((t) => t.text === newVal);

        if (selectedTag == null) return false;

        data.tagLabel = newVal;

        return true;
      },
    }, {
      headerName: '書式',
      resizable: true,
      field: 'format',
      cellStyle: { overflow: 'initial' },
      cellRenderer: (params: ICellRendererParams<DocTypeTag>) => {
        if (!params.data) return '';
        return TagFormatTextMap[params.data.format];
      },
      width: 120,
    },
    {
      headerName: '種類',
      resizable: true,
      field: '',
      width: 120,
      valueGetter: ({ data }) => (data.tagType === TagType.CUSTOMER ? 'カスタマー' : 'システム'),
    },
    {
      headerName: '入力必須',
      resizable: true,
      field: 'require',
      width: 120,
      cellRenderer: CheckBoxCellRender,
      cellRendererParams: ({ data }: AutoCompleteCellRenderProps<DocTypeTag>) => ({
        align: 'center',
        disabled: data.tagType === TagType.SYSTEM
            || !canEditAgGridColumn(data),
      }),
      onCellValueChanged: (cell: NewValueParams<DocTypeTag>) => onTagItemChanged(cell.data, 'require', cell.data.require),
    },
    {
      headerName: '重複を許可しない',
      resizable: true,
      field: 'duplicate',
      width: 160,
      cellRendererSelector: ({ data }: ICellRendererParams<DocTypeTag>) => {
        if (data && data.duplicate) {
          return {
            component: IconCellRender,
            params: {
              src: '/images/check-solid.svg',
            },
          };
        }
        return undefined;
      },
    },
    {
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      field: 'options',
      cellStyle: (params) => {
        if (params.data.tagType === TagType.SYSTEM && systemTags.some((t) => t.tagSettingId === params.data.tagSettingId)) {
          return {
            display: 'none',
            overflow: 'initial',
            justifyContent: 'end',
            justifyItems: 'end',
          };
        }
        return {
          display: 'flex',
          overflow: 'initial',
          justifyContent: 'end',
          justifyItems: 'end',
        };
      },
      cellRenderer: (params: ICellRendererParams) => ButtonCellRender({
        label: '削除',
        data: params.data,
        onClick: (tag: DocTypeTag) => {
          if (tag.tagSettingId > 0) {
            setModal({
              open: true,
              text: '対象のタグは文書へ設定されている可能性があります。\n'
              + '削除してよろしいですか？',
              onConfirm() {
                setModal({ ...modal, open: false });
                deleteTag(tag);
              },
              onCancel: () => setModal({ ...modal, open: false }),
            });
          } else {
            deleteTag(tag);
          }
        },
      }),
      minWidth: 50,
      flex: 1,
    },

  ], [hintTags, systemTags, form, onTagItemChanged, canEditAgGridColumn, modal, deleteTag]);

  // EFFECT's
  useEffect(() => {
    try {
      getDocumentClassificationAndTypeRequest().then((docs) => {
        setDocuments(docs);
      });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [getDocumentClassificationAndTypeRequest, openMessageModal]);

  useEffect(() => {
    if (documentClassificationAndType.id === null) return;
    try {
      getDocumentTypeRequest({ documentClassificationId: documentClassificationAndType.id }).then((res) => {
        setTypes(res);
      });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [documentClassificationAndType, getDocumentTypeRequest, openMessageModal]);

  useEffect(() => {
    if (type.documentTypeId === null) return;
    if (documentClassificationAndType.id === null) return;
    setForm({ ...type, defaultTags: [] });
    const fetchTags = async () => {
      if (documentClassificationAndType.id === null) return;
      try {
        let defaultTags: BaseTag[] = [];
        if (type.documentTypeId !== null) {
          defaultTags = await getDefaultTagsRequest({ documentTypeId: type.documentTypeId });
        }
        let allTags = await getOrganizationTags();
        setAllTagsState(allTags);
        allTags = allTags.filter((t) => !defaultTags.some((d) => d.tagSettingId === t.tagSettingId));
        allTags = allTags.filter((t) => !systemTags.some((d) => d.tagSettingId === t.tagSettingId));
        const tags: DocTypeTag[] = [
          ...defaultTags.map((item: BaseTag): DocTypeTag => ({
            ...item, tagType: TagType.CUSTOMER, modified: false, key: uuid(),
          })),
          ...systemTags.map((item): DocTypeTag => ({
            ...item, tagType: TagType.SYSTEM, modified: false, key: uuid(),
          })),
        ];
        const hints = allTags.map((t): DocTypeTag => ({
          ...t, modified: false, key: uuid(),
        }));
        tags.sort(sortTags);
        hints.sort(sortTags);
        setForm({
          ...type,
          defaultTags: tags,
        });
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    };
    fetchTags();
  }, [type, documentClassificationAndType.id, getDefaultTagsRequest, getSystemTagsRequest, openMessageModal, systemTags, getOrganizationTags, sortTags]);

  useEffect(() => {
    fetchAllTags();
  }, [fetchAllTags]);

  return (
    <MainFrame borderBox>
      <div className={styles.mainFrame}>
        <Breadcrumb crumbs={[
          { label: 'マスタ管理', route: routes.masterDataSettingGeneral },
          { label: document.title }]}
        />
        <div className={styles.mainFrameBody}>
          <div className={styles.mainFrameLeftSection}>
            <div className={styles.mainFrameLeftSectionTop}>
              <div className={styles.title}>文書分類</div>
              <div className={styles.mt10}>
                <AutoCompleteInput
                  placeholder="検索"
                  renderPreInput="&#xF002;"
                  options={documentSearchOptions}
                  onSelect={setDocumentClassificationAndType}
                />
              </div>
            </div>

            <table className={styles.tableHeader}>
              <tbody>
                <tr>
                  <th style={{ width: '38px', minWidth: '38px', maxWidth: '38px' }}>No</th>
                  <th style={{ width: '166px' }}>文書分類</th>
                </tr>
              </tbody>
            </table>

            <div className={styles.mainFrameLeftSectionList}>
              <table>
                <tbody>
                  {documents.map((item) => (
                    <tr
                      key={item.id}
                      style={{ cursor: 'pointer', background: documentClassificationAndType.id === item.id ? 'var(--lighterGray)' : '' }}
                      onClick={async () => {
                        setDocumentClassificationAndType(item);
                        setType(DocumentTypeDefault);
                        if (item.id !== null) {
                          const selectedSystemTags = await getSystemTagsRequest({ documentClassificationId: item.id });
                          setSystemTags(selectedSystemTags);
                        }
                      }}
                    >
                      <td style={{ width: '38px', minWidth: '38px', maxWidth: '38px' }}>
                        {documents.indexOf(item) + 1}
                      </td>
                      <td className={`${styles.borderRight} ${styles.tableName}`} title={item.name}><span>{item.name}</span></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.mainFrameLeftSection}>
            <div className={styles.mainFrameLeftSectionTop}>
              <div className={styles.title}>文書種類</div>
              <div className={styles.mt10}>
                <AutoCompleteInput
                  placeholder="検索"
                  renderPreInput="&#xF002;"
                  options={typeSearchOptions}
                  onSelect={setType}
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                <Button size="smaller" fullWidth disabled={documentClassificationAndType.id === null} onClick={onClickNewDefaultTag}>
                  文書種類を追加
                </Button>
              </div>
            </div>

            <table className={styles.tableHeader}>
              <tbody>
                <tr>
                  <th style={{ width: '38px', minWidth: '38px', maxWidth: '38px' }}>No</th>
                  <th style={{ width: '166px' }}>文書種類</th>
                </tr>
              </tbody>
            </table>

            <div className={styles.mainFrameLeftSectionList}>
              <table>
                <tbody>
                  {types.map((item) => (
                    <tr
                      key={item.documentTypeId}
                      style={{ cursor: 'pointer', background: type.documentTypeId === item.documentTypeId ? 'var(--lighterGray)' : '' }}
                      onClick={() => setType(item)}
                    >
                      <td style={{ width: '38px', minWidth: '38px', maxWidth: '38px' }}>
                        {types.indexOf(item) + 1}
                      </td>
                      <td className={styles.borderRight}>{item.documentTypeName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.mainFrameRightSection}>
            {type.documentTypeName !== '' ? (
              <>
                <div className={styles.mainFrameRightSectionTop}>
                  <div className={styles.title}>文書種類名</div>
                  <div className={styles.mt10}>
                    <input
                      className={styles.topInput}
                      value={form.documentTypeName}
                      type="text"
                      placeholder="文書種類名"
                      onChange={(e) => setForm({ ...form, documentTypeName: e.target.value.trim() })}
                    />
                  </div>
                  <div className={styles.mainFrameRightSectionTopButtons}>
                    <Button className={`${styles.topButton} ${styles.mr10}`} size="smaller" onClick={onClickNewTagRow}>
                      行を追加
                    </Button>
                    <Button className={`${styles.topButton} ${styles.mr10}`} disabled={addNewMode} size="smaller" onClick={onClickDuplicateType}>
                      複製
                    </Button>
                    <Button className={`${styles.topButton}`} size="smaller" disabled={addNewMode} onClick={onDeleteDocumentTypeClick}>
                      削除
                    </Button>
                  </div>
                </div>
                <div className={styles.mainFrameRightSectionList}>
                  <BaseTable<DocTypeTag>
                    tableRef={gridRef}
                    formName="docTypeListTable"
                    rowData={form.defaultTags}
                    columnDefs={columns}
                    suppressRowTransform
                    stopEditingWhenCellsLoseFocus
                    onRowDragMove={onRowDragMove}
                    onRowDragEnd={onRowDragEnd}
                    createLogOnDownloadTableData={LogFormName.DocTypeList}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={styles.mainFrameRightSectionTopEmpty} />
                <div className={styles.mainFrameRightSectionListEmpty} />
              </>
            )}
            <div className={styles.mainFrameRightSectionFooterButtons}>
              {type.documentTypeName !== '' && (
                <>
                  <Button color="lightGray" size="smaller" onClick={openCancelModal}>キャンセル</Button>
                  <Button disabled={saveButtonDisabled} className={`${mainStyles['float-right']}`} color="primary" size="smaller" onClick={onSaveDocumentType}>保存</Button>
                </>
              )}
            </div>
          </div>
          <AlertModal open={modal.open} text={modal.text} onConfirm={modal.onConfirm} onCancel={modal.onCancel} />
          {loading && <LoadingOverlay />}
        </div>
      </div>
    </MainFrame>
  );
}

export default Component;
