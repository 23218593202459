import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import { useCallback, useRef, useState } from 'react';
import { ClickAwayListener, PopperProps as MUIPopperProps } from '@mui/material';
import mainStyles from '../../pages/main.module.css';
import Formatter from '../../utils/formatters';
import CustomAdapterLuxon from './CustomAdapterLuxon';

interface DatePickerProps {
  className?: string,
  value: DateTime | null,
  onChange: (value: DateTime | null) => void,
  inputClassName?: string,
  parentDivClassName?: string,
  placeholder?: string,
  disablePast?: boolean,
  shouldDisableDate?: (date: DateTime) => boolean,
  onFocus?: () => void,
  onInput?: (value: string) => void,
  PopperProps?: Partial<MUIPopperProps>,
  disabled?: boolean,
  disableTyping?: boolean,
}

function DatePickerJp({
  className = '',
  value,
  onChange,
  disablePast = false,
  shouldDisableDate,
  inputClassName = '',
  parentDivClassName = '',
  placeholder = 'yyyy/mm/dd',
  onFocus = () => ({}),
  onInput = () => ({}),
  PopperProps,
  disabled = false,
  disableTyping = false,
}:DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState<DateTime | null>(value);
  const inputReference = useRef<HTMLInputElement | null>(null);

  const handleBlur = useCallback(() => {
    if (currentValue && !currentValue.isValid) {
      setCurrentValue(null);
      onChange(null);
      onInput('');
    }
  }, [currentValue, onChange, onInput]);

  const handleClose = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      handleBlur();
    }
  }, [isOpen, handleBlur]);

  const handleChange = useCallback((val: DateTime | null) => {
    setCurrentValue(val);
    onChange(val);
    if (val && val.isValid) {
      const stringDate = val ? Formatter.fromDateTimeToString(val, Formatter.defaultDateFormat) : '';
      onInput(stringDate);
      return;
    }
    if (val && !val.isValid) {
      onChange(null);
      return;
    }
    onInput('');
  }, [onChange, onInput]);
  return (
    <ClickAwayListener
      onClickAway={() => handleClose()}
      mouseEvent="onMouseDown"
    >
      <div>
        <LocalizationProvider
          dateAdapter={CustomAdapterLuxon(7)}
          adapterLocale="ja"
        >
          <DatePicker
            className={className}
            inputFormat="yyyy/MM/dd"
            value={value}
            disablePast={disablePast}
            shouldDisableDate={shouldDisableDate}
            onChange={(e) => handleChange(e)}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                style={{ position: 'relative' }}
                className={[parentDivClassName].join(' ')}
                onClick={disableTyping ? () => setIsOpen(!isOpen) : undefined}
              >
                <div ref={inputRef} style={{ position: 'absolute', right: 8, top: 15 }}>
                  {InputProps?.endAdornment}
                </div>
                <input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...inputProps}
                  ref={inputReference}
                  onFocus={onFocus}
                  onBlur={() => {
                    setTimeout(() => {
                      handleBlur();
                    }, 100);
                  }}
                  className={[mainStyles['date-picker-input'], inputClassName, disabled ? mainStyles['disabled-input-color'] : ''].join(' ')}
                  placeholder={placeholder}
                  onChange={(e) => {
                    setTimeout(() => {
                      if (inputReference && inputReference.current && inputReference.current.defaultValue) {
                        onInput(inputReference.current.defaultValue);
                      }
                    }, 50);
                    if (inputProps && inputProps.onChange) {
                      inputProps.onChange(e);
                    }
                  }}
                  readOnly={disableTyping}
                />
              </div>
            )}
            open={isOpen}
            onOpen={() => setIsOpen(!isOpen)}
            onClose={() => handleClose()}
            PopperProps={PopperProps}
            disabled={disabled}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
}

export default DatePickerJp;
