import { AgGridReact } from 'ag-grid-react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { DateTime } from 'luxon';
import { ColDef, RowGroupOpenedEvent } from 'ag-grid-community';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './confirmedRequestListScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import Button from '../../components/Button/button';
import DatePickerJp from '../../components/DatePickerJp';
import { ReserveManagementItem, ReserveManagementList, ReserveManagementSearchForm } from '../../services/http/reserveManagement.api';
import { useSearchReserveManagementApi } from '../../hooks/api/reserveManagement.hook';
import DetailCellRenderer from './detailCellRenderer';
import AlertCloseModal, { alertModalInitialState } from './alertCloseModal';
import BaseTable from '../../components/BaseTable';
import Breadcrumb from '../../components/Breadcrumb';
import { useDocumentSearchForReserveNoApi } from '../../hooks/api/documentWarehousing.hook';
import ExportLabelComponent from './exportLabelComponent';
import Formatter from '../../utils/formatters';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import AGUtils from '../../utils/ag-grid.utils';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import { DeliveryType } from '../../services/http/documentWarehousing.api';

/**
 * 確定依頼一覧ページ
 */
function Component() {
  usePageTitle('確定済依頼一覧');
  const openMessageModal = useMessageModal();
  const { request: createLog } = useCreateLogApi();

  const gridRef = useRef<AgGridReact<ReserveManagementList>>(null);

  // States
  const [fromDate, setFromDate] = useState<DateTime | null>(DateTime.local().minus({ year: 1 }));
  const [toDate, setToDate] = useState<DateTime | null>(null);
  const [searchForm, setSearchForm] = useState<ReserveManagementSearchForm>({});
  const [reserveManagementList, setReserveManagementList] = useState<ReserveManagementList[]>([]);
  const [labelExportDisplay, setLabelExportDisplay] = useState(false);
  const [modal, setModal] = useState(alertModalInitialState);
  const [selectedReserveNos, setSelectedReserveNos] = useState<ReserveManagementItem[]>([]);

  // API's
  const searchReserveManagementApi = useSearchReserveManagementApi();
  const searchForReserveNoApi = useDocumentSearchForReserveNoApi();

  useEffect(() => {
    if (labelExportDisplay) {
      document.title = '保管品ラベル発行選択';
      return;
    }
    document.title = '確定済依頼一覧';
  }, [labelExportDisplay]);

  // Methods
  const resetForm = () => {
    setFromDate(null);
    setToDate(null);
    setSearchForm({});
  };

  // Callbacks
  const onSearchClick = useCallback(async () => {
    if (searchForm.requestType === '') {
      delete searchForm.requestType;
    }

    try {
      const getReserveManagementList = await searchReserveManagementApi.request(searchForm);

      if (getReserveManagementList.length > 2000) {
        setModal({
          open: true,
          text: ` 検索結果が2000件を超えているため、\n
              2001件目以降が表示されていません。\n
              検索条件を更に絞り込んで再度検索してください。`,
          onClose: () => setModal({ ...modal, open: false }),
        });
      }
      setReserveManagementList(getReserveManagementList.slice(0, 2000));
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [searchForm, modal, searchReserveManagementApi]);

  // Initial States
  useEffect(() => {
    setSearchForm((_searchForm) => ({ ..._searchForm, dueDate_From: fromDate ? Formatter.fromDateTimeToString(fromDate, Formatter.defaultFullDateFormat) : undefined }));
  }, [fromDate]);

  // Memos
  const requestTypes = useMemo(() => [
    { name: '入庫', type: '1' },
    { name: '出庫', type: '2' },
    { name: '廃棄', type: '3' },
    { name: 'リモートスキャン', type: '4' },
  ], []);

  const colDef = useMemo((): ColDef<ReserveManagementList>[] => [
    {
      field: 'reserveNo',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      cellRenderer: 'agGroupCellRenderer',
      width: 50,
    },
    {
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      field: '',
      minWidth: 50,
      width: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    AGUtils.colAutoIncrement('no'),
    AGUtils.colDefault('reserveNo', '依頼番号', 110),
    AGUtils.colDefault('type', '依頼内容', 110),
    AGUtils.colDate('schDate', '予定日'),
    AGUtils.colDefault('name', '配送先', 130),
    {
      field: 'deliveryType',
      headerName: '集配方法',
      resizable: true,
      sortable: true,
      width: 130,
      cellClass: 'textFormat',
      valueGetter: ({ data }) => {
        if (data?.type === '廃棄' || data?.type === 'リモートスキャン') return '';
        const deliveryType = data?.deliveryType;
        if (deliveryType === '') return '';
        switch (Number(deliveryType)) {
          case DeliveryType.TeradaDelivery:
            return '寺田便';
          case DeliveryType.Courier:
            return '宅配便';
          default:
            return '';
        }
      },
    },
    {
      field: 'postNo',
      headerName: '住所',
      resizable: true,
      sortable: true,
      cellClass: 'textFormat',
      valueGetter: ({ data }) => [data?.postNo, data?.address1, data?.address2, data?.address3].join(', '),
      width: 200,
    },
    AGUtils.colUsername('staffName', '担当者名'),
    AGUtils.colDefault('phone', '電話番号', 150),
    {
      field: 'trdReserveManagementCd2',
      headerName: '廃棄処理報告書発行',
      resizable: true,
      sortable: true,
      cellClass: 'textFormat',
      valueGetter: ({ data }) => {
        const value = data?.trdReserveManagementCd2;
        switch (value) {
          case '0':
            return '不要';
          case '1':
            return '要';
          default:
            return '';
        }
      },
      width: 170,
    },
    AGUtils.colDefault('trdReserveNotes', '寺田倉庫への連絡事項', 200),
    AGUtils.colDefault('customerNotes', 'お客様メモ', 200),
    AGUtils.colDefault('createdBy', '依頼者', 110),
    AGUtils.colDate('createDatetime', '依頼日時'),
    {
      field: '',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      flex: 1,
    },
  ], []);

  const detailCellRenderer = (e:{ data: ReserveManagementItem }) => <DetailCellRenderer reserveNo={e.data.reserveNo} type={e.data.type} />;

  const onRowGroupOpened = (params: RowGroupOpenedEvent<ReserveManagementList>) => {
    if (params.node.expanded) {
      params.api.forEachNode((node) => {
        if (
          node.expanded
            && node.id !== params.node.id
        ) {
          node.setExpanded(false);
        }
      });
    }
  };

  const handleLabelGenerate = (nos: ReserveManagementItem[]) => {
    setLabelExportDisplay(true);
    try {
      searchForReserveNoApi.request(nos);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  };

  useEffect(() => {
    if (!labelExportDisplay) searchForReserveNoApi.setData(null);
  }, [labelExportDisplay]);

  const hasSelected = selectedReserveNos.length > 0;

  useEffectOnce(() => {
    createLog(LogFormName.ConfirmedRequestListScreen, LogControlName.Show);
  });

  return (
    <MainFrame borderBox>
      <div className={styles.mainFrame}>
        <Breadcrumb crumbs={[{ label: document.title }]} />
        <div className={styles.mainFrameBody}>
          <AlertCloseModal open={modal.open} text={modal.text} onClose={modal.onClose} />
          <div className={styles['mainframe-leftsection']}>
            <header>
              検索条件
            </header>
            <div>
              <label>依頼内容</label>
              <AutoCompleteInput
                value={requestTypes.find((item) => item.type === searchForm.requestType)?.name || ''}
                options={requestTypes.map((item) => ({ text: item.name, value: item.type }))}
                onSelect={(value:string) => setSearchForm({ ...searchForm, requestType: value })}
                onTextInput={(value) => setSearchForm({ ...searchForm, requestType: value })}
                deselectOption
              />
            </div>

            <div>
              <label>予定日</label>
            </div>
            <div className={styles['date-field']}>
              <label>FROM</label>
              <DatePickerJp
                parentDivClassName={styles['w-100']}
                inputClassName={styles['w-100']}
                value={fromDate}
                onChange={(newValue) => {
                  setSearchForm({ ...searchForm, dueDate_From: newValue ? Formatter.fromDateTimeToString(newValue, Formatter.defaultFullDateFormat) : undefined });
                  setFromDate(newValue);
                }}
              />
              {' '}
            </div>
            <div className={styles['date-field']}>
              <label>TO</label>
              <DatePickerJp
                parentDivClassName={styles['w-100']}
                inputClassName={styles['w-100']}
                value={toDate}
                onChange={(newValue) => {
                  setSearchForm({ ...searchForm, dueDate_To: newValue ? Formatter.fromDateTimeToString(newValue, Formatter.defaultFullDateFormat) : undefined });
                  setToDate(newValue);
                }}
              />
            </div>
            <footer>
              <Button
                className={mainStyles['ml-10']}
                size="smaller"
                color="lighterGray"
                onClick={() => resetForm()}
              >
                条件リセット
              </Button>
              <Button
                className={mainStyles['mr-10']}
                size="smaller"
                onClick={onSearchClick}
                loading={searchReserveManagementApi.loading}
              >
                検索
              </Button>
            </footer>
          </div>
          <div className={styles['mainframe-rightsection']}>
            <BaseTable<ReserveManagementList>
              tableRef={gridRef}
              formName="confirmedRequestTable"
              rowData={reserveManagementList}
              columnDefs={colDef}
              onSelectionChanged={(e) => {
                setSelectedReserveNos(e.api.getSelectedRows().map((d) => ({
                  reserveNo: d.reserveNo,
                  type: d.type,
                })));
              }}
              suppressRowClickSelection
              rowSelection="multiple"
              masterDetail
              detailCellRenderer={detailCellRenderer}
              detailRowAutoHeight
              onRowGroupOpened={onRowGroupOpened}
              embedFullWidthRows
              sideBar
              createLogOnDownloadTableData={LogFormName.ConfirmedRequestListScreen}
            />
            {labelExportDisplay && (
              <ExportLabelComponent
                data={searchForReserveNoApi.data}
                display={labelExportDisplay}
                setModalDisplay={setLabelExportDisplay}
                tableName="confirmedRequestExportLabelComponent"
              />
            )}
            <div className={styles['mainframe-rightsection-footer']}>
              <button
                type="button"
                className={[styles.downloadButton, mainStyles.button, hasSelected ? mainStyles['btn-primary'] : mainStyles['btn-gray']].join(' ')}
                disabled={hasSelected === false}
                onClick={() => handleLabelGenerate(selectedReserveNos)}
              >
                保管品ラベル発行
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainFrame>
  );
}

export default Component;
