import React, {
  CSSProperties, useMemo, useState,
} from 'react';
import styles from './selectInput.module.css';
import DropdownMenu, { DropDownMenuItem } from '../DropdownMenu';

export type SelectValueType = string | number | readonly string[] | undefined;

interface SelectInputProps<T> {
  className?: string,
  value: T | undefined | null,
  disabled?: boolean,
  style?: CSSProperties,
  options?: DropDownMenuItem<T>[],
  ref?: React.LegacyRef<HTMLInputElement>,
  onChange: (value: T)=> void
  menuStyle?: CSSProperties
}

function SelectInput<T=string>({
  className = '', value, options = [], ref, disabled = false, style = {}, onChange, menuStyle,
}: SelectInputProps<T>) {
  const [openMenu, setShowOptions] = useState(false);

  const text = useMemo(() => options.find((item) => item.value === value)?.text || '', [options, value]);

  return (
    <div className={[styles.container, className].join(' ')} style={style}>
      <DropdownMenu
        open={openMenu}
        className={styles.inputContainer}
        disabled={disabled}
        options={options}
        onSelect={onChange}
        onOutsideClick={() => setShowOptions(false)}
        menuStyle={menuStyle}
      >
        <div className={styles.inputWrapper}>
          <input className={styles.input} value={text} type="text" readOnly disabled={disabled} ref={ref} />
        </div>
      </DropdownMenu>
      <div
        className={styles.inputPosIcon}
        style={{ backgroundColor: disabled ? '#F5F5F5' : '' }}
        onClick={() => !disabled && setShowOptions(!openMenu)}
        onKeyPress={() => !disabled && setShowOptions(!openMenu)}
        role="button"
        tabIndex={0}
      />
    </div>
  );
}

export default SelectInput;
