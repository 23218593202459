import { DateTime } from 'luxon';

export default class Formatter {
  /**
   * バイト数の値を単位を変換した文字列に変換します。
   *
   * @param val - バイト数の値
   * @returns - バイト数の説明文字列
   */
  static byteSizeDescription(val: unknown): string {
    // eslint-disable-next-line no-self-compare
    if (!(typeof val === 'number' && val === val) || Number(val) <= 0) return '';
    const i = Math.floor(Math.log(val) / Math.log(1024));
    return `${Number((val / 1024 ** i).toFixed(2))} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
  }

  static defaultFullDateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";

  static defaultDateTimeFormat = 'yyyy/MM/dd HH:mm:ss';

  static defaultDateFormat = 'yyyy/MM/dd';

  static hyphenDateFormat = 'yyyy-MM-dd';

  static defaultTimeFormat = 'HH:mm:ss';

  /**
   * 文字列を日付型に変換します。
   *
   * @param val - 文字列型の日付(utc)
   * @returns - Dateインスタンスを返す
   */
  static fromStringToDate(val: string): Date {
    return DateTime.fromISO(val, { zone: 'utc' }).toJSDate();
  }

  /**
   * 文字列を指定されたフォーマットに従って日付型に変換します。
   *
   * @param val - 文字列型の日付(utc)
   * @param format - 日付のフォーマット
   * @returns - Dateインスタンスを返す
   */
  static fromStringToDateWithFormat(val: string, format: string): Date {
    return DateTime.fromFormat(val, format, { zone: 'utc' }).toJSDate();
  }

  /**
   * 文字列（日付）を指定されたフォーマットに変換します。
   *
   * @param val - 文字列型の日付(utc)
   * @param format - 日付のフォーマット
   * @returns - 変換後の文字列を返す
   */
  static fromStringToFormattedString(val: string, format: string): string {
    return DateTime.fromISO(val, { zone: 'utc' }).toFormat(format);
  }

  /**
   * 文字列（日付）を指定されたフォーマットに従って、DateTime型に変換します。
   *
   * @param val - 文字列型の日付(utc)
   * @param format - 日付のフォーマット
   * @returns - DateTimeインスタンスを返す
   */
  static fromFormatToDateTime(val: string, format: string): DateTime {
    return DateTime.fromFormat(val, format, { zone: 'utc' });
  }

  /**
   * 日付型を文字列に変換します。
   *
   * @param val - 日付型のデータ
   * @returns - 変換後の文字列を返す
   */
  static fromDateToString(val: Date): string {
    return DateTime.fromJSDate(val).toFormat(Formatter.defaultFullDateFormat);
  }

  /**
   * 日付型をハイフン区切りの文字列に変換します。
   *
   * @param val - 日付型のデータ
   * @returns 変換後の文字列を返す
   */
  static fromDateToStringFormatHyphenDate(val: Date): string {
    return DateTime.fromJSDate(val).toFormat(Formatter.hyphenDateFormat);
  }

  /**
   * DateTime型をローカルタイムに変換した上で文字列に変換します。
   *
   * @param val - DateTimeオブジェクト
   * @param format - 日付のフォーマット
   * @returns - 変換後の文字列を返す
   */
  static fromDateTimeToString(val: DateTime, format: string): string {
    return val.toFormat(format);
  }

  /**
   * DateTime型を指定のフォーマットで変換します。
   *
   * @param val - DateTimeオブジェクト
   * @param format - 日付のフォーマット
   * @returns - 変換後の文字列を返す
   */
  static fromLocalDateTimeToString(val: DateTime, format: string): string {
    return val.toFormat(format);
  }

  /**
   * 日付型を指定されたフォーマットに従って文字列に変換します。
   *
   * @param val - 日付型のデータ(utc)
   * @param format - 日付のフォーマット
   * @returns - 変換後の文字列を返す
   */
  static toDisplayDate(val: Date, format: string): string {
    return DateTime.fromJSDate(val, { zone: 'utc' }).toFormat(format);
  }

  /**
   * 空白を削除し、全て空白である場合は、nullを返す。
   *
   * @param val - 入力文字列
   * @returns - 文字列かnullを返す
   */
  static validString(val?: string): string | null {
    const result = val?.trim();
    if (result && result.length > 0) return result;
    return null;
  }

  /**
   * DateTime型を指定のフォーマットで変換します。
   *
   * @param val - DateTimeオブジェクト
   * @param format - 日付のフォーマット
   * @returns - 変換後のDateTimeを返す
   */
  static reformatDateTime(val: DateTime, format: string): DateTime {
    const formattedString = val.toFormat(format);
    return DateTime.fromFormat(formattedString, format, { zone: 'utc' });
  }
}
