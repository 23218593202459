import { useCallback } from 'react';
import RadioGroupInput from '../../components/RadioGroupInput';
import styles from './userModalTabItem.module.css';

export interface TabSettingGroup {
  id: string,
  name: string,
  list: TabSetting[],
}

export interface TabSetting {
  id: string,
  settingType: number,
  settingName: string,
  settingFlg: boolean,
}

export interface UserModalTabItemProps {
  item: TabSettingGroup,
  onChange: (data: TabSettingGroup) => void
}

function UserModalTabItem({
  item,
  onChange,
}: UserModalTabItemProps) {
  const handleChange = useCallback((oldItem: TabSetting, value: number) => {
    const newItem = {
      ...oldItem,
      settingFlg: Boolean(value),
    };
    const newItemGroup: TabSettingGroup = {
      id: item.id,
      name: item.name,
      list: item.list.map((setting) => {
        if (setting.id === newItem.id) {
          return newItem;
        }
        return setting;
      }),
    };
    onChange(newItemGroup);
  }, [item, onChange]);

  return (
    <div className={styles.tabItem}>
      <div className={styles.tabItemTitle}>
        {item.name}
      </div>
      {item.list.map((setting: TabSetting) => (
        <div key={setting.id} className={styles.tabItemCard}>
          <div className={styles.tabItemSubTitle}>
            {setting.settingName}
          </div>
          <div className={styles.tabCardRow}>
            <RadioGroupInput<number>
              value={Number(setting.settingFlg)}
              onChange={(val) => {
                handleChange(setting, Number(val));
              }}
            >
              <RadioGroupInput.RadioInput value={0}>受け取る</RadioGroupInput.RadioInput>
              <RadioGroupInput.RadioInput value={1}>受け取らない</RadioGroupInput.RadioInput>
            </RadioGroupInput>
          </div>
        </div>
      ))}
    </div>
  );
}

export default UserModalTabItem;
