import { useCallback, useEffect, useState } from 'react';
import { Badge } from '@mui/material';
import usePageTitle from '../../hooks/title.hook';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './cart.module.css';
import Breadcrumb from '../../components/Breadcrumb';
import BaseTab, { BaseTabItem } from '../../components/BaseTab/BaseTab';
import ReceiveTab from './receiveTab';
import ReceiveAgainTab from './receiveAgainTab';
import RemoteScanTab from './remoteScanTab';
import DispatchTab from './dispatchTab';
import DisposalTab from './disposalTab';
import { useReservationCartGetCount } from '../../hooks/api/reservationCart.hook';
import { RequestType } from '../../services/http/reservationCart.api';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';

function Component() {
  usePageTitle('依頼リスト');
  const [initalActiveTab, setInitalActiveTab] = useState<number | null>(null);
  const [receiveQuantity, setReceiveQuantity] = useState<number | null>(null);
  const [receiveAgainQuantity, setReceiveAgainQuantity] = useState<number | null>(null);
  const [remoteScanQuantity, setRemoteScanQuantity] = useState<number | null>(null);
  const [dispatchQuantity, setDispatchQuantity] = useState<number | null>(null);
  const [disposalQuantity, setDisposalQuantity] = useState<number | null>(null);
  const { request: reservationCartGetCountRequest } = useReservationCartGetCount();
  const openMessageModal = useMessageModal();

  const renderTitle = (title: string, quantity: number | null) => (
    <div className={styles.tabTitle}>
      {title}
      {
        Boolean(quantity) && (
          <Badge
            classes={{
              badge: styles.tabTitleBadge,
            }}
            max={999999}
            badgeContent={quantity}
            showZero
          />
        )
      }
    </div>
  );

  const fetchReservationCartGetCount = useCallback(async () => {
    try {
      const { cartCountList } = await reservationCartGetCountRequest({});
      const receiveCartCount = cartCountList.find(({ requestType }) => requestType === RequestType.RECEIVE);
      const receiveAgainCartCount = cartCountList.find(({ requestType }) => requestType === RequestType.RECEIVE_AGAIN);
      const remoteScanCartCount = cartCountList.find(({ requestType }) => requestType === RequestType.REMOTE_SCAN);
      const dispatchCartCount = cartCountList.find(({ requestType }) => requestType === RequestType.DISPATCH);
      const disposalCartCount = cartCountList.find(({ requestType }) => requestType === RequestType.DISPOSAL);

      setReceiveQuantity(receiveCartCount?.count || 0);
      setReceiveAgainQuantity(receiveAgainCartCount?.count || 0);
      setRemoteScanQuantity(remoteScanCartCount?.count || 0);
      setDispatchQuantity(dispatchCartCount?.count || 0);
      setDisposalQuantity(disposalCartCount?.count || 0);

      if (receiveCartCount?.count) setInitalActiveTab(0);
      else if (receiveAgainCartCount?.count) setInitalActiveTab(1);
      else if (remoteScanCartCount?.count) setInitalActiveTab(2);
      else if (dispatchCartCount?.count) setInitalActiveTab(3);
      else if (disposalCartCount?.count) setInitalActiveTab(4);
      else setInitalActiveTab(0);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [openMessageModal, reservationCartGetCountRequest]);

  useEffect(() => {
    fetchReservationCartGetCount();
  }, [fetchReservationCartGetCount]);

  return (
    <MainFrame
      borderBox
    >
      <div className={styles.mainframe}>
        <Breadcrumb crumbs={[{ label: document.title }]} />
        <div className={styles.mainFrameBody}>
          <div className={styles.body}>
            {initalActiveTab != null && (
              <BaseTab isSmallHeader initalActiveTab={initalActiveTab}>
                <BaseTabItem title={renderTitle('新規入庫', receiveQuantity)}>
                  <ReceiveTab total={receiveQuantity} fetchReservationCartGetCount={fetchReservationCartGetCount} />
                </BaseTabItem>
                <BaseTabItem title={renderTitle('再入庫', receiveAgainQuantity)}>
                  <ReceiveAgainTab total={receiveAgainQuantity} fetchReservationCartGetCount={fetchReservationCartGetCount} />
                </BaseTabItem>
                <BaseTabItem title={renderTitle('リモートスキャン', remoteScanQuantity)}>
                  <RemoteScanTab total={remoteScanQuantity} fetchReservationCartGetCount={fetchReservationCartGetCount} />
                </BaseTabItem>
                <BaseTabItem title={renderTitle('出庫', dispatchQuantity)}>
                  <DispatchTab total={dispatchQuantity} fetchReservationCartGetCount={fetchReservationCartGetCount} />
                </BaseTabItem>
                <BaseTabItem title={renderTitle('廃棄', disposalQuantity)}>
                  <DisposalTab total={disposalQuantity} fetchReservationCartGetCount={fetchReservationCartGetCount} />
                </BaseTabItem>
              </BaseTab>
            )}
          </div>
        </div>
      </div>
    </MainFrame>
  );
}

export default Component;
