import React, { useState, useCallback } from 'react';

const getInitialWidthFromStorage = (storageKey: string, defaultWidth: number): number => {
  const savedWidth = localStorage.getItem(storageKey);
  return savedWidth ? Number(savedWidth) : defaultWidth;
};

const saveWidthToStorage = (storageKey: string, width: number) => {
  localStorage.setItem(storageKey, String(width));
};

const calculateNewWidth = (currentWidth: number, deltaX: number, minWidth: number, maxWidth: number): number => Math.max(minWidth, Math.min(maxWidth, currentWidth + deltaX));

const useColumnResize = (
  widthStorageKey: string,
  initialWidth: number,
  minWidth = 220,
  maxWidth = 800,
) => {
  const [width, setWidth] = useState<number>(() => getInitialWidthFromStorage(widthStorageKey, initialWidth));

  const handleResizeStart = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    const startX = event.clientX;

    const handleResizeMove = (mouseMoveEvent: MouseEvent) => {
      const deltaX = mouseMoveEvent.clientX - startX;
      const newWidth = calculateNewWidth(width, deltaX, minWidth, maxWidth);

      setWidth(newWidth);
      saveWidthToStorage(widthStorageKey, newWidth);
    };

    const handleResizeEnd = () => {
      window.removeEventListener('mousemove', handleResizeMove);
      window.removeEventListener('mouseup', handleResizeEnd);
    };

    window.addEventListener('mousemove', handleResizeMove);
    window.addEventListener('mouseup', handleResizeEnd);
  }, [width, minWidth, maxWidth, widthStorageKey]);

  return { width, handleResizeStart };
};

export default useColumnResize;
