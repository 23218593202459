import React, {
  useCallback, useEffect, useState,
} from 'react';
import ScreenModal from '../../components/ScreenModal/screenModal';
import Button from '../../components/Button/button';
import styles from './notificationSettingModal.module.css';
// eslint-disable-next-line import/no-named-as-default
import useReminderApi from '../../hooks/api/reminder.hooks';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import { GetReminderResponse } from '../../services/http/reminder.api';
import { AlertModalProps, CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';

export interface Props {
  tagSettingId: number
  onCancel: () => void
  onSave: () => void
  tagName: string
  reminderNoticePeriodProp: number
  reminderUseFlgProp: number
}

function Component({
  onCancel, onSave, tagSettingId, tagName, reminderNoticePeriodProp, reminderUseFlgProp,
}: Props) {
  // Api
  const {
    getReminderRequest: getReminder,
    updateReminderRequest: updateReminder,
  } = useReminderApi();

  // hooks
  const openMessageModal = useMessageModal();

  // states
  const [closeAlertModal, setCloseAlertModal] = useState<AlertModalProps>(closeModalInitialState);
  const [form, setForm] = useState<GetReminderResponse>({
    tagSettingId,
    reminderNoticePeriod: reminderNoticePeriodProp,
    reminderUseFlg: reminderUseFlgProp,
  });

  // methods
  const handleToggleNotification = (checked: boolean) => {
    const reminderUseFlg = checked ? 1 : 0;
    setForm((prevForm) => ({ ...prevForm, reminderUseFlg }));
  };

  const handleSave = useCallback(async () => {
    const { reminderNoticePeriod } = form;

    if (
      typeof reminderNoticePeriod !== 'number'
        || reminderNoticePeriod < 0
        || !Number.isInteger(reminderNoticePeriod)
        || reminderNoticePeriod.toString().length > 4
    ) {
      setCloseAlertModal({
        ...closeAlertModal,
        text: '日数の設定が正しくありません。\n0から9999の数字を入力してください。',
        open: true,
        onCancel() {
          setCloseAlertModal({ ...closeAlertModal, open: false });
        },
      });
      return;
    }

    const updatedForm = {
      ...form,
      tagSettingId,
    };

    try {
      await updateReminder(updatedForm);
      onSave();
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [form, tagSettingId, closeAlertModal, updateReminder, onSave, openMessageModal]);

  // useEffects
  useEffect(() => {
    const fetchData = async () => {
      try {
        const reminder = await getReminder(tagSettingId);
        setForm(reminder);
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    };

    fetchData();
  }, [getReminder, tagSettingId, openMessageModal]);

  return (
    <ScreenModal
      customStyles={{
        width: '100%',
        maxWidth: '550px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        border: 'var(--defaultBorder)',
        overflowY: 'auto',
      }}
    >
      <div className={styles['modal-header']}>
        <div className={styles.title}>期限通知設定</div>
      </div>
      <div className={styles['notification-email-settings']}>
        <div>
          <div className={`${styles.text}`} style={{ display: 'flex' }}>
            <div className={styles.tagLabel}>タグ名：</div>
            <div className={styles.tagName}>{tagName}</div>
          </div>
          <div className="d-flex align-items-center" style={{ marginTop: '15px', minHeight: 25 }}>
            <div className={styles['toggle-switch']}>
              <span className={styles.text} style={{ marginRight: '10px' }}>通知の有効</span>
              <label className={styles.switch} style={{ marginLeft: '10px' }}>
                <input
                  type="checkbox"
                  checked={form.reminderUseFlg === 1}
                  onChange={(e) => handleToggleNotification(e.target.checked)}
                />
                <span className={styles.slider} />
              </label>
            </div>
            <div style={{ marginLeft: '40px' }}>
              <input
                type="text"
                value={form.reminderNoticePeriod !== null ? String(form.reminderNoticePeriod) : ''}
                className={styles['days-input']}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                  setForm({
                    ...form,
                    reminderNoticePeriod: sanitizedValue ? Number(sanitizedValue) : null,
                  });
                }}
              />
              <span className={styles.text} style={{ marginLeft: '20px' }}>日前</span>
            </div>
          </div>
        </div>

      </div>
      <div className={styles['modal-footer']}>
        <div className={styles['mainframe-body-footer-buttons']}>
          <Button size="smaller" color="lighterGray" onClick={onCancel}>キャンセル</Button>
          <div className="text-red" />
          <Button
            size="smaller"
            onClick={() => handleSave()}
            loading={false}
          >
            保存
          </Button>
        </div>
      </div>
      <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
    </ScreenModal>
  );
}

export default Component;
