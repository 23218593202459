import axios from 'axios';
import { DateTime } from 'luxon';
import { Tag } from './tag.api';
import FileUtils from '../../utils/file.utils';
import Formatter from '../../utils/formatters';
import { DocumentClassificationAndType } from './documentManagement.api';
import TagFormat from '../../utils/tagFormat';
import { WarehouseStatusCode } from '../../utils/warehouse.utils';
import { Paginated } from '../../components/BaseTable/partials/PaginationJp/mannual';
import { Folder } from './folder.api';
import { ControlCodeItem } from '../../utils/controlCode';

export enum Order {
  ASC = 0,
  DESC = 1,
}

export type ContractFlag = 1 | 0;

export interface DocumentClassificationOption {
  documentClassification: DocumentClassificationAndType;
  type: DocumentClassificationAndType['types'][number];
}

export interface DuplicateTag {
  tagLabel?: string,
  value?: string,
}

export interface RelationalDocument {
  ccControlNumber?: number,
  documentId?: number,
  documentName?: string,
  documentType?: string,
  barcodePrinting?: string,
  itemCode?: string,
  folderName?: string,
  duplicateTagList?: DuplicateTag[],
}

export interface Document {
  ccControlNumber: number
  id: number
  name: string
  fileName?: string
  fileSize?: number
  typeId?: number
  typeName?: string
  tagList?: { label: string, value: string, }[]
  relationalDocumentCount?: number
  relationalDocumentList?: RelationalDocument[]
  warehouseStatus?: string
  warehouseStatusCode?: string
  paperFlg?: ContractFlag
  electronicFlg?: ContractFlag
  registUser: string
  registDate: Date
  updateUser: string
  updateDate: Date
  itemCode?: string
  barcodePrinting: string
  requiredTagInputFlg?: number
  folderId: number
  folderName: string
  authorityList: ControlCodeItem[]
  cartAddedFlg?: number
}

export interface DocumentDetail {
  ccControlNumber: number,
  id: number,
  documents: {
    dataId: number,
    versionNo: number,
    name: string,
    fileName: string,
    fileSize: number,
    classification: number,
    typeId: number,
    typeName: string,
    memo: string,
    attachmentFileList: {
      id: number,
      fileName: string,
    }[],
    tags: {
      tagLabel: string,
      format: TagFormat,
      value: string,
      required: boolean,
    }[],
    relationalDocuments: {
      ccControlNumber: number,
      id: number,
      name: string,
      type: string,
      duplicateTags: {
        label: string,
        value: string,
      }[],
    }[],
  }[],
  paperFlg: ContractFlag,
  electronicFlg: ContractFlag,
  barcodePrinting: string,
  barcode: string,
  warehouseStatus: string | null,
  itemCode: string,
  scheduledDate: Date | null,
  barcodeChangeable: number,
  requestHistory: {
    date: string,
    type: string,
    user: string,
  }[],
  versionUpdateHistory: {
    logId: number | null,
    registDate: string,
    requestUser: string,
    updateType: string,
  }[],
  folder: Folder,
}
export interface DocumentFolder {
  documentList: {
    documentId: number,
    documentName: string,
    documentFileName?: string,
    documentFileSize?: number,
    documentTypeId?: number,
    documentTypeName?: string,
  }[],
  tagList?: {
    tagLabel?: string,
    tagValue?: string,
    relationalDocumentCount?: number,
  }[],
  relationalDocumentList?: RelationalDocument[],
  warehouseStatus?: string,
  paperFlg?: ContractFlag,
  electronicFlg?: ContractFlag,
  registUser: string,
  registDate: string,
  updateUser: string,
  itemCode?: string,
  total: number,
  page: number,
}

export interface DocumentPagination extends Paginated {
  highlightWordList: string[],
  documents: Document[],
}

export interface DocumentSearchForm {
  simpleString?: string,
  page: number,
  sortList?: SortList[],
}
export interface DocumentSearchFolderForm {
  folder: Folder,
  page: number,
  sortList?: SortList[],
}

export interface SortList {
  item: string,
  order: Order,
}

export interface DocumentRegisterForm {
  internalId: string,
  documentName: string,
  documentTypeId: number,
  documentTypeName?: string,
  barcodePrinting?: string,
  documentClassification?: number | null,
  folderId?: number,
  documentFileName?: string,
  documentFile?: File,
  paperFlg?: ContractFlag,
  electronicFlg?: ContractFlag,
  memo?: string,
  tagList: {
    tagLabel: string,
    format: TagFormat,
    required: boolean,
    value: string,
  }[],
  relationalDocumentList: Document[],
  attachmentFileList: {
    attachmentFile?: File,
    attachmentFileName?: string,
    attachmentFileSize?: number
  }[]
}

export interface DocumentGetDetailForm {
  id: number,
}

export interface TagListUrl {
  label: string,
  condition: string,
  value1: string,
}

export interface SearchUrlForm {
  tagList?: TagListUrl[];
  [key: string]: any;
}

export interface CcControlNumber {
  ccControlNumber: number,
}

export interface DocumentSearchDetailForm {
  ccControlNumber?: string,
  name?: string,
  fileName?: string,
  barcodePrinting?: string,
  itemCode?: string,
  documentTypeId?: number,
  registDate_From?: DateTime,
  registDate_To?: DateTime,
  updateDate_From?: DateTime,
  updateDate_To?: DateTime,
  warehouseStatus?: WarehouseStatusCode,
  electronicContract?: ContractFlag,
  existPaper?: ContractFlag,
  memo?: string,
  ccControlNumberList?: CcControlNumber[],
  tagList?: {
    label?: string,
    condition?: string,
    value1?: string,
    value2?: string,
  }[],
  searchRange?: number,
  page?: number,
  sortList?: {
    item: string,
    order: Order,
  }[],
  bulkTagChange?: number,
}

export interface GetPdfForm {
  documentId: number,
  fileName: string,
}

export interface DownloadFileForm {
  documentDataId: number,
  fileName?: string,
}
export interface DownloadAttachmentForm {
  attachmentId: number,
  fileName?: string,
}

export interface DocumentUpdateForm {
  id: number,
  name: string,
  file?: File,
  fileName?: string,
  fileSize?: number,
  classification?: number,
  typeId?: number,
  typeName?: string,
  paperFlg?: ContractFlag,
  electronicFlg?: ContractFlag,
  barcodePrinting?: string,
  tagList: { tagLabel: string, value: string, format: TagFormat, required: boolean }[],
  memo?: string,
  relationalDocumentList?: { ccControlNumber?: number, id?: number, name?: string, type?: string, duplicateTags?: { label: string; value: string; }[] }[],
  attachmentFileList: { id?: number, file?: File, fileName?: string, fileSize?: number, deleted?: boolean }[],
}

export interface DocumentRevisionForm {
  id: number,
  name: string,
  file?: File,
  fileName?: string,
  fileSize?: number,
  classification?: number,
  typeId?: number,
  typeName?: string,
  paperFlg?: ContractFlag,
  electronicFlg?: ContractFlag,
  barcodePrinting?: string,
  tagList: { tagLabel: string, value: string, format: TagFormat }[],
  memo?: string,
  relationalDocumentList?: { id?: number, }[],
  attachmentFileList: { id?: number, file?: File, fileName?: string, fileSize?: number }[],
}
export interface DocumentDeleteForm {
  id: number,
}

export interface DocumentDeleteRevisionForm {
  id: number,
  versionId: number,
}

export interface DocumentRegisterFormWithOption extends DocumentRegisterForm {
  documentClassificationOption?: DocumentClassificationOption;
}

export interface DocType {
  id: number;
  name: string;
  displayOrder: number;
}

export interface DocumentType {
  docType: DocType;
  documentClassification: DocumentClassificationAndType;
  name: string;
}

export interface DocsToRegisterFromCsv {
  docName: string,
  electronicFlg: string,
  paperFlg: string,
  memo?: string,
  barcodePrinting?: string,
  pdfFileName: string
  tags: Tag[],
}

export interface DocumentsFromCsv {
  ContractFlag?: number;
  documentType?: DocumentType;
  hasScreen?: number;
  docsToRegister: DocsToRegisterFromCsv[];
  documentClassificationOption?: DocumentClassificationOption;
}

export interface MoveDocumentFolderForm {
  documentId: number,
  folderId: number,
}

function convertToPagination(data: any, folder?: Folder): DocumentPagination {
  return {
    pageLimit: data.pageLimit,
    page: data.page,
    total: data.total,
    highlightWordList: data.highlightWordList ? data.highlightWordList.map((item: { highlightWord: string }) => item.highlightWord) : [],
    documents: data.documentList.map((item: {
      ccControlNumber: number,
      documentId: number,
      documentName: string,
      documentFileName: string,
      documentFileSize: number,
      documentTypeId: number,
      documentTypeName: string,
      tagList: { tagLabel: string, tagValue: string }[],
      relationalDocumentCount: number,
      relationalDocumentList: RelationalDocument[],
      warehouseStatus: string,
      warehouseStatusCode: string,
      paperFlg: ContractFlag,
      electronicFlg: ContractFlag,
      registUser: string,
      registDate: string,
      updateUser: string,
      updateDate: string,
      barcodePrinting: string,
      requiredTagInputFlg?: number,
      itemCode?: string,
      folderId: number,
      folderName: string,
      authorityList: ControlCodeItem[],
      cartAddedFlg: number
    }): Document => ({
      ccControlNumber: item.ccControlNumber,
      id: item.documentId,
      name: item.documentName,
      fileName: item.documentFileName,
      fileSize: item.documentFileSize,
      typeId: item.documentTypeId,
      typeName: item.documentTypeName,
      tagList: item.tagList.map((tag: { tagLabel: string, tagValue: string }) => ({ label: tag.tagLabel, value: tag.tagValue })),
      relationalDocumentCount: item.relationalDocumentCount,
      relationalDocumentList: item.relationalDocumentList,
      warehouseStatus: item.warehouseStatus,
      warehouseStatusCode: item.warehouseStatusCode,
      paperFlg: item.paperFlg,
      electronicFlg: item.electronicFlg,
      registUser: item.registUser,
      registDate: Formatter.fromStringToDate(item.registDate),
      updateUser: item.updateUser,
      updateDate: Formatter.fromStringToDate(item.updateDate),
      barcodePrinting: item.barcodePrinting,
      requiredTagInputFlg: item.requiredTagInputFlg,
      itemCode: item.itemCode,
      folderId: item.folderId,
      folderName: item.folderName,
      authorityList: folder ? folder.authorityList : item.authorityList,
      cartAddedFlg: item.cartAddedFlg,
    })),
  };
}

export default {
  /**
   * 文書の検索を行います。
   *
   * @param form - 検索フォーム
   * @returns - 検索結果のページネーション
   */
  async search(form: DocumentSearchForm): Promise<DocumentPagination> {
    const res = await axios.post('/Documents/search', form);
    const { data } = res;
    return convertToPagination(data);
  },

  /**
   * 文書の詳細情報を取得します。
   *
   * @param form - 文書詳細情報取得フォーム
   * @returns - 文書詳細情報
   */
  async getDetail(form: DocumentGetDetailForm): Promise<DocumentDetail> {
    const res = await axios.get('/Documents/getDetail', { params: { documentId: form.id } });
    const data = res.data as {
      ccControlNumber: number,
      documentId: number,
      documentList: {
        documentDataId: number,
        versionNo: number,
        documentName: string,
        documentFileName: string,
        documentFileSize: number,
        documentClassification: number,
        documentTypeId: number,
        documentTypeName: string,
        memo: string,
        attachmentFileList: {
          attachmentFileId: number,
          attachmentFileName: string,
        }[],
        tagList: {
          tagLabel: string,
          format: number,
          value: string,
          required: number,
        }[],
        relationalDocumentList: {
          ccControlNumber: number,
          documentId: number,
          documentName: string,
          documentType: string,
          duplicateTagList: {
            tagLabel: string,
            value: string,
          }[]
        }[],
      }[],
      paperFlg: ContractFlag,
      electronicFlg: ContractFlag,
      barcodePrinting: string,
      barcode: string,
      warehouseStatus: string,
      itemCode: string,
      scheduledDate: string | null,
      barcodeChangeable: number,
      requestHistory: {
        requestDate: string,
        requestType: string,
        requestUser: string,
      }[],
      versionUpdateHistoryList: {
        logId: number | '',
        registDate: string,
        requestUser: string,
        updateType: string,
      }[],
      folder: {
        folderId: number,
        folderName: string,
        authorityList: [
          {
            controlCode: string
          },
        ],
        reservedFlg: boolean,
      },
    };
    return {
      ccControlNumber: data.ccControlNumber,
      id: data.documentId,
      paperFlg: data.paperFlg,
      electronicFlg: data.electronicFlg,
      barcodePrinting: data.barcodePrinting,
      barcode: data.barcode,
      warehouseStatus: data.warehouseStatus,
      itemCode: data.itemCode,
      scheduledDate: data.scheduledDate ? Formatter.fromStringToDate(data.scheduledDate) : null,
      barcodeChangeable: data.barcodeChangeable,
      documents: data.documentList.map((document) => ({
        dataId: document.documentDataId,
        versionNo: document.versionNo,
        name: document.documentName,
        fileName: document.documentFileName,
        fileSize: document.documentFileSize,
        classification: document.documentClassification,
        typeId: document.documentTypeId,
        typeName: document.documentTypeName,
        memo: document.memo,
        tags: document.tagList.map((tag) => ({
          tagLabel: tag.tagLabel,
          format: tag.format,
          value: tag.value,
          required: !!tag.required,
        })),
        relationalDocuments: document.relationalDocumentList.map((relationalDocument) => ({
          ccControlNumber: relationalDocument.ccControlNumber,
          id: relationalDocument.documentId,
          name: relationalDocument.documentName,
          type: relationalDocument.documentType,
          duplicateTags: relationalDocument.duplicateTagList.map((duplicateTag) => ({
            label: duplicateTag.tagLabel,
            value: duplicateTag.value,
          })),
        })),
        attachmentFileList: document.attachmentFileList.map((attachment) => ({
          id: attachment.attachmentFileId,
          fileName: attachment.attachmentFileName,
        })),
      })),
      requestHistory: data.requestHistory.map((history) => ({
        date: history.requestDate,
        type: history.requestType,
        user: history.requestUser,
      })),
      versionUpdateHistory: data.versionUpdateHistoryList.map((versionUpdateHistory) => ({
        logId: versionUpdateHistory.logId === '' ? null : versionUpdateHistory.logId,
        registDate: versionUpdateHistory.registDate,
        requestUser: versionUpdateHistory.requestUser,
        updateType: versionUpdateHistory.updateType,
      })),
      folder: {
        id: data.folder.folderId,
        name: data.folder.folderName,
        reservedFlg: data.folder.reservedFlg,
        authorityList: data.folder.authorityList.map((authority) => ({
          controlCode: authority.controlCode,
        })),
      },
    };
  },

  /**
   * 文書の詳細検索を行います。
   *
   * @param form - 文書詳細検索フォーム
   * @returns - 文書詳細検索結果のページネーション
   */
  async searchDetail(form: DocumentSearchDetailForm): Promise<DocumentPagination> {
    const res = await axios.post(
      '/Documents/searchDetail',
      {
        ccControlNumber: Number(form.ccControlNumber),
        documentName: form.name,
        documentFileName: form.fileName,
        barcodePrinting: form.barcodePrinting,
        itemCode: form.itemCode,
        documentTypeId: form.documentTypeId,
        registDate_From: form.registDate_From ? Formatter.fromDateTimeToString(form.registDate_From, Formatter.defaultFullDateFormat) : undefined,
        registDate_To: form.registDate_To ? Formatter.fromDateTimeToString(form.registDate_To, Formatter.defaultFullDateFormat) : undefined,
        updateDate_From: form.updateDate_From ? Formatter.fromDateTimeToString(form.updateDate_From, Formatter.defaultFullDateFormat) : undefined,
        updateDate_To: form.updateDate_To ? Formatter.fromDateTimeToString(form.updateDate_To, Formatter.defaultFullDateFormat) : undefined,
        warehouseStatus: form.warehouseStatus,
        electronicContract: form.electronicContract,
        existPaper: form.existPaper,
        memo: form.memo,
        ccControlNumberList: form.ccControlNumberList ? form.ccControlNumberList.map((ccControlNumber) => ({
          ccControlNumber: ccControlNumber.ccControlNumber,
        })) : undefined,
        tagList: form.tagList ? form.tagList.map((tag) => ({
          tagLabel: tag.label,
          condition: tag.condition,
          value1: tag.value1,
          value2: tag.value2,
        })) : undefined,
        searchRange: form.searchRange?.toString(),
        page: form.page,
        sortList: form.sortList ? form.sortList.map((sort) => ({
          item: sort.item,
          order: sort.order,
        })) : undefined,
        bulkTagChange: form.bulkTagChange,
      },
    );
    const { data } = res;
    return convertToPagination(data);
  },

  /**
   * 文書フォルダの検索を行います。
   *
   * @param form - 文書フォルダ検索フォーム
   * @returns - 文書フォルダ検索結果のページネーション
   */
  async searchFolder(form: DocumentSearchFolderForm): Promise<DocumentPagination> {
    const res = await axios.post('/Documents/searchFolder', { folderId: form.folder.id, page: form.page, sortList: form.sortList });
    const { data } = res;
    return convertToPagination(data, form.folder);
  },

  /**
   * 文書の登録を行います。
   *
   * @param form - 文書登録フォーム
   * @returns - void
   */
  async register(form: DocumentRegisterForm): Promise<void> {
    await axios.post(
      '/Documents/register',
      {
        documentName: form.documentName,
        documentFile: form.documentFile ? await FileUtils.toBase64(form.documentFile) : undefined,
        documentFileName: form.documentFileName,
        documentFileSize: form.documentFile?.size,
        documentClassification: form.documentClassification,
        documentTypeId: form.documentTypeId,
        documentTypeName: form.documentTypeName,
        paperFlg: form.paperFlg,
        electronicFlg: form.electronicFlg,
        barcodePrinting: form.barcodePrinting,
        folderId: form.folderId,
        memo: form.memo,
        tagList: form.tagList ? form.tagList.map((tag) => ({
          tagLabel: tag.tagLabel,
          format: tag.format,
          value: tag.value,
        })) : undefined,
        relationalDocumentList: form.relationalDocumentList ? form.relationalDocumentList.map((relationalDocument) => ({
          relationalDocumentId: relationalDocument.id,
        })) : undefined,
        attachmentFileList: form.attachmentFileList ? await Promise.all(
          form.attachmentFileList
            .filter((attachment) => attachment.attachmentFile)
            .map(async (attachment) => ({
              attachmentFile: attachment.attachmentFile ? await FileUtils.toBase64(attachment.attachmentFile) : undefined,
              attachmentFileName: attachment.attachmentFileName,
              attachmentFileSize: attachment.attachmentFile?.size,
            })),
        ) : undefined,
      },
    );
  },

  /**
   * 文書ファイルのダウンロードを行います。
   *
   * @param form - ダウンロードファイルフォーム
   * @returns - ダウンロードしたファイル
   */
  async downloadFile(form: DownloadFileForm): Promise<File> {
    const res = await axios.post('/Documents/downloadFile', { documentDataId: form.documentDataId });
    return FileUtils.fromBase64(`${res.data.documentFile}`, form.fileName || 'file', { type: 'application/pdf' });
  },

  /**
   * 文書の添付ファイルをダウンロードします。
   *
   * @param form - ダウンロード添付ファイルフォーム
   * @returns - ダウンロードした添付ファイル
   */
  async downloadAttachment(form: DownloadAttachmentForm): Promise<File> {
    const res = await axios.post('/Documents/downloadAttachment', { attachmentFileId: form.attachmentId });
    return FileUtils.fromBase64(`${res.data.attachmentFile}`, form.fileName || 'file', { type: 'application/pdf' });
  },

  /**
   * 文書の更新を行います。
   *
   * @param form - 文書更新フォーム
   * @returns - void
   */
  async update(form: DocumentUpdateForm): Promise<void> {
    await axios.post('/Documents/update', {
      documentId: form.id,
      documentName: form.name,
      documentFile: form.file ? await FileUtils.toBase64(form.file) : '',
      documentFileName: form.fileName ?? '',
      documentFileSize: form.fileSize,
      documentClassification: form.classification,
      documentTypeId: form.typeId,
      documentTypeName: form.typeName,
      paperFlg: form.paperFlg,
      electronicFlg: form.electronicFlg,
      barcodePrinting: form.barcodePrinting,
      memo: form.memo,
      tagList: form.tagList ? form.tagList.map((tag) => ({
        tagLabel: tag.tagLabel || undefined,
        format: tag.format || undefined,
        value: tag.value || undefined,
      })) : undefined,
      relationalDocumentList: form.relationalDocumentList ? form.relationalDocumentList.map((relationalDocument) => ({
        relationalDocumentId: Number(relationalDocument.id),
      })) : undefined,
      attachmentFileList: form.attachmentFileList ? await Promise.all(form.attachmentFileList.map(async (attachment) => ({
        attachmentFile: attachment.file ? await FileUtils.toBase64(attachment.file) : undefined,
        attachmentFileName: attachment.fileName,
        attachmentFileSize: attachment.fileSize,
        attachmentFileId: attachment.id || undefined,
      }))) : undefined,
    });
  },

  /**
   * 文書のリビジョンを行います。
   *
   * @param form - 文書リビジョンフォーム
   * @returns - void
   */
  async revision(form: DocumentRevisionForm): Promise<void> {
    await axios.post('/Documents/revision', {
      documentId: form.id,
      documentName: form.name,
      documentFile: form.file ? await FileUtils.toBase64(form.file) : undefined,
      documentFileName: form.fileName ?? '',
      documentFileSize: form.fileSize,
      documentClassification: form.classification,
      documentTypeId: form.typeId,
      documentTypeName: form.typeName,
      paperFlg: form.paperFlg,
      electronicFlg: form.electronicFlg,
      barcodePrinting: form.barcodePrinting,
      memo: form.memo,
      tagList: form.tagList ? form.tagList.map((tag) => ({
        tagLabel: tag.tagLabel || undefined,
        format: tag.format || undefined,
        value: tag.value || undefined,
      })) : undefined,
      relationalDocumentList: form.relationalDocumentList ? form.relationalDocumentList.map((relationalDocument) => ({
        relationalDocumentId: Number(relationalDocument.id),
      })) : undefined,
      attachmentFileList: form.attachmentFileList ? await Promise.all(form.attachmentFileList.map(async (attachment) => ({
        attachmentFileId: attachment.id,
        attachmentFile: attachment.file ? await FileUtils.toBase64(attachment.file) : undefined,
        attachmentFileName: attachment.fileName,
        attachmentFileSize: attachment.fileSize,
      }))) : undefined,
    });
  },

  /**
   * 文書の削除を行います。
   *
   * @param form - 文書削除フォーム
   * @returns - void
   */
  async delete(form: DocumentDeleteForm): Promise<void> {
    await axios.post('/Documents/delete', {
      documentId: form.id,
    });
  },

  /**
   * 文書のリビジョンの削除を行います。
   *
   * @param form - 文書リビジョン削除フォーム
   * @returns - void
   */
  async deleteRevision(form: DocumentDeleteRevisionForm): Promise<void> {
    await axios.post('/Documents/deleteRevision', {
      documentId: form.id,
      version: form.versionId,
    });
  },

  /**
   * 文書のフォルダの移動を行います。
   *
   * @param form - 文書フォルダ移動フォーム
   * @returns - void
   */
  async moveFolder(form: MoveDocumentFolderForm): Promise<void> {
    const res = await axios.post('/Documents/moveFolder', form);
    const { data } = res;
    return data;
  },
};
