import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { ReserveManagementItem } from './reserveManagement.api';
import { RequestType } from './reservationCart.api';

export interface DocumentWarehousingSearchForm {
  documentList: DocumentId[],
}

export type ContractFlag = 1 | 0;

export interface DocumentWarehousing {
  ccControlNumber: number,
  documentId: number | null,
  documentName: string,
  documentFileName?: string,
  documentFileSize?: number,
  documentTypeId?: number,
  documentTypeName?: string,
  tagList: TagList[],
  relationalDocumentCount?: number,
  warehouseStatus?: string,
  paperFlg?: ContractFlag,
  electronicFlg?: ContractFlag,
  registUser: string,
  registDate: Date,
  updateUser: string,
  updateDate: Date,
  itemCode: string,
  barcodePrinting: string,
}

export interface TagList {
  tagLabel: string,
  tagValue: string,
}

export interface DocumentWarehousingStoreForm {
  documentList?: DocumentId[],
  deliveryType?: number | null,
  scheduledDate?: string,
  deliveryTimeCd?: string,
  trdContact?: string,
  emergencyContacts?: string,
  userMemo?: string,
  deliveryCd?: string,
  deliveryName?: string,
  deliveryPostNo?: string,
  deliveryAddress1?: string,
  deliveryAddress2?: string,
  deliveryAddress3?: string,
  customerStaffName?: string,
  deliveryPhone?: string,
  deliveryFax?: string,
}

export interface DocumentId {
  documentId: number | null,
}

export interface DocumentsSearchForReserveNoRequest {
  ReserveList: { ReserveNo: string; type: string }[]
}

function reduceDocumentWarehousing(res:AxiosResponse<any, any>) {
  const { data } = res;
  return data.documentList.map((item: {
    ccControlNumber: number,
    documentId: number,
    documentName: string,
    documentFileName: string,
    documentFileSize: number,
    documentTypeId: number,
    documentTypeName: string,
    tagList: { tagLabel: string, tagValue: string }[],
    relationalDocumentCount: number,
    warehouseStatus: string,
    paperFlg: ContractFlag,
    electronicFlg: ContractFlag,
    registUser: string,
    registDate: string,
    updateUser: string,
    updateDate: string,
    itemCode: string,
    barcodePrinting: string,
  }) => ({
    ccControlNumber: item.ccControlNumber,
    documentId: item.documentId,
    documentName: item.documentName,
    documentFileName: item.documentFileName,
    documentFileSize: item.documentFileSize,
    documentTypeId: item.documentTypeId,
    documentTypeName: item.documentTypeName,
    tagList: item.tagList.map((tag: { tagLabel: string, tagValue: string }) => ({
      tagLabel: tag.tagLabel,
      tagValue: tag.tagValue,
    })),
    relationalDocumentCount: item.relationalDocumentCount,
    warehouseStatus: item.warehouseStatus,
    paperFlg: item.paperFlg,
    electronicFlg: item.electronicFlg,
    registUser: item.registUser,
    registDate: DateTime.fromISO(item.registDate).toJSDate(),
    updateUser: item.updateUser,
    updateDate: DateTime.fromISO(item.updateDate).toJSDate(),
    itemCode: item.itemCode,
    barcodePrinting: item.barcodePrinting,
  }));
}

export enum DeliveryType {
  TeradaDelivery = 0, // 寺田便
  Courier = 1, // 宅配便
  BikeCourier = 3, // バイク便
  InStorePickup = 5, // 来店
  LetterPack = 6, // レターパック
}

export enum DeliveryAddressSpecify {
  NotSpecified = '0',
  Specified = '1',
}

interface Delivery {
  deliveryCd: string;
  deliveryPostNo?: string;
  deliveryAddress1?: string;
  deliveryAddress2?: string;
  deliveryAddress3?: string;
}

interface BaseDeliveryRequest {
  reservationType: RequestType;
  deliveryAddressSpecify: DeliveryAddressSpecify;
  delivery: Delivery[];
}

export type DeliveryConfirmationRequest = BaseDeliveryRequest;

export interface GetDeliveryCalendarRequestForm {
  reservationType: RequestType;
  deliveryServiceId: string;
  delivery: Delivery[];
}

export interface DeliveryServiceList {
  deliveryServiceId: string,
  deliveryType: number,
  deliveryServiceName: string,
}

export interface DeliveryConfirmationResponse {
  deliveryServiceList: DeliveryServiceList[]
}

export interface Holiday {
  holiday: string;
}

export interface DeliveryTime {
  deliveryTimeCd: string;
  deliveryTimeLabel: string;
  unavailableDateList: UnavailableDate[];
}

export interface UnavailableDate {
  unavailableDate: string;
}

export interface DeliveryCalendarResponse {
  shortestDesiredDate: string;
  lastDesiredDate: string;
  holidayList: Holiday[];
  deliveryTimeList: DeliveryTime[];
}

export default {
  /**
   * 文書の保管庫への保管を行います。
   *
   * @param form - 文書保管フォーム
   * @returns - 空のプロミス
   */
  async store(form: DocumentWarehousingStoreForm): Promise<void> {
    const res = await axios.post('/StoreReserve/confirm', form);
    const { data } = res;
    return data;
  },

  /**
   * 保管番号による文書検索を行います。
   *
   * @param form - 文書検索フォーム
   * @returns - 保管文書の配列
   */
  async searchForReserveNo(nos: ReserveManagementItem[]): Promise<DocumentWarehousing[]> {
    const form = { ReserveList: nos };
    const res = await axios.post('/Documents/searchForReserveNo', form);
    return reduceDocumentWarehousing(res);
  },

  /**
   * 集配方法の確認を取得します。
   *
   * @param form - 配送確認フォーム
   * @returns - 配送方法のリスト
   */
  async getDeliveryMethod(form: DeliveryConfirmationRequest): Promise<DeliveryConfirmationResponse> {
    const res = await axios.post('/DeliveryConfirmation/getMethod', form);
    const { data } = res;
    return data;
  },

  /**
   * 集配可能日取得を行います。
   *
   * @param form - 配送可能日取得フォーム
   * @returns - 配送可能日の詳細
   */
  async getDeliveryCalendar(form: GetDeliveryCalendarRequestForm): Promise<DeliveryCalendarResponse> {
    const res = await axios.post('/DeliveryConfirmation/getCalendar', form);
    const { data } = res;
    const { getCalendarData } = data;
    return getCalendarData;
  },
};
