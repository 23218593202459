interface WarehouseStatusMapType {
  [key: string]: string | null;
}

export enum WarehouseStatusCode {
  REQUESTING_TO_RECEIVE = 'RequestingToReceive',
  IN_STORAGE = 'InStorage',
  REQUESTING_TO_DISPATCH = 'RequestingToDispatch',
  OUT_OF_WAREHOUSE = 'OutOfWarehouse',
  REQUESTING_DISPOSAL = 'RequestingDisposal',
  DISPOSED = 'Disposed',
  NOT_RECEIVED = 'NotReceived',
  REQUESTING_SCAN = 'RequestingScan',
}

export enum DeliveryTime {
  NO_SPECIFIC_TIME = '90', // 時間指定なし
  MORNING = '91', // 午前
  AFTERNOON = '92', // 午後
  EVENING = '93', // 夕方
}

const WarehouseStatusMap: WarehouseStatusMapType = {
  未入庫: null,
  入庫依頼中: '入庫予定日',
  保管中: null,
  出庫依頼中: '到着予定日',
  出庫済み: null,
  廃棄依頼中: '廃棄予定日',
  廃棄済み: '廃棄日',
  スキャン依頼中: 'スキャン予定日',
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Warehouse {
  /**
   * ステータスと日付から倉庫のステータスの説明文を生成する関数
   *
   * @param status - ステータスの文字列
   * @param value - 日付の文字列
   * @returns - ステータスの説明文
   */
  export function getWarehouseDateDescription(status: string | null, value: string | null) {
    if (status) {
      if (WarehouseStatusMap[status] && value) {
        return `${status + '\xa0'.repeat(10) + WarehouseStatusMap[status]} : ${value}`;
      }
    }
    return status;
  }

  /**
   * 指定のステータスでバーコードが編集可能かどうかを判定する関数
   *
   * @param status - ステータスの文字列
   * @returns - 編集可能ならtrue、そうでなければfalse
   */
  export function canEditBarcode(status: string | null | undefined) {
    return (status === '未入庫');
  }
}

export default Warehouse;
