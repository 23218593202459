import React, { useCallback, useState } from 'react';
import MainFrameLeftSection from '../../components/MainFrameLeftSection/mainFrameLeftSection';
// eslint-disable-next-line import/no-cycle
import MainFrameRightAttachingSection from '../../components/MainFrameRightAttachingSection/mainFrameRightAttachingSection';
// eslint-disable-next-line import/no-cycle
import {
  DocumentsAndSearchType, Pagination, SearchType, defaultDocumentsAndSearchType, defaultPagination,
} from '../../components/MainFrameRightSection/mainFrameRightSection';
import styles from '../mainPage/mainPage.module.css';
import { Folder } from '../../services/http/folder.api';
import FolderPermissionsSection from '../../components/FolderPermissions/folderPermissionsSection';
import { useDocumentSearch, useDocumentSearchDetail, useDocumentSearchFolder } from '../../hooks/api/document.hook';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import {
  Document,
  DocumentDetail, DocumentSearchDetailForm, SortList,
} from '../../services/http/documents.api';
import LoadingOverlay from '../../components/LoadingOverlay';
import TopBar from '../../components/TopBar/topBar';
import SearchInputTop, { ContractType, WarehouseStatusType } from '../../components/SearchInputTop';
import MainFrame from '../../components/MainFrame/mainFrame';
import TopBarTagBulkButton from '../../components/TopBar/topBarTagBulkButton';
import useColumnResize from '../../hooks/useColumnResize';

/**
 * 選択された関連ドキュメントのプロップスインターフェース
 */
export interface ChooseRelatedDocumentProps {
  /** 関連文書が選択されたときに呼び出される関数 */
  onChooseRelatedDocuments: (documents: Document[]) => void,
  /** 関連文書の追加がキャンセルされたときに呼び出される関数 */
  onCancelAddingRelatedDocuments: () => void,
}

/**
 * 選択された関連ドキュメントのコンポーネント
 */
function ChooseRelatedDocument({
  onChooseRelatedDocuments = () => {},
  onCancelAddingRelatedDocuments = () => {},
}: ChooseRelatedDocumentProps) {
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);
  const [changingFolderPermissions, setChangingFolderPermissions] = useState(false);
  const [updateFolderList, setUpdateFolderList] = useState(0);
  // ------------------
  const [isSearching, setIsSearching] = useState(false);
  const [sort, setSort] = useState<SortList[] | undefined>(undefined);
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);
  const [documentsAndSearchType, setDocumentsAndSearchType] = useState<DocumentsAndSearchType>(defaultDocumentsAndSearchType);
  const [advancedSearchForm, setAdvancedSearchForm] = useState<DocumentSearchDetailForm | null>(null);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [highlightTexts, setHighlightTexts] = useState<string[]>([]);
  const [contractType, setContractType] = useState<ContractType>(ContractType.ALL);
  const [warehouseStatus, setWarehouseStatus] = useState<WarehouseStatusType>(WarehouseStatusType.ALL);

  const { request: documentSearchFolderRequest, loading: loadingFolder } = useDocumentSearchFolder();
  const { request: documentSearchDetailRequest, loading: loadingAdvancedSearch } = useDocumentSearchDetail();
  const { exec: documentSearchRequest, loading: loadingSearch } = useDocumentSearch();
  const openMessageModal = useMessageModal();
  const { width: leftWidth, handleResizeStart } = useColumnResize(
    'chooseRelatedDocumentLeftSectionWidth',
    220,
  );

  const isLoading = loadingFolder || loadingAdvancedSearch || loadingSearch;

  const onClearSearch = useCallback(() => {
    setSearchText('');
    setIsSearching(false);
  }, []);

  const onClearAdvancedSearch = useCallback(() => {
    setAdvancedSearchForm(null);
  }, []);

  const searchFolderDocument = useCallback(async (_folder: Folder, page: number, sortList = sort) => {
    try {
      const res = await documentSearchFolderRequest({ folder: _folder, page, sortList: sortList || [] });
      setDocumentsAndSearchType({ documents: res.documents, searchType: SearchType.FOLDER });
      const totalPages = Math.ceil(res.total / res.pageLimit) || 1;
      setPagination({
        page: res.page,
        totalItems: res.total,
        pageLimit: res.pageLimit,
        totalPages,
      });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [documentSearchFolderRequest, openMessageModal, sort]);

  const advancedSearchDocument = useCallback(async (form: DocumentSearchDetailForm, page: number, sortList = sort) => {
    setSort(sortList);
    try {
      const res = await documentSearchDetailRequest({ ...form, page, sortList: sortList || [] });
      setDocumentsAndSearchType({ documents: res.documents, searchType: SearchType.ADVANCED });
      setIsSearching(true);
      setSelectedFolder(null);
      const totalPages = Math.ceil(res.total / res.pageLimit) || 1;
      setPagination({
        page: res.page,
        totalItems: res.total,
        pageLimit: res.pageLimit,
        totalPages,
      });
    } catch (e) {
      setIsSearching(false);
      openMessageModal((e as ApiError)?.message);
    }
  }, [documentSearchDetailRequest, openMessageModal, sort]);

  const searchDocument = useCallback(async (text: string, page: number, sortList = sort) => {
    setSort(sortList);
    try {
      const res = await documentSearchRequest({ page, sortList: sortList || [], simpleString: text });
      setDocumentsAndSearchType({ documents: res.documents, searchType: SearchType.SIMPLE });
      setIsSearching(true);
      setSelectedFolder(null);
      const totalPages = Math.ceil(res.total / res.pageLimit) || 1;
      setPagination({
        page: res.page,
        totalItems: res.total,
        pageLimit: res.pageLimit,
        totalPages,
      });
      setHighlightTexts(res.highlightWordList);
    } catch (e) {
      setIsSearching(false);
      openMessageModal((e as ApiError)?.message);
    }
  }, [documentSearchRequest, openMessageModal, sort]);

  const handleFolderSelect = useCallback((folder: Folder) => {
    if (selectedFolder && (selectedFolder.id === folder.id)) return;
    setSelectedFolder(folder);
    searchFolderDocument(folder, 1);
    if (!isSearching) return;
    setSearchText(null);
  }, [isSearching, searchFolderDocument, selectedFolder]);

  const handlePageChange = useCallback((page: number) => {
    if (documentsAndSearchType.searchType === SearchType.ADVANCED && advancedSearchForm) {
      advancedSearchDocument(advancedSearchForm, page);
      return;
    }
    if (documentsAndSearchType.searchType === SearchType.SIMPLE && searchText !== null) {
      searchDocument(searchText, page);
      return;
    }
    if (documentsAndSearchType.searchType === SearchType.FOLDER && selectedFolder) {
      searchFolderDocument(selectedFolder, page);
    }
  }, [advancedSearchDocument, advancedSearchForm, documentsAndSearchType.searchType, searchDocument, searchFolderDocument, searchText, selectedFolder]);

  const handleAdvancedSearch = useCallback((form: DocumentSearchDetailForm) => {
    setSearchText(null);
    advancedSearchDocument(form, 1);
    setAdvancedSearchForm(form);
  }, [advancedSearchDocument]);

  const handleSearch = useCallback((text: string) => {
    searchDocument(text, 1);
    setSearchText(text);
  }, [searchDocument]);

  const onRefresh = useCallback((page?: number, sortList?: SortList[]) => {
    if (documentsAndSearchType.searchType === SearchType.ADVANCED && advancedSearchForm) {
      advancedSearchDocument(advancedSearchForm, page || 1, sortList);
      return;
    }
    if (documentsAndSearchType.searchType === SearchType.SIMPLE && searchText !== null) {
      searchDocument(searchText, page || 1, sortList);
      return;
    }
    if (documentsAndSearchType.searchType === SearchType.FOLDER && selectedFolder) {
      searchFolderDocument(selectedFolder, page || 1, sortList);
    }
  }, [advancedSearchDocument, advancedSearchForm, documentsAndSearchType.searchType, searchDocument, searchFolderDocument, searchText, selectedFolder]);

  const onSortChanged = useCallback((sortList: SortList[]) => {
    let result = sortList;

    if (sortList.length > 0) {
      const { order } = sortList[0];
      let { item } = sortList[0];
      item = item === 'name' ? 'documentName' : item;
      result = [{ item, order }];
    }

    setSort(result);
    if (pagination.totalPages !== 1) {
      onRefresh(1, result);
    }
  }, [onRefresh, pagination.totalPages]);

  const onRemoveDocument = useCallback((e: DocumentDetail) => {
    setDocumentsAndSearchType((prev) => ({
      ...prev,
      documents: prev.documents.filter((document) => document.id !== e.id),
    }));
  }, []);

  return (
    <div className={styles.attachDocSection}>
      <MainFrame
        borderBox
        topBarDisplay={false}
        onClearSearch={onClearSearch}
        onClearAdvancedSearch={onClearAdvancedSearch}
      >
        <div className={styles.mainFrame}>
          <TopBar>
            <SearchInputTop
              text={searchText || ''}
              isSearching={isSearching}
              contractType={contractType}
              warehouseStatus={warehouseStatus}
              onAdvancedSearch={handleAdvancedSearch}
              onSearch={handleSearch}
              onTextChange={setSearchText}
              onChangeContractType={setContractType}
              onChangeWarehouseStatus={setWarehouseStatus}
            />
            <TopBarTagBulkButton />
          </TopBar>
          <div className={styles.mainSections}>
            {!isSearching ? (
              <MainFrameLeftSection
                onDocumentFolderChanged={() => onRefresh(pagination.page, sort)}
                folder={selectedFolder}
                onSelectFolder={handleFolderSelect}
                isChangingFolderPermissions={changingFolderPermissions}
                updateFolderList={updateFolderList}
                mainFrameLeftSectionWidth={leftWidth}
              />
            ) : null}
            <div
              role="separator"
              style={{
                width: '5px',
                cursor: 'col-resize',
                backgroundColor: 'transparent',
                margin: '-3px',
                zIndex: 999,
                height: '100%',
                maxHeight: '100%',
              }}
              onMouseDown={handleResizeStart}
            />
            {!changingFolderPermissions && (
            <MainFrameRightAttachingSection
              onChooseRelatedDocuments={onChooseRelatedDocuments}
              onCancelAddingRelatedDocuments={onCancelAddingRelatedDocuments}
              folder={selectedFolder}
              documentsAndSearchType={documentsAndSearchType}
              pagination={pagination}
              isSearching={isSearching}
              highlightTexts={highlightTexts}
              contractType={contractType}
              warehouseStatus={warehouseStatus}
              onPageChanged={handlePageChange}
              onSortChanged={onSortChanged}
              onRemoveDocument={onRemoveDocument}
              onRefresh={onRefresh}
            />
            )}
            {changingFolderPermissions && (
            <FolderPermissionsSection
              folder={selectedFolder}
              onCancel={() => setChangingFolderPermissions(false)}
              updateFolderList={() => setUpdateFolderList((prev) => prev + 1)}
            />
            )}
          </div>
        </div>
      </MainFrame>
      {isLoading ? <LoadingOverlay /> : null}
    </div>
  );
}

export default ChooseRelatedDocument;
