import { ColDef, ValueGetterParams } from 'ag-grid-community';
import CheckboxCellRender from '../components/BaseTable/partials/CheckBoxCell/CheckBoxCellRender';
import Formatter from './formatters';
import { Document } from '../services/http/documents.api';
import { BaseTag } from '../services/http/tag.api';
import TagFormat from './tagFormat';
import { DeliveryType } from '../services/http/documentWarehousing.api';

export default class AGUtils {
  /**
   * オートインクリメントを行うためのカラム設定を返します。
   *
   * @param field - カラムに対応するデータのフィールド名
   * @param headerName - カラムの見出し名
   * @param startIndex - 開始インデックス
   * @param sortable - ソート可能かどうか (デフォルトはtrue)
   * @returns - 生成されたカラムの設定
   */
  static colAutoIncrement(field: string, headerName = 'No', startIndex = 0): ColDef {
    return {
      field,
      headerName,
      resizable: true,
      valueGetter: (params) => String((params.node?.childIndex || 0) + 1 + startIndex),
      width: 80,
    };
  }

  /**
   * デフォルト設定のカラムを返します。
   *
   * @param field - フィールド名
   * @param headerName - ヘッダー名
   * @param width - 列の幅 (デフォルトは150)
   * @param sortable - ソート可能かどうか (デフォルトはtrue)
   * @param isHighlight - ハイライト対象かどうか (デフォルトはfalse)
   * @param additionalOptions - 追加のオプション
   */
  static colDefault(field: string, headerName: string, width = 150, sortable = true, isHighlight = false, additionalOptions: ColDef = {}): ColDef {
    return {
      field,
      headerName,
      resizable: true,
      sortable,
      width,
      cellClass: isHighlight ? 'textFormat highlightTarget' : 'textFormat',
      ...additionalOptions,
    };
  }

  /**
   * ユーザー名に専用のカラムの設定を返します。
   *
   * @param field - フィールド名
   * @param headerName - ヘッダー名
   * @param sortable - ソート可能かどうか (デフォルトはtrue)
   * @returns - カラム定義
   */
  static colUsername(field: string, headerName: string, sortable = true): ColDef {
    return {
      field,
      headerName,
      resizable: true,
      sortable,
      width: 150,
      cellClass: 'textFormat',
    };
  }

  /**
   * Emailアドレス専用のカラムの設定を返します。
   *
   * @param field - フィールド名
   * @param headerName - ヘッダー名
   * @returns - カラム定義
   */
  static colEmail(field: string, headerName: string): ColDef {
    return {
      field,
      headerName,
      resizable: true,
      sortable: true,
      width: 200,
      cellClass: 'textFormat',
    };
  }

  /**
   * 日付専用のカラムの設定を返します。
   *
   * @param field - フィールド名
   * @param headerName - ヘッダー名
   * @param sortable - ソート可能かどうか (デフォルトはtrue)
   * @returns カラム定義
   */
  static colDate(field: string, headerName: string, sortable = true): ColDef {
    return {
      field,
      headerName,
      resizable: true,
      sortable,
      width: 180,
      cellClass: 'textFormat',
      valueGetter: ({ data }) => (typeof data[field] === 'string' ? Formatter.fromStringToFormattedString(data[field], Formatter.defaultDateTimeFormat) : Formatter.toDisplayDate(data[field], Formatter.defaultDateTimeFormat)),
    };
  }

  /**
   * ファイルサイズ専用のカラムの設定を返します。
   *
   * @param field - フィールド名
   * @param headerName - ヘッダー名
   * @param sortable - ソート可能かどうか (デフォルトはtrue)
   * @returns - カラム定義
   */
  static colFileSize(field: string, headerName: string, sortable = true): ColDef {
    return {
      field,
      headerName,
      resizable: true,
      sortable,
      width: 100,
      cellClass: 'textFormat',
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const fileSizeA = nodeA.data[field];
        const fileSizeB = nodeB.data[field];
        if (fileSizeA === fileSizeB) return 0;
        return (fileSizeA > fileSizeB) ? 1 : -1;
      },
      valueGetter: ({ data }) => Formatter.byteSizeDescription(data[field]),
    };
  }

  /**
   * チェックボックス専用のカラムの設定を返します。
   *
   * @param field - フィールド名
   * @param headerName - ヘッダー名
   * @param width - 列の幅 (デフォルトは150)
   * @returns - カラム定義
   */
  static colCheckboxReadOnly(field: string, headerName: string, width = 150): ColDef {
    return {
      field,
      headerName,
      resizable: true,
      sortable: true,
      width,
      cellRenderer: CheckboxCellRender,
      cellRendererParams: {
        readOnly: true,
      },
    };
  }

  /**
   * 数値処理
   *
   * @param n - 文字列としての数値
   * @returns - 数値
   */
  static processNumber(n: string) {
    return n ? Number(n) : null;
  }

  /**
   * タグ処理
   *
   * @param tagName - タグ名
   * @param tag - APIからのタグ
   * @returns - valueGetter
   */
  static getTagValueGetter = (tagName: string, tag: BaseTag | null) => ({ data }: ValueGetterParams<Document>) => {
    if (!data || !data.tagList) return '';
    const docTag = data.tagList.find((t: { label: string, value: string }) => t.label === tagName);

    if (docTag) {
      if (tag != null && tag.format === TagFormat.NUMBER) return AGUtils.processNumber(docTag.value);

      return docTag.value;
    }
    return AGUtils.processNumber('');
  };
}
